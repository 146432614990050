<template lang="pug">
  div(class="e-input-scanner")
    v-text-field(
      v-bind="$attrs"
      v-on="$listeners"
      :error-messages="errorMessages"
    )
      template(#append)
        v-tooltip(
          v-if="generateButton"
          top
        )
          template(#activator="{on}")
            v-btn(
              v-on="on"
              @click="generateBarcode"
              :class="{ 'mr-2': !xsDevice }"
              :loading="generateBarcodeLoading"
              x-small
              icon
            )
              e-svg-icon generate
          | {{ $t('Generate barcode') }}
        v-tooltip(v-if="!xsDevice" top)
          template(#activator="{on}")
            v-btn(
              @click="dialog = true"
              v-on="on"
              x-small
              icon
            )
              e-svg-icon barcode
          | {{ $t('Scan barcode') }}
    span(v-if="notification" class="e-input-scanner__notification") {{ notification }}
    t-dialog(
      v-model="dialog"
      max-width="450px"
    )
      template(#title)
        p(class='text-center ma-0 pa-0') {{ $t('Scan the barcode') }}
      template(#content)
        m-block-barcode-scanner(@scan="scan" class="mb-5")
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TDialog from '~/components/templates/t-dialog'
import MBlockBarcodeScanner from '~/components/modules/blocks/concrete/m-block-barcode-scanner'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    TDialog,
    ESvgIcon,
    MBlockBarcodeScanner
  },
  mixins: [responsive],
  props: {
    translate: {
      type: Boolean,
      default: false
    },
    generateButton: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: null,
      default: null
    }
  },
  data: () => ({
    generateBarcodeLoading: false,
    dialog: false,
    notification: null
  }),
  methods: {
    scan (barcode) {
      this.dialog = false
      const data = this.translate ? this.translateToLatin(barcode) : barcode
      const val = this.$attrs.value
      if (this.multiple && val) {
        this.$emit('input', val + `, ${data}`)
      } else {
        this.$emit('input', data)
      }
    },
    async generateBarcode () {
      try {
        this.generateBarcodeLoading = true
        const barcode = this._.get(await AccountingGoods.api().generateBarcode(), 'response.data.barcode')
        const val = this.$attrs.value
        if (this.multiple && val) {
          this.$emit('input', val + `, ${barcode}`)
        } else {
          this.$emit('input', barcode)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.generateBarcodeLoading = false
      }
    },
    translateToLatin (barcode) {
      if (!barcode) {
        return
      }
      this.notification = null
      let stamp = barcode.toUpperCase()
      const regExp = '[А-Я]'
      const stampCharacters = stamp.replace(/\d/g, '')
      const stampNumbers = stamp.replace(/\D/g, '')
      const charactersData = { Ф: 'A', И: 'B', С: 'C', В: 'D', У: 'E', А: 'F', П: 'G', Р: 'H', Ш: 'I', О: 'J', Л: 'K', Д: 'L', Ь: 'M', Т: 'N', Щ: 'O', З: 'P', Й: 'Q', К: 'R', Ы: 'S', І: 'S', Е: 'T', Г: 'U', М: 'V', Ц: 'W', Ч: 'X', Н: 'Y', Я: 'Z' }

      if (stampCharacters.search(regExp) !== -1) {
        const translatedLetters = stampCharacters.split('').map(character => charactersData[character] || character).join('')
        stamp = translatedLetters + stampNumbers
        this.notification = this.$t('We automatically changed the letters to Latin. Please check that the data is correct')
      }
      return stamp
    }
  }
}
</script>

<style scoped lang="scss">
.e-input-scanner {
  &::v-deep {
    .v-input {
      &__append-inner {
        align-self: center;
        margin-top: 0;
        cursor: pointer;

        svg {
          transition: .2s;
        }

        &:hover {
          svg {
            fill: #04aed5;
          }
        }
      }
    }
  }

  &__notification {
    font-size: 0.675rem;
    line-height: 1.5;
    display: block;
    margin-bottom: 0.75rem;
    color: $color-warning;
  }
}
</style>
