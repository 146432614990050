import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import AcquiringOrder from '~/modules/acquiring/models/AcquiringOrder'

export class PosTerminals extends PersistAble(OrmModel) {
  static entity = 'posterminals'
  static paginated = true
  static defaultSortOrder = true
  static defaultSortParam = 'usage'

  static ormTrans = {
    single: 'POS-terminals',
    multy: 'POS-terminal'
  }

  static platforms = {
    android: {
      value: 'android'
    },
    desktop: {
      value: 'desktop'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      cashRegister: this.attr(null),
      terminalName: this.attr(null),
      providerName: this.attr(null),
      port: this.attr(null),
      ipAddress: this.attr(null),
      printReceiptOnAndroidTerminal: this.attr(null),
      displayReceiptOnAndroidTerminal: this.attr(null),
      usage: this.attr(null),
      acquiringOrder: this.attr(null),
      payLinkTerminalId: this.attr(null),
      platform: this.attr(null)
    }
  }

  get usageString () {
    return this.usage ? 'active' : 'deactivated'
  }

  get terminalNameString () {
    return this.terminalName || '–'
  }

  get providerNameString () {
    return this.providerName || '–'
  }

  get cashRegisterString () {
    const cashRegister = this.cashRegister
    return cashRegister ? `№${cashRegister.number} (${cashRegister.fiscalNumber})` : '-'
  }

  static ormHeaders = [
    { text: 'Status', value: 'usageString', sortable: true, filterable: false, sortQuery: 'usage' },
    { text: 'Terminal ID', value: 'terminalNameString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegister', sortable: false, filterable: false },
    { text: 'Bank', value: 'providerNameString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'cashRegisterString'

  static ormColsComponents = {
    usageString: {
      component: 'block-pos-terminal-status'
    },
    cashRegister: {
      component: 'block-pos-terminal-cash-register-status'
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'edit',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-pos-terminal-settings-form',
          width: '650px',
          componentProps: {
            item,
            isDialogPart: true,
            isEditing: true
          }
        })
      }
    },
    {
      name: 'delete',
      visible: (item) => {
        const expiredOrder = get(item, 'acquiringOrder.status') === AcquiringOrder.STATUSES.expired
        return item?.acquiringOrder ? expiredOrder : true
      },
      call: (item) => {
        const expiredOrder = get(item, 'acquiringOrder.status') === AcquiringOrder.STATUSES.expired
        const dialog = Dialog.query().where('type', 'confirmation').first()
        const title = expiredOrder ? 'Do you really want to delete the acquiring application?' : 'Are you sure you want to delete bank terminal settings?'
        dialog.open({
          title,
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            if (expiredOrder) {
              const modelItem = {
                ...get(item, 'acquiringOrder', {}),
                $id: get(item, 'acquiringOrder.id')
              }
              await AcquiringOrder.api().del(modelItem)
              AppNotifications.success('The application for the use of acquiring has been successfully deleted')
            } else {
              await PosTerminals.api().del(item)
              AppNotifications.success('Bank terminal settings have been successfully deleted')
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default PosTerminals
