<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Select cash registers') }}
    div(class="dialog__topbar")
      p(class="dialog__text") {{ $t('Select the PROs that will participate and agree to the terms of the promotion') }}
      div(class="dialog__banner") {{ $t('Chosen') }}: {{ selectedItems }} {{ $t('from') }} {{ totalItems }}
    m-orm-table(
      :model="model"
      ref="datatable"
      :custom-request="tableRequest"
      @select="handleSelectItem"
      @requested="handleRequested"
      :disable-pagination="false"
      show-select
      hide-default-footer
    )
    div(class="dialog__actions")
      div(class="dialog__terms-text")
        span(class="mr-1") {{ $t('By clicking') }} “{{ $t('Take part') }}”, {{ $t('you agree to the') }}
        e-link(
          class="login-form__link"
          href="https://checkbox.ua/umovy-aktsii-perekhodte-zi-staroho-rro-na-prro-checkbox/"
          underline
        ) {{ $t('conditions of the promotion') }}
      v-btn(
        class="main-button"
        @click="handleClick"
        :loading="loading"
      ) {{ $t('Take part') }}
</template>

<script>
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import PromoCashRegistersForSelection from '~/modules/promotion/models/PromoCashRegistersForSelection'
import ELink from '~/components/elements/links/e-link'
import contentDialog from '~/mixins/dialogs/contentDialog'
import OutletsSearch from '~/models/directories/search/OutletsSearch'

export default {
  name: 'BlockPromotionCashRegisters',
  components: {
    MOrmTable,
    ELink
  },
  mixins: [contentDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    selectedItems: 0,
    totalItems: 0
  }),
  computed: {
    model () {
      return PromoCashRegistersForSelection
    }
  },
  methods: {
    async tableRequest ({ model, requestParams }) {
      this.selectedItems = 0
      await OutletsSearch.api().filter({
        limit: 10,
        offset: 1,
        mode: 100
      }).all()
      return await model.api().filter({
        pagination: false,
        usedInShare: false,
        active: true,
        search: this._.get(requestParams, 'filterBy.search', '')
      }).all()
    },
    handleSelectItem (selected) {
      this.selectedItems = this._.get(selected, 'length') || 0
    },
    handleRequested (res) {
      this.totalItems = this._.get(res, 'data.meta.totalItems') || 0
    },
    async validateAndGetSelected () {
      const tableValidation = this._.get(this.$refs, 'datatable.$refs.tableValidation')
      if (!tableValidation) {
        return false
      }
      await tableValidation.reset()
      const selected = this._.get(this.$refs, 'datatable.selected')
      if (!this._.get(selected, 'length')) {
        return false
      }
      const errors = {}
      this._.each(selected, (item) => {
        const additionalData = item.getAdditionalData()
        if (!additionalData?.outlet) {
          errors[`outlet-${item?.id}`] = 'Поле Торгова точка повинно мати значення'
        }
        if (!additionalData?.prroType) {
          errors[`prroType-${item?.id}`] = 'Поле Тип ПРРО повинно мати значення'
        }
      })
      await tableValidation.setErrors(errors)
      if (this._.get(Object.keys(errors), 'length')) {
        return false
      }
      return selected
    },
    async handleClick () {
      try {
        this.loading = true
        const selected = await this.validateAndGetSelected()
        if (!selected) {
          return
        }
        await this.closeModal()
        await this.contentDialog.open({
          component: 'block-promotion-send-progress',
          width: '560px',
          componentProps: {
            items: selected,
            onSuccess: this.onSuccess
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__title {
    padding-bottom: 12px;
  }

  &__text {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
    flex-grow: 1;
  }

  &__terms-text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    flex-grow: 1;
    margin-bottom: 0;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 32px;

    .main-button {
      width: 100%;
      max-width: 180px;
    }
  }

  &__topbar {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  &__banner {
    height: 32px;
    background: rgba(0, 0, 0, 0.03);
    padding: 0 8px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.5);
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &::v-deep {
    .v-data-table__wrapper {
      max-height: 315px !important;

      @media (min-width: map-get($breakpoints, 'sm')) {
        max-height: 522px !important;
      }
    }
  }
}
</style>
