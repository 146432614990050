// mport VueI18n from 'vue-i18n'
import {
  extend,
  localize
} from 'vee-validate'
import { isBase64 } from '~/services/_utils/utils'

// import _ from 'lodash'
// import { extend, configure } from 'vee-validate'
// import { required } from 'vee-validate/dist/rules'
// import enValidations from 'vee-validate/dist/locale/en'
// import ukValidations from 'vee-validate/dist/locale/uk'
//
// const i18n = new VueI18n({
//   locale: 'en',
//   en: {
//     validations: Object.assign({}, enValidations.messages, {
//       alpha_num_dash: 'Field must contain only alpha'
//     })
//   },
//   uk: {
//     validations: Object.assign({}, ukValidations.messages, {
//       alpha_num_dash: 'Field must contain only alpha'
//     })
//   }
// })

// configure({
//   defaultMessage: (field, values) => {
//     values._field_ = i18n.t(`fields.${field}`)
//     return i18n.t(`validations.${values._rule_}`, values)
//   }
// })

localize({
  en: {
    messages: {
      max_barcode: 'The {_field_} should consists of no more 21 characters.',
      valid_barcode: 'The {_field_} should contains "G", "W", "C" characters and mumbers.',
      integer_or_decimal: 'The {_field_} should as integer or decimal number also can contains ".", "," and two number after.',
      decimal_number: 'The {_field_} should be as fractional or integer number.',
      positive: 'The {_field_} should not be negative or zero.',
      positive_or_zero: 'The {_field_} should be positive number',
      latin_dashes: 'The field {_field_} may contain only latin letters, digits and symbols "_" and "-"',
      alpha_dash_spaces: 'The field {_field_} may contain only letters, digits, spaces and symbols "_" and "-"',
      money: '{_field_} should be as xxxxx.xx format',
      money_decimal: '{_field_} should be as xxxxx.x format',
      phone: '{_field_} is not valid phone',
      base64: '{_field_} is not valid',
      emailsString: '{_field_} is not correct',
      cyrillic_uk: '{_field_} must contain only letters of the Ukrainian alphabet',
      tap2PhoneTerminalId: '{_field_} must be of the format "Х*******"',
      noSpaces: '{_field_} must be without spaces',
      uktzed: 'The field {_field_} must contain 10 digits',
      exciseStamp: '{_field_} should be as "AAAA123456" format',
      IBAN: 'The field {_field_} is not valid'
    }
  },
  uk: {
    messages: {
      max_barcode: 'Поле {_field_} повинно складатися не більш ніж 21 символ.',
      valid_barcode: 'Поле {_field_} може містити тільки латинські літери "G", "W", "C" та цифри.',
      integer_or_decimal: 'Поле {_field_} має бути цілим або дробовим числом, може містити ".", "," і два символа після.',
      decimal_number: 'Поле {_field_} повино буди дробовим або цілим числом.',
      positive: 'Поле {_field_} не повинно бути негативним, або дорівнювати нулю.',
      positive_or_zero: 'Поле {_field_} повинно бути цілим, позитивним числом',
      length: 'Поле {_field_} повинно бути довжиною {length} символів',
      integer: 'Поле {_field_} повинно бути цілим числом',
      latin_dashes: 'Поле {_field_} може містити тільки латинські букви, цифри, символи "_" та "-"',
      alpha_dash_spaces: 'Поле {_field_} може містити тільки букви, цифри, пробіли, символи "_" та "-"',
      money: '{_field_} повинно бути xxxxxx.xx формату',
      money_decimal: '{_field_} повинно бути xxxxxx.x формату',
      inn_passport: '{_field_} маеє бути IПН або серією та номером паспорта',
      phone: '{_field_} має невірний формат',
      base64: '{_field_} невірне',
      emailsString: '{_field_} не відповідає формату. Перевірте правильність введення',
      url: 'Поле {_field_} має бути дійсною адресою сайту',
      is: 'Поле {_field_} обов\'язкове',
      cyrillic_uk: 'Поле {_field_} повинно містити лише літери українського алфавіту',
      tap2PhoneTerminalId: 'Поле {_field_} має бути формату "Х*******"',
      noSpaces: 'Поле {_field_} має бути без пробілів',
      uktzed: 'Поле {_field_} повинно містити 10 цифр',
      exciseStamp: '{_field_} повинна бути формату "AAAA123456"',
      IBAN: 'Поле {_field_} не є валідним'
    }
  }
})

extend('alpha_num_dash', {
  validate: value => /^[0-9A-Za-z\\-]+$/.test(value),
  message: 'This {_field_} is invalid.'
})

extend('max_barcode', {
  validate (value, { length }) {
    return value.length <= parseInt(length)
  },
  params: ['length']
})

extend('valid_barcode', {
  validate: value => /^[0-9GWCgwc]+$/.test(value)
})

extend('integer_or_decimal', {
  validate: (value) => {
    return /^[0-9]*[(\\.|\\,){0,1}]?[0-9]{1,2}$/.test(value)
  }
})

extend('decimal_number', {
  validate: value => /^[0-9]*([(\\.|\\,){0,1}][0-9]*)*$/.test(value)
})

extend('positive', {
  validate: value => parseFloat(value.replace(',', '.')) > 0
})

extend('positive_or_zero', {
  validate: value => parseFloat((value + '').replace(',', '.')) >= 0
})

extend('latin_dashes', {
  validate: value => /^[0-9A-Za-z_-]*$/.test(value)
})

extend('alpha_dash_spaces', {
  validate: value => /^[ 0-9A-ZА-ЩЬЮЯЄІЇҐЁ_-]*$/i.test(value)
})

extend('money', {
  validate: value => /^[0-9]{1,18}(\.)?[0-9]{0,2}$/i.test(value)
})

extend('money_decimal', {
  validate: value => /^[0-9]{1,18}(\.)?[0-9]?$/i.test(value)
})

extend('inn_passport', {
  validate: (value) => {
    return /^[А-ЩЬЮЯҐЄІЇ]{2}\d{6}$/i.test(value) || /^\d{8,10}$/i.test(value)
  }
})

extend('cyrillic_uk', {
  validate: (value) => {
    return /^[А-ЩЬЮЯҐЄІЇа-щьюяґєії]/i.test(value)
  }
})

extend('phone', {
  validate: (value) => {
    return /^\+380\d{2}-\d{3}-\d{2}-\d{2}/i.test(value)
  }
})

extend('base64', {
  validate: (value) => {
    return isBase64(value)
  }
})

extend('emailsString', {
  validate: (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+((\s*[;,:|\s]\s*)*([^\s@]+@[^\s@]+\.[^\s@]+)*)*$/.test(value)
  }
})

extend('url', {
  validate: (value) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return !!pattern.test(value)
  }
})

extend('tap2PhoneTerminalId', {
  validate: (value) => {
    const startsWith = value && value.startsWith('X')
    const length = value && value.length === 8
    return startsWith && length
  }
})

extend('noSpaces', {
  validate: (value) => {
    return !/\s/g.test(value)
  }
})

extend('uktzed', {
  validate: (value) => {
    const val = value ? value.replace(/\s/g, '') : ''
    return !isNaN(val) && val.length === 10
  }
})

extend('exciseStamp', {
  validate: (value) => {
    const pattern = /^[a-zA-Zа-яґєіїА-ЯҐЄІЇ]{4}[0-9]{6}$/gm
    return !!pattern.test(value)
  }
})

extend('IBAN', {
  validate: (value) => {
    const pattern = /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/i
    return !!pattern.test(value)
  }
})
