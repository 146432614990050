<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners" :max-width="cardWidth")
    template(#title) {{ $t('Send to sfs') }}

    template(#content)
      v-overlay(absolute :value="isLoading" )
        e-progress-circular
      m-iit-sign-plugin(ref="signPlugin" @keyLoaded="onKeyLoaded")
</template>

<script>
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import MIitSignPlugin from '~/components/modules/IIT/m-iit-sign-plugin'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'

export default {
  components: {
    EProgressCircular,
    MIitSignPlugin,
    TOrmFields,
    TOrmButtons,
    TDialog
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    selectedItem: {}
  }),
  computed: {
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    cardWidth () {
      return [650, 'px'].join('')
    },
    isLoading () {
      return this.loading || this.itemLoading
    }
  },
  methods: {

    close () {
      this.$emit('input', false)
    },
    fill (item) {
      this.selectedItem = item
    },
    async onKeyLoaded (keyData) {
      this.itemLoading = true

      const edrpouCode = this._.get(keyData[0], 'infoEx.subjDRFOCode', null)

      const xmlResponse = await this.model.api().put(this.model.$routes[this.model.entity].xmlDoc(this.selectedItem[this.model.primaryKey]),
        { HKBOS: edrpouCode },
        { save: false })

      const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
      const fname = this._.get(xmlResponse, 'response.data.fname', null)

      const userCert = keyData[0].data

      const taxReportService = new TaxReportService(this.$refs.signPlugin)
      const signData = await taxReportService.getEnvelopedData(xml, true, userCert)

      try {
        await this.model.api().put(this.model.$routes[this.model.entity].taxRegister(this.selectedItem[this.model.primaryKey]), {
          signData,
          fname
        }, { dataKey: null })

        const sentItem = this.model.query().whereId(this.selectedItem[this.model.primaryKey]).first()

        if (sentItem.taxDocId && sentItem.status !== 'error') {
          const docId = sentItem.taxDocId + ''
          const reqEncryptedId = await taxReportService.getEnvelopedData(Base64js.toByteArray(btoa(docId)), false, userCert)
          await this.model.api().update(sentItem, { reqEncryptedId })
        }

        if (sentItem.taxStatus.includes('ERROR')) {
          this.$notification.error(this.$t('Error when sending to tax. Try again later'))
        } else {
          this.$notification.success(this.$t('Successfully sent to Tax'))
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.itemLoading = false
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
//
</style>
