<template lang="pug">
  v-row(no-gutters)
    v-col(cols="12" sm="8" class="block-iban-form")
      h2(class="block-iban-form__title") {{ $t('Enter account number') }}
      v-expansion-panels(v-if="isResponsive" v-model="mobileHint" class="mb-6" accordion)
        v-expansion-panel
          v-expansion-panel-header(color="rgba(0, 0, 0, 0.03)") 💡 {{ $t('Hint') }}
          v-expansion-panel-content(color="rgba(0, 0, 0, 0.03)")
            div(class="block-iban-form__hint-text") {{ $t('Add your current account number, we will pull all other bank details automatically') }}
      ValidationObserver(ref="ibanValidator")
        t-orm-fields(v-bind.sync="form" :items="fields")
      div(class="d-flex justify-end")
        v-btn(
          class="main-button"
          :class={ 'w-100': isResponsive }
          height="48"
          :loading="loading"
          @click="submit"
        ) {{ $t('Save') }}
    v-col(v-if="!isResponsive" cols="12" sm="4" class="block-iban-form__hint")
      div(class="block-iban-form__hint-title") 💡 {{ $t('Hint') }}
      div(class="block-iban-form__hint-text") {{ $t('Add your current account number, which will be used for IBAN payment') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Iban from '~/modules/acquiring/models/Iban'

export default {
  name: 'BlockIbanForm',
  components: { TOrmFields },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: 'create'
    }
  },
  data () {
    return {
      loading: false,
      form: {
        name: null,
        number: null,
        paymentDescription: null
      },
      mobileHint: false
    }
  },
  computed: {
    model () {
      return Iban
    },
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          fieldVal: () => this._.get(this.item, 'name'),
          attrs: {
            label: 'Account name',
            outlined: true
          }
        },
        {
          model: 'number',
          component: 'v-text-field',
          provider: {
            vid: 'iban',
            name: 'IBAN',
            rules: 'length:27'
          },
          fieldVal: () => {
            const iban = this._.get(this.item, 'number')
            if (iban) {
              return this.sliceIban(iban)
            }
          },
          attrs: {
            label: 'IBAN',
            outlined: true,
            prefix: 'UA'
          }
        },
        {
          model: 'paymentDescription',
          component: 'v-text-field',
          fieldVal: () => this._.get(this.item, 'paymentDescription') || 'Оплата за товари та послуги',
          attrs: {
            label: 'Purpose of payment',
            outlined: true
          }
        }
      ]
    },
    isUpdate () {
      return this.context === 'update'
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    sliceIban (iban) {
      return iban.slice(2)
    },
    async submit () {
      const valid = await this.$refs.ibanValidator.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        const iban = 'UA' + this.form.number
        const payload = {
          ...this.form,
          number: iban
        }
        if (this.isUpdate) {
          await this.model.api().update(this.item, payload)
        } else {
          await this.model.api().create(payload)
        }
        let message
        if (this.isUpdate) {
          message = this.$t('Iban has successfully updated!')
        } else {
          message = this.$t('Iban has successfully added!')
        }
        this.$notification.success(message)
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-iban-form {
  padding: 40px 32px 32px 32px;

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding: 16px 12px;
  }

  &__title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.24px;
    padding-bottom: 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__hint {
    padding: 40px 32px;
    background: rgba(0, 0, 0, 0.03);

    &-title {
      color: #161B25;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &-text {
      padding: 12px 0 24px 0;
      color: rgba(22, 27, 37, 0.75);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  &::v-deep {
    .v-expansion-panel {
      border-radius: 12px !important;
    }
    .v-expansion-panel::before {
      box-shadow: none !important;
    }
    .v-expansion-panel-header {
      border-radius: 12px !important;
    }
    .v-expansion-panel-content {
      border-radius: 0 0 12px 12px !important;
    }
  }
}
</style>
