import { find, get } from 'lodash'
import auth from '~/modules/auth/auth'
import taxReports from '~/modules/reports/mixins/taxReports'
import Invoices from '~/models/billing/Invoices'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'

function hasExpiringKeys () {
  const oneMonthFromNow = new Date()
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1)
  const oneMonthBeforeToday = new Date()
  oneMonthBeforeToday.setMonth(oneMonthBeforeToday.getMonth() - 1)

  return Boolean(EmployeesSearch.query().where((employee) => {
    if (!employee.certificateEnd) {
      return false
    }
    const isActive = employee.mode === 100
    const certificateEnd = new Date(employee.certificateEnd)
    return isActive && certificateEnd >= oneMonthBeforeToday && certificateEnd <= oneMonthFromNow
  }).first())
}

const faqChildren = [
  {
    text: 'How to start',
    color: '#ff8a00',
    icon: {
      type: 'e-svg-icon',
      text: 'integrations-orange',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isOwnerWithoutOrganization
    },
    href: '/dashboard/onboarding'
  },
  {
    text: 'Instructions ',
    icon: {
      type: 'e-svg-icon',
      text: 'book',
      options: {
        size: 'md'
      }
    },
    href: '/dashboard/instructions'
  },
  {
    text: 'Rules of work with cash register',
    icon: {
      type: 'e-svg-icon',
      text: 'faq',
      options: {
        size: 'sm'
      }
    },
    href: '/dashboard/faq'
  },
  {
    text: 'Як протестувати',
    icon: {
      type: 'e-svg-icon',
      text: 'compass',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isOwnerWithoutOrganization
    },
    link: 'https://wiki.checkbox.ua/uk/portal/test-data'
  }
]

export const menuItemsKeys = {
  answersToQuestionsWelcome: 'answersToQuestionsWelcome',
  answersToQuestions: 'answersToQuestions',
  important: 'important',
  myCashRegister: 'myCashRegister',
  cashManagement: 'cashManagement',
  organizations: 'organizations',
  adminUsers: 'adminUsers',
  users: 'users',
  payments: 'payments',
  paymentsSms: 'paymentsSms',
  taxBills: 'taxBills',
  invoices: 'invoices',
  billings: 'billings',
  clientBalances: 'clientBalances',
  accountingAutomation: 'accountingAutomation',
  acts: 'acts',
  myOrganization: 'myOrganization',
  reconciliationAct: 'reconciliationAct',
  notification: 'notification',
  reviews: 'reviews',
  refunds: 'refunds'
}

export const menuItems = [
  {
    key: menuItemsKeys.answersToQuestionsWelcome,
    text: 'Answers to questions',
    visible: (user) => {
      let show = user.isOwner || user.isEmployee || user.isOwnerWithoutOrganization
      const dateCreated = user?.dateCreated
      if (dateCreated) {
        if (show) {
          // Two weeks
          show = (new Date().getTime() - new Date(dateCreated).getTime()) < 12096e5
        }
      }
      return !user?.globalAllOrganizationsSelected && show
    },
    highlight: () => true,
    children: faqChildren
  },
  {
    key: menuItemsKeys.important,
    text: 'Important',
    visible: (user) => {
      let show = user.isOwner || user.isAdmin || user.isOwnerWithoutOrganization
      const dateCreated = user?.dateCreated
      if (dateCreated) {
        if (show) {
          // Two weeks
          show = (new Date().getTime() - new Date(dateCreated).getTime()) > 12096e5
        }
      }
      const isExpiringKeys = hasExpiringKeys()

      return !user?.globalAllOrganizationsSelected && (show || isExpiringKeys)
    },
    highlight: () => true,
    children: [
      {
        text: 'Payment',
        icon: {
          type: 'e-svg-icon',
          text: 'pay',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user, org) => {
          const hasKopeechkaTag = find(get(org, 'tags'), i => i.id === 'ea4fe1fb-590b-40cd-b458-5f7c48ae6da5')
          if (hasKopeechkaTag) {
            return false
          }
          return Boolean(Invoices.query().where('status', 'invoiced').first())
        },
        href: '/dashboard/payment'
      },
      {
        text: 'Tax reports',
        icon: {
          type: 'e-svg-icon',
          text: 'taxes-report-2',
          options: {
            color: '#a5a4bf',
            size: 'sm'
          }
        },
        visible: (user, org) => {
          const availableReports = taxReports.methods.generateAvailableReports()
          return taxReports.methods.taxReportVisible(org) && availableReports.length
        },
        href: '/dashboard/reports/taxreports'
      },
      {
        text: 'Employees',
        icon: {
          type: 'e-svg-icon',
          text: 'paymaster',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/employees',
        visible: (user) => {
          const isExpiringKeys = hasExpiringKeys()

          return isExpiringKeys && !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        }
      }
    ]
  },
  {
    key: menuItemsKeys.myCashRegister,
    text: 'My cash register',
    icon: {
      type: 'e-svg-icon',
      text: 'cheque',
      options: {
        color: '#a5a4bf',
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isAdmin || user.isEmployee || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant
    },
    children: [
      {
        text: 'Creating a deposit',
        icon: {
          type: 'e-svg-icon',
          text: 'deposit-2',
          options: {
            color: '#a5a4bf',
            size: 'xmd'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && user.isPawnshop,
        href: '/dashboard/createdeposit'
      },
      {
        text: 'Redeem deposit',
        icon: {
          type: 'e-svg-icon',
          text: 'deposit',
          options: {
            color: '#a5a4bf',
            size: 'xmd'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && user.isPawnshop,
        href: '/dashboard/redeemdeposit'
      },
      {
        text: 'Selling',
        icon: {
          type: 'e-svg-icon',
          text: 'sell',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && !user?.isAccountant,
        href: '/dashboard/createreceipts'
      },
      {
        text: 'Return',
        icon: {
          type: 'e-svg-icon',
          text: 'selling',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && !user?.isAccountant,
        href: '/dashboard/refundreceipt'
      },
      {
        text: 'Reports',
        icon: {
          type: 'e-svg-icon',
          text: 'seo-report',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/reports'
      },
      {
        text: 'Delivery',
        icon: {
          type: 'e-svg-icon',
          text: 'shopping-cart',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user, org) => get(org, 'enableOrderDelivery'),
        href: '/dashboard/delivery'
      }
    ]
  },
  {
    key: menuItemsKeys.cashManagement,
    text: 'Cash management',
    icon: {
      type: 'e-svg-icon',
      text: 'cashier',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isAdmin || user.isHeadCashier || user.isEmergencyCashier || user.isEmployee || user.isOwnerWithoutOrganization || user.isAccountant
    },
    children: [
      {
        text: 'Employees',
        icon: {
          type: 'e-svg-icon',
          text: 'paymaster',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/employees',
        visible: (user) => {
          const isExpiringKeys = hasExpiringKeys()

          return !isExpiringKeys && !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        }
      },
      {
        text: 'Cash registers',
        icon: {
          type: 'e-svg-icon',
          text: 'cash-register',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/cashregisters',
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isEmergencyCashier || user.isOwnerWithoutOrganization)
        }
      },
      {
        text: 'Acquiring',
        icon: {
          type: 'e-svg-icon',
          text: 'banking-2',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/acquiring',
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        }
      },
      {
        text: 'Outlets',
        icon: {
          type: 'e-svg-icon',
          text: 'shop',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant)
        },
        href: '/dashboard/outlets'
      },
      {
        text: 'Receipts archive',
        icon: {
          type: 'e-svg-icon',
          text: 'price',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/receipts',
        visible: (user) => {
          return user.isOwner || user.isEmployee || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant
        }
      },
      {
        text: 'Work shifts',
        icon: {
          type: 'e-svg-icon',
          text: 'change',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return user.isOwner || user.isEmployee || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant
        },
        href: '/dashboard/shifts'
      },
      {
        text: 'Goods',
        icon: {
          type: 'e-svg-icon',
          text: 'shopping-cart',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        },
        href: '/dashboard/goods'
      }
    ]
  },
  {
    key: menuItemsKeys.organizations,
    text: 'Organizations',
    icon: {
      type: 'e-flat-icon',
      text: 'flaticon-connection',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/organizations'
  },
  {
    key: menuItemsKeys.users,
    text: 'Users',
    icon: {
      type: 'e-svg-icon',
      text: 'users',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/users'
  },
  {
    key: menuItemsKeys.adminUsers,
    text: 'Admin users',
    icon: {
      type: 'e-svg-icon',
      text: 'users',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin && user.isEmployeeManager
    },
    href: '/dashboard/adminusers'
  },
  {
    key: menuItemsKeys.payments,
    text: 'Payments',
    icon: {
      type: 'e-svg-icon',
      text: 'cashflow',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/payments'
  },
  {
    key: menuItemsKeys.paymentsSms,
    text: 'Payments SMS',
    icon: {
      type: 'e-svg-icon',
      text: 'cashflow',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/smspayments'
  },
  {
    key: menuItemsKeys.taxBills,
    text: 'Tax Bills',
    icon: {
      type: 'e-svg-icon',
      text: 'cashflow',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/taxbills'
  },
  {
    key: menuItemsKeys.invoices,
    text: 'Invoices',
    icon: {
      type: 'e-svg-icon',
      text: 'invoice',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/invoices'
  },
  {
    key: menuItemsKeys.billings,
    text: 'Billings',
    icon: {
      type: 'e-svg-icon',
      text: 'billing',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/billings'
  },
  {
    key: menuItemsKeys.clientBalances,
    text: 'Client balances',
    icon: {
      type: 'e-svg-icon',
      text: 'billing',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/clientbalances'
  },
  {
    key: menuItemsKeys.accountingAutomation,
    text: 'Accounting automation',
    icon: {
      type: 'e-svg-icon',
      text: 'settings',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/accountingautomation'
  },
  {
    key: menuItemsKeys.acts,
    text: 'Acts',
    icon: {
      type: 'e-svg-icon',
      text: 'act',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/acts'
  },
  {
    key: menuItemsKeys.myOrganization,
    text: 'My organization',
    icon: {
      type: 'e-svg-icon',
      text: 'organization',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isAdmin || user.isOwnerWithoutOrganization
    },
    children: [
      {
        text: 'Organization data',
        icon: {
          type: 'e-svg-icon',
          text: 'network',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return !user.isAdmin
        },
        href: '/dashboard/myorganization'
      },
      {
        text: 'Payment',
        icon: {
          type: 'e-svg-icon',
          text: 'pay',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user, org) => {
          if (user?.globalAllOrganizationsSelected || user?.isAccountant) {
            return false
          }
          const hasKopeechkaTag = find(get(org, 'tags'), i => i.id === 'ea4fe1fb-590b-40cd-b458-5f7c48ae6da5')
          if (hasKopeechkaTag) {
            return false
          }
          const dateCreated = user?.dateCreated
          let moreThenTwoWeeks = false
          if (dateCreated) {
            moreThenTwoWeeks = (new Date().getTime() - new Date(dateCreated).getTime()) > 12096e5
          }
          return moreThenTwoWeeks ? !Invoices.query().where('status', 'invoiced').first() : true
        },
        href: '/dashboard/payment'
      },
      {
        text: 'Tax rates',
        icon: {
          type: 'e-svg-icon',
          text: 'taxes',
          options: {
            color: '#a5a4bf',
            size: 'sm'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (auth.getSuperAdminAuthToken() || user.isOwner || user.isAdmin || user.isOwnerWithoutOrganization)
        },
        href: '/dashboard/taxrates'
      },
      {
        text: 'Users',
        icon: {
          type: 'e-svg-icon',
          text: 'users',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && user.isOwner
        },
        href: '/dashboard/organizationusers'
      },
      {
        text: 'Settings',
        icon: {
          type: 'e-svg-icon',
          text: 'settings',
          options: {
            color: '#a5a4bf',
            size: 'xmd'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && user.isOwner
        },
        href: '/profile'
      }
    ]
  },
  {
    key: menuItemsKeys.answersToQuestions,
    text: 'Answers to questions',
    visible: (user) => {
      let show = user.isOwner || user.isEmployee || user.isOwnerWithoutOrganization
      const dateCreated = user?.dateCreated
      if (dateCreated) {
        if (show) {
          // Two weeks
          show = (new Date().getTime() - new Date(dateCreated).getTime()) > 12096e5
        }
      }
      return !user?.globalAllOrganizationsSelected && show
    },
    highlight: () => false,
    children: faqChildren
  },
  {
    key: menuItemsKeys.reconciliationAct,
    text: 'Reconciliation Checkbox act',
    icon: {
      type: 'e-svg-icon',
      text: 'reconciliation-act',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/reconciliationact'
  },
  {
    key: menuItemsKeys.notification,
    text: 'Notification',
    icon: {
      type: 'e-svg-icon',
      text: 'notification-2',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isClientsManager
    },
    href: '/dashboard/notifications'
  },
  {
    key: menuItemsKeys.reviews,
    text: 'Reviews',
    icon: {
      type: 'e-svg-icon',
      text: 'review-2',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/reviews'
  },
  {
    key: menuItemsKeys.refunds,
    text: 'Refunds',
    icon: {
      type: 'e-svg-icon',
      text: 'selling',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/refunds'
  }
]
