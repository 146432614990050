<template lang="pug">
  v-row(no-gutters class="payment-type")
    v-col(v-if="insidePrePaymentModal" cols="12")
      ValidationProvider(
        v-if="isPrepayment"
        vid="prepayment"
        ref="prepaymentField"
        rules="required"
        :name="$t('Postpayment sum')"
        v-slot="{ errors }"
      )
        v-text-field(
          :value="prepayment"
          @input="$emit('update:prepayment', $event)"
          @change="$emit('change:prepayment', $event)"
          :label="`${$t('Postpayment sum')}, ₴`"
          class="received-cash__input mb-2"
          :class="{'mt-2': paymentType === paymentTypes.card.value}"
          outlined
          :error-messages="errors"
          :readonly="paymentType === paymentTypes.other.value"
          :disabled="[paymentTypes.ettn.value, paymentTypes.monobank.value].includes(paymentType)"
          @keypress="floatKeyPress"
        )
    v-col(cols="12" :sm="colsWidth")
      v-radio-group(class="mt-0 payment-type__radio-wrap" v-model="paymentType" :column="isColumn" hide-details)
        v-radio(:label="paymentTypes.cash.label" :value="paymentTypes.cash.value" color="black" :ripple="false")
        v-radio(:label="paymentTypes.card.label" :value="paymentTypes.card.value" color="black" :ripple="false")
        v-row(v-if="visiblePaymentTypes[paymentTypes.monobank.value]" align="center" class="payment-type__mono" :class="{ 'mb-2': isColumn }" no-gutters)
          v-col(cols="auto" class="mr-2")
            v-radio(:label="paymentTypes.monobank.label" :value="paymentTypes.monobank.value" color="black" :ripple="false" :disabled="isPrepayment && !insidePrePaymentModal")
          v-col(cols="auto" v-if="paymentType === paymentTypes.monobank.value")
            v-btn-toggle(v-model="monoUseQr" dark mandatory)
              v-btn(:value="true" small v-if="defaultAcquiringQr" ) офлайн
              v-btn(:value="false" small v-if="allowMonoInternetAcquiring" ) онлайн
        v-radio(v-if="visiblePaymentTypes[paymentTypes.easyPay.value]" :label="paymentTypes.easyPay.label" :value="paymentTypes.easyPay.value" color="black" :ripple="false" :disabled="isPrepayment")
        v-radio(v-if="visiblePaymentTypes[paymentTypes.rozetkaPay.value]" :label="paymentTypes.rozetkaPay.label" :value="paymentTypes.rozetkaPay.value" color="black" :ripple="false" :disabled="isPrepayment")
        v-radio(v-if="visiblePaymentTypes[paymentTypes.ettn.value]" :label="paymentTypes.ettn.label" :value="paymentTypes.ettn.value" color="black" :ripple="false" :disabled="isPrepayment && !insidePrePaymentModal")
        v-radio(v-if="rememberedPaymentType && !redeemDeposit" :label="_.get(rememberedPaymentType, 'label')" :value="_.get(rememberedPaymentType, 'type')" color="black" :ripple="false")
        v-radio(v-if="visiblePaymentTypes[paymentTypes.other.value]" :label="$t(otherPaymentTypeLabel)" :value="paymentTypes.other.value" @click.native="setMixedPaymentTypes" color="black" :ripple="false")
    v-col(cols="12" :sm="paymentType === paymentTypes.cash.value ? 8 : 6" v-if="visiblePaymentTypeFields && !redeemDeposit" :class="{ 'mt-2': xsDevice }")
      v-row(v-if="paymentType === paymentTypes.cash.value && !isReturn && !isPrepayment" :no-gutters="!xsDevice")
        v-col(cols="12" :sm="receivedCash && sumChange ? 6 : 12" class="px-1")
          v-text-field(
            v-model="receivedCash"
            :label="`${$t('Receive Cash')}, ₴`"
            @keypress="floatKeyPress"
            @change="handleReceivedCashChange"
            class="received-cash__input"
            hide-details
            outlined
          )
        v-col(cols="12" sm="6" class="px-1")
          v-text-field(
            v-if="receivedCash && sumChange"
            :value="sumChange"
            readonly
            :label="`${$t('Change (money)')}, ₴`"
            class="received-cash__input"
            hide-details
            outlined
          )
      ValidationProvider(
        vid="eTTN"
        ref="ettnNumberField"
        mode="passive"
        rules="required|length:14|numeric"
        :name="$t('eTTN number')"
        v-slot="{ errors }"
        v-if="paymentType === paymentTypes.ettn.value && !isReturn"
      )
        v-text-field(
          v-model="ettnNumber"
          :loading="hasEttnLoader"
          :error-messages="errors"
          :placeholder="$t('eTTN number')"
          :class="{ 'ml-auto': !xsDevice && !isReturn }"
          class="received-cash__input received-ettn__input"
          outlined
        )
      v-btn(
        v-if="paymentType === paymentTypes.card.value"
        @click="addEpzData"
        class="d-block"
        :class="{ 'ml-auto mt-7': !xsDevice && !isReturn, 'mt-4': isReturn }"
        small
        dark
      ) {{ isReturn ? $t('Change EPZ data') : $t('Add EPZ data') }}
      ValidationProvider(
          v-if="isPrepayment && !insidePrePaymentModal"
          vid="prepayment"
          ref="prepaymentField"
          rules="required"
          :name="$t('Prepayment sum')"
          v-slot="{ errors }"
        )
          v-text-field(
            :value="prepayment"
            @input="$emit('update:prepayment', $event)"
            @change="$emit('change:prepayment', $event)"
            :label="`${$t('Prepayment sum')}, ₴`"
            class="received-cash__input"
            :class="{'mt-2': paymentType === paymentTypes.card.value}"
            outlined
            :error-messages="errors"
            :readonly="paymentType === paymentTypes.other.value"
            @keypress="floatKeyPress"
          )
</template>

<script>
import currencyFormat from '~/mixins/filters/currencyFormat'
import EttnOrders from '~/modules/ettn/models/EttnOrders'
import contentDialog from '~/mixins/dialogs/contentDialog'
import ReceiptPaymentMethods from '~/modules/receipt/models/ReceiptPaymentMethods'
import responsive from '~/mixins/pages/responsive'
import authData from '~/modules/receipt/mixins/getters/authData'
import Dialog from '~/models/system/Dialog'
import paymentTypes from '~/modules/receipt/mixins/paymentTypes'

export default {
  mixins: [contentDialog, responsive, authData, currencyFormat, paymentTypes],
  props: {
    column: {
      type: Boolean,
      default: true
    },
    redeemDeposit: {
      type: Boolean,
      default: false
    },
    notSetDefault: {
      type: Boolean,
      default: false
    },
    isReturn: {
      type: Boolean,
      default: false
    },
    processingReceipt: {
      type: Object,
      default: () => {}
    },
    totalPaySum: {
      type: Number,
      default: 0
    },
    isPrepayment: {
      type: Boolean,
      default: false
    },
    prepayment: {
      type: [String, Number],
      default: null
    },
    insidePrePaymentModal: {
      type: Boolean,
      defeult: false
    }
  },
  data: () => ({
    paymentType: 'CASH',
    receivedCash: null,
    otherPaymentTypeLabel: 'Other',
    otherPaymentTypes: [],
    rememberedPaymentType: null,
    monoUseQr: true,
    paymentMethodsReceived: false,
    hasEttnLoader: false,
    ettnNumber: null,
    epzData: {},
    ettn: null
  }),
  computed: {
    colsWidth () {
      if (this.isReturn) {
        return '12'
      } else if (this.paymentType === this.paymentTypes.cash.value) {
        return '4'
      } else {
        return '6'
      }
    },
    isColumn () {
      return this.column && !this.xsDevice
    },
    hasEttnToken () {
      return Boolean(this._.get(this.authenticatedCashier, 'ettnSettings.token', false))
    },
    hasAcquiringToken () {
      return Boolean(this._.get(this.authenticatedCashier, 'acquiringSetting.token', false))
    },
    allowMonoInternetAcquiring () {
      return Boolean(this._.get(this.authenticatedCashier, 'acquiringSetting.allowInternetAcquiring', false))
    },
    hasEasyPayServiceKey () {
      return Boolean(this._.get(this.authenticatedCashier, 'cashRegister.easyPayAcquiringSetting.token', false))
    },
    hasRozetkaPaySettings () {
      return Boolean(this._.get(this.authenticatedCashier, 'cashRegister.rozetkaPaySetting.login', false))
    },
    defaultAcquiringQr () {
      const codes = this._.get(this.authenticatedCashier, 'acquiringSetting.qrCodes', null)
      if (codes && Array.isArray(codes)) {
        return codes.find(item => item.default)
      }
      return null
    },
    paymentsPayload () {
      const payments = []
      if (this.paymentType === this.paymentTypes.cash.value) {
        payments.push({
          type: this.paymentTypes.cash.value,
          label: this.paymentTypes.cash.label,
          value: this.isPrepayment ? Math.round(this.prepayment * 100) : Math.round(this.receivedCash * 100) || 0
        })
      } else if (
        this.paymentType === this.paymentTypes.card.value ||
        this.paymentType === this.paymentTypes.monobank.value ||
        this.paymentType === this.paymentTypes.easyPay.value ||
        this.paymentType === this.paymentTypes.rozetkaPay.value
      ) {
        payments.push({
          type: this.paymentTypes.card.value,
          label: this.paymentTypes.card.label,
          card_mask: '',
          value: this.isPrepayment ? Math.round(this.prepayment * 100) : 0,
          ...this.epzData
        })
      } else if (this.paymentType === this.paymentTypes.cashless.value && this.rememberedPaymentType) {
        payments.push({
          id: this._.get(this.rememberedPaymentType, 'id'),
          type: this._.get(this.rememberedPaymentType, 'type'),
          label: this._.get(this.rememberedPaymentType, 'label'),
          card_mask: '',
          value: this.isPrepayment ? Math.round(this.prepayment * 100) : 0
        })
      } else if (this.paymentType === this.paymentTypes.other.value && !this._.isEmpty(this.otherPaymentTypes)) {
        this.otherPaymentTypes.forEach((payment) => {
          payments.push({
            id: payment.id,
            type: payment.type,
            label: this.$t(payment.label),
            value: Math.round(payment.amount * 100) || 0,
            card_mask: ''
          })
        })
      } else if (this.paymentType === this.paymentTypes.ettn.value) {
        payments.push({
          type: this.paymentTypes.ettn.value,
          label: 'Безготівкова',
          value: 0,
          ettn: this.ettnNumber
        })
      }

      return payments
    },
    sumChange () {
      const change = +this.receivedCash - this.totalPaySum
      return change.toFixed(2)
    },
    overTopContentDialog () {
      return Dialog.query().where('type', 'content_over_top').first()
    },
    visiblePaymentTypes () {
      return {
        [this.paymentTypes.cash.value]: true,
        [this.paymentTypes.card.value]: true,
        [this.paymentTypes.other.value]: !this.redeemDeposit,
        [this.paymentTypes.cashless.value]: true,
        [this.paymentTypes.monobank.value]: !this.isReturn && !this.redeemDeposit && this.hasAcquiringToken,
        [this.paymentTypes.easyPay.value]: !this.isReturn && this.hasEasyPayServiceKey && !this.insidePrePaymentModal,
        [this.paymentTypes.rozetkaPay.value]: !this.isReturn && this.hasRozetkaPaySettings && !this.insidePrePaymentModal,
        [this.paymentTypes.ettn.value]: !this.isReturn && this.hasEttnToken
      }
    },
    visiblePaymentTypeFields () {
      return [this.paymentTypes.cash.value, this.paymentTypes.card.value, this.paymentTypes.ettn.value, this.paymentTypes.other.value, this.paymentTypes.cashless.value].includes(this.paymentType)
    }
  },
  watch: {
    ettn () {
      this.$emit('changeEttnNumber', this.ettn)
    },
    ettnNumber: {
      handler (val) {
        this.ettn = null
        if (val && val.length) {
          this.$nextTick(async () => {
            const { valid } = await this.$refs.ettnNumberField.validate()
            this.ettnNumber = val.replace(/\s/g, '')

            if (valid) {
              await this.requestEttnNumber()
            }
          })
        }
      }
    },
    paymentsPayload (val) {
      this.$emit('input', val)
    },
    paymentType (val) {
      const receiptPayment = this._.get(this.processingReceipt, 'payments[0]')
      const isCardReceiptPayment = this._.get(receiptPayment, 'type') === this.paymentTypes.card.value || this._.get(receiptPayment, 'type') === this.paymentTypes.cashless.value
      if (val === this.paymentTypes.card.value && isCardReceiptPayment && this.isReturn) {
        this.epzData = {
          card_mask: this._.get(receiptPayment, 'card_mask', null),
          auth_code: this._.get(receiptPayment, 'auth_code', null),
          rrn: this._.get(receiptPayment, 'rrn', null),
          payment_system: this._.get(receiptPayment, 'payment_system', null),
          owner_name: this._.get(receiptPayment, 'owner_name', null),
          terminal: this._.get(receiptPayment, 'terminal', null),
          bank_name: this._.get(receiptPayment, 'bank_name', null)
        }
      } else {
        this.epzData = {}
      }
      this.$emit('changePaymentType', val)
    },
    async authenticatedCashier (val, prevVal) {
      if (!this.notSetDefault && this._.get(val, 'accessToken') !== this._.get(prevVal, 'accessToken')) {
        await this.setRememberedPaymentType(this._.get(this.$localStorageClientData(), 'receipt.paymentType', null))
      }
    },
    otherPaymentTypes (val) {
      if (val.length && this.isPrepayment) {
        const otherTotalSum = this.sumAllOtherPayments()
        this.$emit('update:prepayment', otherTotalSum)
      }
    },
    isPrepayment (val) {
      if (val && this.otherPaymentTypes.length) {
        const otherTotalSum = this.sumAllOtherPayments()
        this.$emit('update:prepayment', otherTotalSum)
      }
    }
  },
  mounted () {
    if (this.allowMonoInternetAcquiring && !this.defaultAcquiringQr) {
      this.monoUseQr = false
    } else if (!this.allowMonoInternetAcquiring && this.defaultAcquiringQr) {
      this.monoUseQr = true
    }
  },
  async created () {
    if (!this.notSetDefault) {
      await this.setRememberedPaymentType(this._.get(this.$localStorageClientData(), 'receipt.paymentType', null))
    }
    this.$emit('input', this.paymentsPayload)
    this.$emit('changePaymentType', this.paymentType)
    this.$emit('changeEttnNumber', this.ettn)
  },
  methods: {
    handleReceivedCashChange (val) {
      if (val) {
        const parsedVal = parseFloat(val)
        this.receivedCash = parsedVal.toFixed(1) + '0'
      }
    },
    sumAllOtherPayments () {
      return this.otherPaymentTypes.reduce((acc, item) => {
        acc += parseFloat(item.amount)
        return acc
      }, 0)
    },
    createEpzDataDialogConfig () {
      return {
        component: 'block-epz-data-form',
        width: '600px',
        componentProps: {
          item: this.epzData
        }
      }
    },
    async addEpzData () {
      let data
      const epzDataDialogConfig = this.createEpzDataDialogConfig()
      if (this.insidePrePaymentModal) {
        data = await this.overTopContentDialog.open(epzDataDialogConfig)
      } else {
        data = await this.contentDialog.open(epzDataDialogConfig)
      }
      if (data) {
        this.$set(this.$data, 'epzData', data)
      }
    },
    async requestEttnNumber () {
      try {
        this.hasEttnLoader = true
        const request = await EttnOrders.api().validate({
          cashRegister: this._.get(this.authenticatedCashier, 'cashRegister.id', ''),
          ttn: this.ettnNumber
        })

        this.ettn = this._.get(request, 'response.data', null)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.hasEttnLoader = false
      }
    },
    async setRememberedPaymentType (paymentType) {
      const rememberedPaymentType = paymentType
      if (rememberedPaymentType) {
        const { type, id } = rememberedPaymentType
        if (type === this.paymentTypes.cashless.value) {
          this.paymentType = this.paymentTypes.cashless.value
          this.rememberedPaymentType = rememberedPaymentType

          if (id !== 'bonus' && id !== 'iban') {
            await ReceiptPaymentMethods.api().all()
            const receiptPaymentMethods = ReceiptPaymentMethods.query().all()
            const paymentMethod = this._.head(this._.filter(receiptPaymentMethods, method => method.id === id))

            if (!paymentMethod) {
              this.resetData()
              this.clearRememberedPaymentType()
            }
          }
        } else if (this.visiblePaymentTypes[type]) {
          this.paymentType = type
        }
      }
    },
    rememberPaymentType () {
      const payload = {
        receipt: {
          paymentType: null
        }
      }
      if (this._.isArray(this.paymentsPayload) && this.paymentsPayload.length === 1) {
        if (this.paymentType === this.paymentTypes.other.value) {
          payload.receipt.paymentType = {
            id: this._.get(this.otherPaymentTypes, '[0].id', null),
            ...this.paymentsPayload[0]
          }
        } else if (this.paymentType === this.paymentTypes.monobank.value) {
          payload.receipt.paymentType = {
            type: this.paymentTypes.monobank.value
          }
        } else if (this.paymentType === this.paymentTypes.easyPay.value) {
          payload.receipt.paymentType = {
            type: this.paymentTypes.easyPay.value
          }
        } else if (this.paymentType === this.paymentTypes.rozetkaPay.value) {
          payload.receipt.paymentType = {
            type: this.paymentTypes.rozetkaPay.value
          }
        } else {
          payload.receipt.paymentType = this.paymentsPayload[0]
        }
      }
      this.$setLocalStorageClientData(payload)
      return payload.receipt.paymentType
    },
    clear () {
      const paymentType = this.rememberPaymentType()
      this.resetData()
      this.setRememberedPaymentType(paymentType)
    },
    clearRememberedPaymentType () {
      this.rememberedPaymentType = null
      this.$setLocalStorageClientData({
        receipt: {
          paymentType: null
        }
      })
    },
    resetData () {
      this.paymentType = this.paymentTypes.cash.value
      this.receivedCash = null
      this.otherPaymentTypeLabel = this.paymentTypes.other.label
      this.otherPaymentTypes = []
      this.rememberedPaymentType = null
      this.ettnNumber = null
      this.epzData = {}
    },
    createSetMixedPaymentTypesDialogConfig () {
      return {
        component: 'm-block-receipt-sale-mixed-payment-types',
        width: '900px',
        contentNoGutters: true,
        componentProps: {
          payments: this.otherPaymentTypes,
          paymentMethodsReceived: this.paymentMethodsReceived,
          onPaymentMethodsReceived: (val) => {
            this.paymentMethodsReceived = val
          }
        }
      }
    },
    async setMixedPaymentTypes () {
      this.clearRememberedPaymentType()
      let paymentTypes
      const setMixedPaymentTypesDialogConfig = this.createSetMixedPaymentTypesDialogConfig()
      if (this.insidePrePaymentModal) {
        paymentTypes = await this.overTopContentDialog.open(setMixedPaymentTypesDialogConfig)
      } else {
        paymentTypes = await this.contentDialog.open(setMixedPaymentTypesDialogConfig)
      }

      this.paymentType = this.paymentTypes.other.value
      if (this._.isEmpty(paymentTypes)) {
        this.otherPaymentTypeLabel = this.paymentTypes.other.label
        this.otherPaymentTypes = []
        this.paymentType = this.paymentTypes.cash.value
        return
      }
      this.otherPaymentTypeLabel = paymentTypes.reduce((acc, val, index, arr) => this.$t(acc) + (acc ? '+' : '') + this.$t(val.label), '')
      this.otherPaymentTypes = paymentTypes
    }
  }
}
</script>

<style scoped lang="scss">
.payment-type {
  ::v-deep {
    .v-input:not(.v-input--radio-group--column) {
      .v-input--radio-group__input {
        flex-wrap: wrap;
        gap: 5px 15px;
      }
    }
    .v-label {
      min-width: 170px;
    }
  }

  &__mono {
    flex: 0;
    flex-wrap: nowrap;
    gap: 8px;
  }
}

.received-cash {
  &__input {
    border-radius: 12px;
  }
}
.received-ettn {
  &__input {
    margin-top: 4rem;
  }
}
</style>
