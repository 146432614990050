import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'

const allowedOrgs = [
  // '9962f238-42b9-43fc-aa0c-81ff148b19cc'
]

const svoeIT = {
  mixins: [processingReceipt],
  methods: {
    checkHtmlPrintOrg () {
      return allowedOrgs.includes(this._.get(this.$Organization, 'id'))
    },
    async printHtml (id) {
      const res = await this.createHtmlReceiptPreview(id)
      if (res) {
        const w = window.open('', 'PRINT', 'height=800,width=600')
        w.document.write(res)
        w.document.close() // necessary for IE >= 10
        w.focus() // necessary for IE >= 10*/
        w.print()
      }
    }
  }
}

export default svoeIT
