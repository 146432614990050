<template lang="pug">
  div(class="goods-removal")
    h3(class="goods-removal__title") {{ title }}
    p(class="my-0") {{ $t('If the group has goods with balances, you can move them to another group, or delete and write off') }}
    div(class="pt-8 d-flex justify-center")
      v-btn(
        class="color-error secondary-button"
        :class="{ 'mb-3 w-100 mr-0': xsDevice, 'mr-4': !xsDevice }"
        :loading='writeOffAndDeleteLoading'
        @click="writeOffAndDelete"
        :disabled="moveToGroupAndDeleteLoading"
      ) {{ $t('Write off and delete') }}
      t-fields(
        :items="autocompleteBtn"
        v-bind.sync="$data"
        :cols="{ cols: 12 }"
        :classes="['py-0']"
        no-margin-top
      )
      v-btn(v-if='selectedGroup' class="ml-1 secondary-button" :loading="moveToGroupAndDeleteLoading" @click="moveToGroupAndDelete(selectedGroup)" outlined) Ок
</template>

<script>
import DOMPurify from 'dompurify'
import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import TFields from '~/components/templates/t-fields'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'MBlockGoodsGroupRemoval',
  components: { TFields },
  mixins: [responsive],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    writeOffAndDeleteLoading: false,
    moveToGroupAndDeleteLoading: false,
    selectedGroup: null
  }),
  computed: {
    model () {
      return GoodsGroups
    },
    autocompleteBtn () {
      return [
        new AutocompleteBuilder({
          model: 'selectedGroup',
          label: 'Move to another group',
          returnObject: true,
          hideDetails: true,
          disabled: this.moveToGroupAndDeleteLoading || this.writeOffAndDeleteLoading,
          query: model => model.api(),
          itemsFilter: items => this._.filter(items, item => this._.get(item, 'id') !== this._.get(this.item, 'id'))
        }, GoodsGroupsSearch).build()
      ]
    },
    title () {
      return this.$t('Delete group?', { name: DOMPurify.sanitize(this._.get(this.item, 'name', '')) })
    }
  },
  methods: {
    async writeOffAndDelete () {
      try {
        this.writeOffAndDeleteLoading = true
        await AccountingGoods.api().deleteAllInGroup({ groupId: this._.get(this.item, 'id') })
        await this.deleteGroup()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.writeOffAndDeleteLoading = false
      }
    },
    async moveToGroupAndDelete (group) {
      try {
        this.moveToGroupAndDeleteLoading = true
        await this.model.api().moveGoods(this._.get(this.item, 'id'), { toGroup: this._.get(group, 'id') })
        await this.model.api().read(this._.get(group, 'id'))
        await this.deleteGroup()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.moveToGroupAndDeleteLoading = false
      }
    },
    async deleteGroup () {
      try {
        await this.model.api().del(this.item)
        GoodsGroupsSearch.delete(this._.get(this.item, 'id'))
        this.$root.$emit('goodsGroupsChangeAction', { action: 'delete', group: this.item })
        this.closeModal()
        this.$notification.success('Group successfully deleted')
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.goods-removal {
  padding: 32px 0 25px;

  &__title {
    color: #161b25;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 32px;
  }

  &::v-deep {
    .v-autocomplete {
      .primary--text,
      .v-label {
        color: #161b25 !important;
      }
      fieldset {
        border: 2px solid #161b25;
        border-radius: 10px;
      }
    }
  }
}
</style>
