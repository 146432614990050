<template lang="pug">
  div(class="dialog")
    span(v-if="currentStep" class="dialog__stepper") {{ $t('Step') }} {{ currentStep }} / {{ maxStep }}
    div(class="dialog__title")
      span {{ $t(title) }}
      div(
        v-if="showTerminalSettingsForm"
        class="dialog__link"
      )
        e-link(
          href="https://wiki.checkbox.ua/uk/app/pc/portal_acquiring"
        ) {{ $t('Instructions') }} &#x2192;
    div(class="dialog__content")
      block-pos-terminal-connecting-options(
        v-if="showTerminalOptions"
        @showTerminalSettingsForm="onShowTerminalSettingsForm"
        @showBankSelectionForm="onShowBankSelectionForm"
      )
      block-pos-terminal-settings-form(
        v-else-if="showTerminalSettingsForm"
        :closeModal="closeModal"
      )
      block-pos-terminal-bank-selection(
        v-else-if="showBankSelectionForm"
        @showOrderTerminalForm="onShowOrderTerminalForm"
        @changeSelectedBank="onChangeSelectedBank"
        :banks="banks"
      )
      div(v-else-if="showOrderTerminalForm && selectedBank")
        block-a-bank-connecting(
          v-if="selectedBank === banks.aBank"
          @tableRequest="tableRequest"
          @changeTitle="changeTitle"
          @closeModal="closeModal"
          @toggleCloseBtn="toggleCloseBtn"
          @showBankSelectionForm="onShowBankSelectionForm"
          @changeDialogWidth="changeDialogWidth"
          @step="currentStep = $event"
          @maxStep="maxStep = $event"
        )
</template>

<script>
import BlockABankConnecting from '~/modules/acquiring/views/a-bank/block-a-bank-connecting'
import BlockPosTerminalSettingsForm from '~/modules/acquiring/views/block-pos-terminal-settings-form'
import BlockPosTerminalConnectingOptions from '~/modules/acquiring/views/block-pos-terminal-connecting-options'
import BlockPosTerminalBankSelection from '~/modules/acquiring/views/block-pos-terminal-bank-selection'
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockPosTerminalForm',
  components: {
    ELink,
    BlockPosTerminalBankSelection,
    BlockPosTerminalConnectingOptions,
    BlockPosTerminalSettingsForm,
    BlockABankConnecting
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    changeDialogWidth: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    isFromBanner: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedBank: null,
    title: 'Add POS-terminal',
    showTerminalOptions: true,
    showTerminalSettingsForm: false,
    showOrderTerminalForm: false,
    showBankSelectionForm: false,
    currentStep: null,
    maxStep: null
  }),
  computed: {
    banks () {
      return {
        aBank: 'aBank',
        oschad: 'oschad'
      }
    }
  },
  created () {
    this.$gtm.push({
      event: 'open_add_pos_terminal_dialog',
      organization: this._.get(this.$Organization, 'owner.email')
    })
    if (this.isFromBanner) {
      this.selectedBank = this.banks.aBank
      this.onShowOrderTerminalForm(true)
    }
  },
  methods: {
    changeTitle (title) {
      this.title = title
    },
    onChangeSelectedBank (val) {
      this.selectedBank = val
    },
    onShowTerminalSettingsForm (val) {
      this.showTerminalSettingsForm = val
      this.showBankSelectionForm = false
      this.showOrderTerminalForm = false
      this.showTerminalOptions = false
    },
    onShowBankSelectionForm (val) {
      this.showBankSelectionForm = val
      this.showTerminalSettingsForm = false
      this.showOrderTerminalForm = false
      this.showTerminalOptions = false
    },
    onShowOrderTerminalForm (val) {
      this.showOrderTerminalForm = val
      this.showTerminalSettingsForm = false
      this.showBankSelectionForm = false
      this.showTerminalOptions = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__content {
    padding-top: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;

    &::v-deep {
      .e-link {
        font-weight: 700;
      }
    }
  }

  &__stepper {
    display: inline-block;
    padding: 1px 6px;
    border-radius: 6px;
    background: #F0F1F2;
    align-self: flex-start;

    color: #161B25;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.11px;
  }
}
</style>
