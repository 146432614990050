<template lang="pug">
  div(class="dialog")
    iframe(
      id="print-iframe"
      style="display: none"
    )
    div(class="dialog__actions")
      v-btn(
        id="priceTagsPrintButton"
        class="secondary-button dialog__btn"
        :loading="loading"
        @click="print"
        dark
      ) {{ $t('Print ') }}
      v-btn(
        id="priceTagsPdfButton"
        class="secondary-button dialog__btn"
        :loading="loading"
        @click="downloadPdf"
        dark
      ) PDF
    div(class="dialog__content")
      div(
        ref="priceTagsWrapper"
        :style="styles.priceTagsWrapper"
      )
        div(
          v-for="(item, index) in items"
          :key="index"
        )
          div(
            v-if="index && !(index % 24)"
            class="page-break"
          )
          div(:style="styles.priceTag")
            div(
              v-if="_.get(options, 'code')"
              :style="styles.priceTagCode"
            )
              span(:style="{ padding: '0 3px' }") Код: {{ item.code }}
            div(
              v-if="_.get(options, 'price') || _.get(options, 'name')"
              :style="getPriceNameWrapperStyles(item)"
            )
              div
                div(
                  v-if="_.get(options, 'price')"
                  :style="styles.priceTagPrice"
                ) {{ item.price | currencyFormat }} грн.
                div(
                  v-if="_.get(options, 'name')"
                  :style="styles.priceTagName"
                ) {{ item.name }}
            div(
              v-if="_.get(options, 'barcode') && _.get(item, 'barcode')"
              :style="styles.priceTagBarcode"
            )
              svg(
                :id="`price-tag-${_.get(item, 'id')}-${index}`"
              ) {{ generateBarcode(item.barcode, `price-tag-${_.get(item, 'id')}-${index}`) }}
</template>

<script>
import html2pdf from 'html2pdf.js'
import JsBarcode from 'jsbarcode'
import currencyFormat from '~/mixins/filters/currencyFormat'

export default {
  name: 'BlockGoodsPriceTagsPrint',
  mixins: [currencyFormat],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    styles () {
      return {
        priceTagsWrapper: {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '4px'
        },
        priceTag: {
          pageBreakInside: 'avoid',
          pageBreakAfter: 'auto',
          border: '1px solid #000',
          width: '227px',
          height: '130px',
          color: '#000',
          textAlign: 'center',
          wordBreak: 'break-word',
          display: 'flex',
          flexDirection: 'column'
        },
        priceTagCode: {
          width: '100%',
          borderBottom: '1px solid #000',
          fontSize: '12px',
          lineHeight: '1.6',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        },
        priceTagPriceNameWrapper: {
          width: '100%',
          flexGrow: '1',
          position: 'relative',
          overflow: 'hidden',
          display: 'grid',
          placeItems: 'center'
        },
        priceTagPrice: {
          margin: '6px 0',
          padding: '0 3px',
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '1.2',
          textDecoration: 'underline',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        },
        priceTagName: {
          lineHeight: '1.2',
          margin: '0 0 6px',
          padding: '0 3px',
          fontSize: '12px'
        },
        priceTagBarcode: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px 0'
        }
      }
    }
  },
  methods: {
    generateBarcode (barcode, id) {
      this.$nextTick(() => {
        const el = document.getElementById(id)
        if (id && barcode && el) {
          const formattedBarcode = barcode.replace(/\s/g, '')
          JsBarcode(`#${id}`, formattedBarcode, {
            flat: true,
            margin: 0,
            marginLeft: 7,
            marginRight: 7,
            width: 1.2,
            height: 30,
            displayValue: false
          })
        }
      })
      return null
    },
    getPriceNameWrapperStyles (item) {
      const showBarcode = this._.get(this.options, 'barcode') && this._.get(item, 'barcode')
      return {
        ...this.styles.priceTagPriceNameWrapper,
        ...(showBarcode ? { borderBottom: '1px solid #000' } : {})
      }
    },
    print () {
      const iframe = document.getElementById('print-iframe')
      const cw = iframe && iframe.contentWindow

      if (cw) {
        cw.document.open()
        cw.document.write(this._.get(this.$refs, 'priceTagsWrapper.outerHTML', ''))
        cw.document.close()
        setTimeout(() => {
          cw.print()
        }, 0)
      }
    },
    downloadPdf () {
      const iframe = document.getElementById('print-iframe')
      const cw = iframe && iframe.contentWindow

      if (cw) {
        cw.document.write(this._.get(this.$refs, 'priceTagsWrapper.outerHTML', ''))
        html2pdf().set({
          margin: [3, 6, 0, 3],
          filename: 'price-tags.pdf',
          jsPDF: {
            format: 'a4',
            orientation: 'portrait'
          },
          pagebreak: {
            mode: 'css',
            after: '.page-break'
          }
        }).from(cw.document.body).save()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 15px 0 30px;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 10px;
  }

  &__btn {
    min-width: 150px !important;
  }
}
</style>
