<template lang="pug">
  div(class="block-qr-acquiring-form")
    div(class="dialog__content-flex")
      div(class="dialog__left")
        h3(class="block-qr-acquiring-form__title")
          v-btn(
            v-if="showBackBtn"
            class="back-arrow"
            @click="handleStepBack"
            icon
            small
          )
            e-svg-icon(size="sm") arrow-next
          span {{ isEditing ? $t('Edit QR acquiring') : $t('Add QR acquiring') }}
        div(
          v-if="step !== steps.bankSelection && xsDevice"
          class="mb-6"
        )
          block-acquiring-form-hint(
            mobile
            :currentStep="step"
            :steps="steps"
            acquiringType="qr"
            :isEditing="isEditing"
          )
        m-block-acquiring-bank-selection(
          v-if="step === steps.bankSelection"
          :banks="banks"
          @changeSelectedBank="onChangeSelectedBank"
        )
        block-monobank-qr(
          v-else-if="step === steps.monoQr"
          :close-modal="closeModal"
          @getMonobankQrData="setQrTerminals"
          @showNextStep="handleStep({ prevStep: steps.monoQr, nextStep: steps.settingsForm })"
        )
        block-monobank-acquiring-settings-form(
          v-else-if="step === steps.settingsForm"
          :terminals="qrTerminals"
          :mono-account-name="monoName"
          type="qr"
          :close-modal="closeModal"
          :table-request="tableRequest"
          :is-editing="isEditing"
          :item="item"
          :show-token-input="showTokenManual"
          @changeMonoAccount="handleStep({ prevStep: steps.bankSelection, nextStep: steps.monoQr })"
        )
      div(v-if="step !== steps.bankSelection && !xsDevice" class="dialog__right")
        block-acquiring-form-hint(
          :currentStep="step"
          :steps="steps"
          acquiringType="qr"
          :isEditing="isEditing"
          @setTokenManual="handleManual"
        )
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import MBlockAcquiringBankSelection from '~/modules/acquiring/views/m-block-acquiring-bank-selection'
import BlockMonobankQr from '~/modules/acquiring/views/monobank/block-monobank-qr'
import BlockMonobankAcquiringSettingsForm from '~/modules/acquiring/views/monobank/block-monobank-acquiring-settings-form'
import BlockAcquiringSuccess from '~/modules/acquiring/views/block-acquiring-success'
import BlockAcquiringFormHint from '~/modules/acquiring/views/block-acquiring-form-hint'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockQrAcquiringForm',
  components: {
    ESvgIcon,
    MBlockAcquiringBankSelection,
    BlockMonobankQr,
    BlockMonobankAcquiringSettingsForm,
    BlockAcquiringSuccess,
    BlockAcquiringFormHint
  },
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    // for Edit
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      step: 'bankSelection',
      prevStep: null,
      selectedBank: null,
      monobankQrData: null,
      qrTerminals: null,
      monoName: null,
      showTokenManual: false
    }
  },
  computed: {
    banks () {
      return [
        {
          key: 'mono',
          visible: () => true
        }
      ]
    },
    steps () {
      return {
        bankSelection: 'bankSelection',
        monoQr: 'monoQr',
        settingsForm: 'settingsForm'
      }
    },
    showBackBtn () {
      return [this.steps.monoQr].includes(this.step)
    }
  },
  watch: {
    selectedBank (val) {
      // decide acquiring flow depending on selected bank
      if (val) {
        switch (val) {
          case 'mono':
            this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.monoQr })
            break
        }
      }
    }
  },
  created () {
    if (this.isEditing) {
      this.handleStep({ prevStep: null, nextStep: this.steps.settingsForm })
    }
  },
  methods: {
    handleStep ({ prevStep, nextStep }) {
      this.step = nextStep
      this.prevStep = prevStep
    },
    handleStepBack () {
      this.selectedBank = null
      this.handleStep({ prevStep: null, nextStep: this.prevStep })
    },
    setQrTerminals (data) {
      this.monoName = this._.get(data, 'name')
      this.qrTerminals = this._.filter(data.terminals, terminal => terminal.type === 'qr')
      if (this.qrTerminals && !this.qrTerminals.length) {
        this.closeModal({
          terminals: this.qrTerminals,
          success: false
        })
      }
    },
    onChangeSelectedBank (val) {
      this.selectedBank = val
    },
    handleManual () {
      this.showTokenManual = true
      this.handleStep({ prevStep: this.steps.monoQr, nextStep: this.steps.settingsForm })
    }
  }
}
</script>

<style scoped lang="scss">
.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}

.block-qr-acquiring-form {
  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }
}
</style>
