<template lang="pug">
  div(class="info-hint info-hint--vertical" v-if="(hint || showHint) && !_.get($User, 'isSuperAdmin')")
    div(v-if="hint" class="d-flex align-center")
      span(class="info-hint__bulb") 💡
      div
        b {{ $t('Hint') }}:
        span(class="ml-1") {{ $t(hint) }}
    div(v-else)
      div(class="d-flex align-center" v-if="showRefreshDataHint")
        span(class="info-hint__bulb") 💡
        div
          b {{ $t('Hint') }}:
          span
            span(class="ml-1") {{ $t('after 3-5 minutes update page or click the "Update Data" button to make sure') }}
            span(class="ml-1") {{ $tc('tableFooterHint.' + _.get(model, 'ormTrans.single', ''), 1) }} {{ $t('in sfs') }}.
      div(class="d-flex align-center" v-else-if="showHintForTax")
        span(class="info-hint__bulb") 💡
        div
          b {{ $t('Warning') }}!
          span(class="ml-1") {{ $t('Changes in tax rates will be applied only in the next cash register shift') }}
      div(class="d-flex" v-if="showEmployeeHint")
        span(class="info-hint__bulb") 💡
        div
          div(class="mb-3")
            | Для реєстрації нового касира, натисніть
            |
            b «Синхронізувати дані з ДПС»
            |
            | та скористайтесь ключем директора Вашої організації → “Додати касира” та завантажте ключ Вашого касира.
          div
            | Якщо у Вас закінчується строк дії електронного ключа (печатки) касира - отримайте новий ключ (печатку) у Вашому АЦСК → натисніть
            |
            b “Синхронізувати дані з ДПС»
            |
            | та скористайтесь ключем директора Вашої організації. Далі натисніть “Дії” (три крапки) біля необхідного касира -> "Заміна ключа" та завантажте новий ключ Вашого касира.
      div(class="d-flex align-center" v-if="!this.$Organization")
        span(class="info-hint__bulb") 💡
        b {{ $t('Hint') }}:
        span(class="ml-1") {{ $t('To receive a test data, please add your organization through EDS key') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    ESvgIcon
  },
  mixins: [responsive],
  props: {
    model: {
      type: Function,
      required: true,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    entityExists: false,
    showHintForTax: false,
    showEmployeeHint: false
  }),
  computed: {
    showHint () {
      if (!this.$Organization) {
        return true
      }
      return (this.showRefreshDataHint || this.showHintForTax || this.showEmployeeHint) && this.$User.isOwner
    },
    isRightEntity () {
      return this._.get(this.model, 'entity', '') === 'cashregisters' ||
        this._.get(this.model, 'entity', '') === 'employee' ||
        this._.get(this.model, 'entity', '') === 'outlet'
    },
    showRefreshDataHint () {
      const orderEntity = this.model.orderEntity
      if (orderEntity) {
        return Boolean(orderEntity.query().where('status', orderEntity.orderStatuses['200'].value).exists())
      }
      return Boolean(this.model.query().where((entity) => {
        const status = this._.get(entity, 'status', null)
        return status === 'doc_saved_kvt_1' || status === 'awaiting_send_to_tax'
      }).exists())
    }
  },
  async created () {
    if (!this.$Organization) {
      return
    }
    if (this.isRightEntity) {
      try {
        const res = await this.model.api().all({ itemsPerPage: 1, page: 1 }, { save: false })
        this.entityExists = this._.get(res, 'response.data.meta.totalItems', 0)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
    if (this._.get(this.model, 'entity', '') === 'taxes') {
      this.showHintForTax = true
    }
    if (this._.get(this.model, 'entity', '') === 'employee') {
      this.showEmployeeHint = true
    }
  }
}
</script>

<style scoped lang="scss">
</style>
