<template lang="pug">
  div(v-on="listeners" style="padding:22px 5px 19px")
    div(style="overflow-y: auto; max-height: 609.7px")
      iframe(ref="receiptDoc" class="d-none")
      div(style="min-height:200px")
        v-overlay(absolute :value="viewLoading")
          e-progress-circular
        div(v-dompurify-html="receiptHtml" v-if="view === 'html' && !notFound")
        pre(v-if="view==='text' && !notFound") {{view}}
        div(v-if='notFound' style="text-align:center; margin-top:50px")
          | {{$t('Requested preview not exist. Please contact with manager.')}}
    div
      v-row(v-if="view")
        v-col(cols="12" class="col-links")
          e-link(v-if="this.type === 'receipt'" @click="sendToEmail" underline class="e-link") {{$t('Sent to email')}}
          e-link(v-if="this.type === 'receipt'" @click="copyToClipboard" underline class="e-link") {{$t('Copy link')}}
          v-btn(color="black" :loading="downloadLoading"  @click="downloadReceipt" dark block)
            e-svg-icon(class="mr-2") download
            | {{$t('Download')}}
        v-col(cols="12")
          v-btn(color="black" :loading="printLoading" @click="print" dark block)
            e-svg-icon(class="mr-2") printer
            | {{$t('Print')}}
</template>

<script>
import Download from 'js-file-download'
import AppNotifications from '~/services/Notifications/AppNotifications'
import TDialog from '~/components/templates/t-dialog'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import converters from '~/mixins/methods/converters'
import clipboard from '~/mixins/global/_clipboard'
import ELink from '~/components/elements/links/e-link'
import Receipts from '~/modules/receipt/models/Receipts'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/models/directories/CashRegisters'
import contentDialog from '~/mixins/dialogs/contentDialog'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

const extensionsMap = {
  html: 'html',
  pdf: 'pdf',
  text: 'txt'
}
export default {
  components: {
    EProgressCircular,
    TDialog,
    TOrmButtons,
    TOrmFields,
    ESvgIcon,
    ELink
  },
  mixins: [converters, clipboard, contentDialog, processingApiRequest],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    disableQr: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '470px'
    },
    downloadNameField: {
      type: String,
      required: true
    },
    downloadPrefix: {
      type: String,
      required: true
    },
    download: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    },
    receiptHtmlAndId: {
      type: [Object],
      required: true
    }
  },
  data: () => ({
    downloadLoading: false,
    printLoading: false,
    viewLoading: false,
    downloaded: null,
    notFound: false,
    imgUrl: '',
    receiptView: null
  }),
  computed: {
    receiptHtml () {
      return this._.get(this.receiptHtmlAndId, 'receiptHtml', null)
    },
    resultReceiptId () {
      return this._.get(this.resultReceipt, 'id', null)
    },
    resultReceipt () {
      return this._.get(this.receiptHtmlAndId, 'resultReceipt', null)
    },
    downloadedFileName () {
      const name = this.resultReceipt[this.downloadNameField]
        ? this.resultReceipt[this.downloadNameField] + `_${this.downloadPrefix}`
        : Date.now() + `_${this.downloadPrefix}`
      const ext = extensionsMap[this.download]
      return name + '.' + ext
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    }
  },
  methods: {
    async downloadReceipt () {
      try {
        this.downloadLoading = true
        const data = this._.get(await this.model.api()['processingRead' + this._.upperFirst(this.download)](this.resultReceiptId), 'response.data', '')
        Download(data, this.downloadedFileName)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.downloadLoading = false
      }
    },
    async print () {
      if (this.type) {
        await this.printImg()
      } else {
        await this.printText()
      }
    },
    async printText () {
      try {
        this.printLoading = true
        const res = this._.get(await this.model.api().processingReadText(this.resultReceiptId), 'response.data', '')
        const cw = this.$refs.receiptDoc.contentWindow

        cw.document.open()
        cw.document.write('<pre>')
        cw.document.write(res)
        cw.document.write('</pre>')

        if (!this.disableQr) {
          const qrCode = this._.get(await this.model.api().processingReadQrCode(this.resultReceipId), 'response.data', '')
          const qrCodeUrl = await this.blobToBase64(qrCode)
          const wrapperWidth = this._.get(this.$Organization, 'receiptSetting.width', 32) * 8
          cw.document.write('<div style="width:' + wrapperWidth + 'px; text-align: center; margin: 0; padding: 0;">')
          cw.document.write(`<img src="${qrCodeUrl}" style="width: 100%; max-width: 250px; display: block; margin: 0 auto;">`)
          cw.document.write('</div>')
        }

        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No text representation for check printing, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.printLoading = false
      }
    },
    async printImg () {
      try {
        this.printLoading = true
        const res = this._.get(await this.model.api().processingReadPng(this.resultReceiptId), 'response.data', '')
        const receiptUrl = await this.blobToBase64(res)
        const cw = this.$refs.receiptDoc.contentWindow
        const paperWidth = this._.get(this.$Organization, 'receiptSetting.paperWidth') || 58
        const imgWidth = (paperWidth * 3.78).toFixed(2) + 'px'
        cw.document.open()
        cw.document.write(`<img src="${receiptUrl}" alt="Receipt" style="width: ${imgWidth};">`)
        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No check view was found, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.printLoading = false
      }
    },
    clear () {
      this.view = ''
      this.notFound = false
      this.viewLoading = false
      this.downloadLoading = false
    },
    copyToClipboard () {
      try {
        this.copy('https://check.checkbox.ua/' + this.resultReceiptId)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    sendToEmail () {
      this.contentDialog.open({
        title: 'Sent to email',
        width: '500px',
        component: 'm-form-block',
        componentProps: {
          buttonText: 'Send ',
          fields: [
            {
              model: 'email',
              component: 'v-text-field',
              provider: {
                vid: 'email',
                name: 'E-mail',
                rules: 'required|email'
              },
              attrs: {
                outlined: true,
                type: 'email',
                placeholder: 'E-mail'
              },
              cast: val => val && val.toLowerCase()
            }
          ],
          onSubmit: async (data) => {
            try {
              await Promise.all([
                Employees.api().filter({ mode: Employees.itemModes.checkbox.mode, limit: 10 }).all(),
                CashRegisters.api().filter({ mode: CashRegisters.itemModes.checkbox.mode, limit: 1 }).all()
              ])
              const employee = Employees.query().where((item) => {
                if (item.certificateEnd) {
                  return item.mode === Employees.itemModes.checkbox.mode && new Date().getTime() < new Date(item.certificateEnd).getTime()
                }
              }).first()
              const accessToken = this._.get(await Employees.api().processingSignin(employee), 'response.data.access_token')
              await this.processingApiRequest({
                request: token => Receipts.api().sendToEmail([data.email], this.resultReceiptId, token),
                token: accessToken,
                employee
              })
              AppNotifications.success('The check was successfully sent')
              await this.contentDialog.close()
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.e-link{
  margin-bottom: 12px;
  font-size: 12px !important
}
.col-links{
  display: flex;
  flex-direction: column
}
</style>
