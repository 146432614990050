import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import DOMPurify from 'dompurify'
import Base64js from 'base64-js'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import { contexts, formats } from '~/const/global'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import Organization from '~/models/directories/Organization'

export class Employees extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'employee'
  static orderEntity = EmployeeOrder
  static paginated = true
  static ormLoadWithRelations = true
  // TODO check defaultSortOrder field
  static defaultSortOrder = false
  static defaultSortParam= 'mode'
  static dblClickAction = 'read'

  static itemTypes = {
    cashier: {
      text: 'Касир',
      value: 'cashier'
    },
    head_cashier: {
      text: 'Старший касир',
      value: 'head_cashier'
    }
  }

  static signTypes = {
    agent: {
      text: 'AGENT',
      value: 'AGENT'
    },
    depositsign: {
      text: 'DEPOSITSIGN',
      value: 'DEPOSITSIGN'
    },
    cloudsignature: {
      text: 'Ключ запущено на захищеному хмарному сервісі',
      value: 'CLOUD_SIGNATURE'
    },
    cloudsignature2: {
      text: 'Ключ запущено на захищеному хмарному сервісі',
      value: 'CLOUD_SIGNATURE_2'
    },
    cloudsignature3: {
      text: 'Ключ запущено на захищеному хмарному сервісі',
      value: 'CLOUD_SIGNATURE_3'
    },
    test: {
      text: 'Тестовий касир',
      value: 'TEST'
    }
  }

  static itemModes = {
    checkbox: {
      text: 'Active ',
      value: 'checkbox',
      mode: 100
    },
    deactivated: {
      text: 'Deactivated in SFS',
      value: 'deactivated',
      mode: 200
    },
    alert: {
      text: 'Need interaction',
      value: 'alert',
      mode: 300
    },
    onRemovalInSfs: {
      text: 'On removal in SFS',
      value: 'onRemovalInSfs'
    },
    hasRemovalOrderError: {
      text: 'Active ',
      value: 'hasRemovalOrderError'
    },
    onModifyInSfs: {
      text: 'On modify in SFS',
      value: 'onModifyInSfs'
    },
    hasModifyOrderError: {
      text: 'Active',
      value: 'hasModifyOrderError'
    },
    test: {
      text: 'Тестовий касир',
      value: 'TEST'
    }
  }

  static checkTaxOrdersStatus (item) {
    const sortedTaxOrders = this._.sortBy(this._.get(item, 'employeeTaxOrders', []), order => new Date(order.dateSend).getTime())
    const lastTaxOrder = this._.last(sortedTaxOrders)
    const onRemovalInSfs = this._.get(lastTaxOrder, 'type') === 300 && this._.get(lastTaxOrder, 'status') === 200
    const hasRemovalOrderError = this._.get(lastTaxOrder, 'type') === 300 && this._.get(lastTaxOrder, 'status') === 400 && this._.get(item, 'mode') === 100
    const onModifyInSfs = this._.get(lastTaxOrder, 'type') === 200 && this._.get(lastTaxOrder, 'status') === 200
    const hasModifyOrderError = this._.get(lastTaxOrder, 'type') === 200 && this._.get(lastTaxOrder, 'status') === 400 && this._.get(item, 'mode') === 100

    return {
      lastTaxOrder,
      onRemovalInSfs,
      hasRemovalOrderError,
      onModifyInSfs,
      hasModifyOrderError,
      actionVisible: !onRemovalInSfs && !onModifyInSfs
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      // modes: tax, checkbox, order
      mode: this.attr(null),
      status: this.attr(null),
      type: this.attr(null),

      // Table fields.
      name: this.attr(null),
      login: this.attr(null),
      password: this.attr(null),
      hasPassword: this.attr(null),
      pinCode: this.attr(null),
      // can be: AGENT, UKEY, DEPOSITSIGN
      signatureType: this.attr(null),
      // extra fields
      DRFOCode: this.attr(null),
      publicKeyId: this.attr(null),

      // deposit-sign fields
      depositsignKeyPassword: this.attr(null),
      depositsignKeyToken: this.attr(null),

      taxMessage: this.attr(null),
      reqEncryptedId: this.attr(null),
      taxDocId: this.attr(null),
      dateCreated: this.attr(null),
      hasErrorFile: this.attr(null),
      employeeTaxOrders: this.attr(null),
      edsKey: this.attr(null),
      certificateEnd: this.attr(null),
      isTest: this.attr(false)
    }
  }

  static ormTrans = {
    single: 'Employee ',
    multy: 'Employees',
    notificationUpdate: 'Employee data',
    notification: 'Employee'
  }

  static ormFieldsMap = {
    //
  }

  get modeString () {
    const { onRemovalInSfs, hasRemovalOrderError, onModifyInSfs, hasModifyOrderError, test } = Employees.itemModes
    const orderStatus = Employees.checkTaxOrdersStatus(this)
    if (this._.get(orderStatus, onRemovalInSfs.value)) {
      return onRemovalInSfs.value
    } else if (this._.get(orderStatus, hasRemovalOrderError.value)) {
      return hasRemovalOrderError.value
    } else if (this._.get(orderStatus, onModifyInSfs.value)) {
      return onModifyInSfs.value
    } else if (this._.get(orderStatus, hasModifyOrderError.value)) {
      return hasModifyOrderError.value
    } else if (this.isTest) {
      return test.value
    }
    return this.mode
  }

  get isCloudSign2 () {
    return Employees.signTypes.cloudsignature2.value === this.signatureType
  }

  get isCloudSign3 () {
    return Employees.signTypes.cloudsignature3.value === this.signatureType
  }

  get isCloudSign () {
    return this.isCloudSign2 || this.isCloudSign3
  }

  get isDepositSign () {
    return Employees.signTypes.depositsign.value === this.signatureType
  }

  get certificateEndAsString () {
    return this.getDateTime(this.certificateEnd)
  }

  static ormHeaders = [
    { text: 'Status', value: 'modeString', align: 'left', width: '227', sortable: true, filterable: false },
    { text: 'Employee login', value: 'login', filterable: true },
    { text: 'Full name', value: 'name', filterable: true, sortable: true },
    { text: 'EDS key', value: 'edsKey', width: '200', filterable: false, sortable: false },
    { text: 'Key expire date', sortQuery: 'certificateEnd', value: 'certificateEndAsString', filterable: true, sortable: true },
    { text: 'Pin code ', value: 'pinCode', filterable: false, sortable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false }
  ]

  static ormRowsConfig = {
    disabled: false
  }

  static ormColsComponents = {
    pinCode: {
      component: 'e-copy-text'
    },
    login: {
      component: 'e-orm-table-column-with-action',
      attrs: {
        actionCondition: () => item => item.isTest,
        // eslint-disable-next-line no-console
        action: () => (item, e) => console.log(e),
        tooltipText: 'Пароль для тестового касира аналогічний його логіну',
        icon: 'question'
      }
    },
    modeString: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            checkbox,
            deactivated,
            alert,
            onRemovalInSfs,
            hasRemovalOrderError,
            onModifyInSfs,
            hasModifyOrderError,
            test
          } = this.itemModes

          return {
            certificateEnd: item.certificateEnd,
            id: item.id,
            model: EmployeeOrder,
            [checkbox.mode]: {
              text: checkbox.text,
              type: checkbox.value
            },
            [deactivated.mode]: {
              text: deactivated.text,
              type: deactivated.value,
              tooltip: 'Employee was deactivated in sfs'
            },
            [alert.mode]: {
              text: alert.text,
              type: alert.value,
              tooltip: 'To use this cashier you need to manually set a login and password'
            },
            onRemovalInSfs: {
              text: onRemovalInSfs.text,
              type: onRemovalInSfs.value,
              tooltip: 'The employee is on removal in the SFS'
            },
            hasRemovalOrderError: {
              text: hasRemovalOrderError.text,
              type: hasRemovalOrderError.value,
              error: {
                id: this._.get(Employees.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
                taxErrorMessage: this._.get(Employees.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
                hasErrorFile: this._.get(Employees.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
              }
            },
            onModifyInSfs: {
              text: onModifyInSfs.text,
              type: onModifyInSfs.value,
              tooltip: 'The employee is on modify in the SFS'
            },
            hasModifyOrderError: {
              text: hasModifyOrderError.text,
              type: hasModifyOrderError.value,
              error: {
                id: this._.get(Employees.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
                taxErrorMessage: this._.get(Employees.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
                hasErrorFile: this._.get(Employees.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
              }
            },
            TEST: {
              text: test.text,
              type: test.value,
              tooltip: 'Використовуйте цього касира для тестування видачі чеків',
              appearance: {
                color: '#ff6a00',
                textColor: '#fff'
              }
            }
          }
        }
      }
    },
    edsKey: {
      component: 'block-employee-eds-key-status'
    },
    certificateEndAsString: {
      component: 'block-employee-key-end'
    }
  }

  static ormFilters = [
    {
      model: 'mode',
      component: 'v-select',
      attrs: {
        items: [
          { text: 'Активний', value: Employees.itemModes.checkbox.mode },
          { text: 'Деактивовано в ДПС', value: Employees.itemModes.deactivated.mode },
          { text: 'Потрібно встановити дані', value: Employees.itemModes.alert.mode }
        ],
        label: 'Status',
        outlined: true,
        clearable: true,
        hideDetails: true
      },
      fieldVal: () => ''
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['status', 'code'],
      'sort-desc': [true, true]
    })
  }

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6
              },
              fields: [
                'mode'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = []

  static ormActions = [
    {
      name: 'permissions',
      text: 'Permissions',
      visible: item => item.mode === Employees.itemModes.checkbox.mode,
      icon: {
        type: 'e-svg-icon',
        text: 'settings-2'
      },
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          width: '500px',
          component: 'form-employee-permissions',
          componentProps: { employee: item }
        })
      }
    },
    {
      name: 'uploadEdsKey',
      text: 'Launch Checkbox Signature on a secure cloud service',
      visible: (item) => {
        return item.mode === Employees.itemModes.checkbox.mode && !item.isCloudSign && !item.isDepositSign && !item.isTest
      },
      icon: {
        type: 'e-svg-icon',
        text: 'upload'
      },
      call: async (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        const keyData = await contentDialog.open(
          {
            title: 'Launch Checkbox Signature on a secure cloud service',
            component: 'form-employee-upload-eds-key',
            width: '650px',
            componentProps: {
              inModal: true
            }
          }
        )
        if (!keyData) {
          return null
        }
        const issuerCN = this._.get(keyData, 'keyData.certificate_info.certificate.pszIssuerCN', null)
        const certificateStart = this._.get(keyData, 'keyData.certificate_start', null)
        const certificateEnd = this._.get(keyData, 'keyData.certificate_end', null)
        const certificate = this._.get(keyData, 'keyData.certificate_info', null)
        const keyIdFromKey = this._.get(keyData, 'keyData.key_id', '').replace(/ /g, '').toLowerCase()
        if (keyIdFromKey !== item.publicKeyId) {
          AppNotifications.error('Selected key does not match of cashier key', true)
          return null
        }
        const data = {
          ca: this._.get(keyData, 'keyData.ca', null),
          keyPassword: keyData.form.keyPassword,
          keyFile: Base64js.fromByteArray(new Uint8Array(keyData.form.keyFile)),
          caIssuerCN: issuerCN,
          certificateStart,
          certificateEnd,
          certificateInfo: certificate
        }
        try {
          await Employees.api().uploadEdsKey(item.id, data)
          AppNotifications.success('Key successfully upload to checkbox server')
        } catch (e) {
          AppNotifications.error(e)
        }
      }
    },
    {
      name: 'removeEdsKey',
      text: 'Remove EDS Key',
      visible: (item) => {
        return item.mode === Employees.itemModes.checkbox.mode && item.isCloudSign && !item.isTest
      },
      icon: {
        type: 'e-svg-icon',
        text: 'cloud-computing'
      },
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirmationDialog.open({
          title: 'Remove EDS Key',
          text: 'Are you sure to delete eds key from checkbox srver'
        })
        if (confirmed) {
          try {
            await Employees.api().removeEdsKey(item.id)
            AppNotifications.success('Key successfully removed from checkbox server')
          } catch (e) {
            AppNotifications.error(e)
          }
        }
      }
    },
    {
      name: 'resetPinCode',
      visible: item => item.mode === Employees.itemModes.checkbox.mode,
      text: 'reset pin code',
      icon: {
        type: 'e-svg-icon',
        text: 'keyword'
      },
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'confirmation').first()
        contentDialog.open({
          title: 'm-orm-reset-pincode-dialog.title',
          width: '500px',
          buttonText: {
            approve: 'Reset',
            dismiss: 'Cancel'
          },
          onConfirm: async (ctx) => {
            const res = await Employees.api().resetPincode(item)
            ctx.$notification.success(ctx.$t('m-orm-reset-pincode-dialog.notification', { pinCode: DOMPurify.sanitize(ctx._.get(res, 'response.data.pinCode', '')) }))
          }
        })
      }
    },
    {
      name: 'setData',
      text: 'Set data',
      visible: item => item.mode === Employees.itemModes.alert.mode,
      icon: {
        type: 'e-svg-icon',
        text: 'wrench'
      },
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Cashier data installation',
          width: '600px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Set',
            successMessage: 'Data successfully installed',
            fields: [
              {
                model: 'login',
                component: 'v-text-field',
                provider: {
                  vid: 'login',
                  name: 'Come up with a cashier login',
                  rules: 'latin_dashes|required|max:20'
                },
                attrs: {
                  disabled: ctx => ctx.context === contexts.read,
                  label: 'Come up with a cashier login',
                  type: 'text',
                  outlined: true
                },
                hint: 'It will be used for the cashier to enter the mobile application or on my.checkbox.in.ua'
              },
              {
                model: 'password',
                component: 'e-input-password',
                provider: {
                  vid: 'password',
                  name: 'Come up with a cashier password',
                  rules: 'required|min:6|max:50'
                },
                attrs: {
                  label: 'Come up with a cashier password',
                  type: 'password',
                  outlined: true,
                  autocomplete: 'new-password',
                  showPasswordStrength: true,
                  hasHint: true
                },
                hint: 'It will be used for the cashier to enter the mobile application or on my.checkbox.in.ua'
              }
            ],
            onSubmit: async (data) => {
              await Employees.api().updateLocal(item, data)
              await contentDialog.close()
            }
          }
        })
      }
    },
    {
      name: 'edit',
      visible: (item) => {
        return (item.mode === Employees.itemModes.checkbox.mode || item.mode === Employees.itemModes.deactivated.mode) && get(Employees.checkTaxOrdersStatus(item), 'actionVisible') && !item.isTest
      },
      call: (employee) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Employee modifying',
          width: '860px',
          component: 'form-employee-modify',
          componentProps: {
            item: employee,
            onSubmit: async () => await contentDialog.close()
          }
        })
      }
    },
    {
      name: 'keyReplacement',
      text: 'Key replacement',
      icon: {
        type: 'e-svg-icon',
        text: 'key'
      },
      visible: (item) => {
        return (item.mode === Employees.itemModes.checkbox.mode) && get(Employees.checkTaxOrdersStatus(item), 'actionVisible') && !item.isTest
      },
      call: async (employee) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          width: '900px',
          component: 'block-change-employee-eds-key',
          contentNoGutters: true,
          componentProps: {
            item: employee
          }
        })
      }
    },
    {
      name: 'deactivate',
      text: 'Deactivate',
      icon: {
        type: 'e-svg-icon',
        text: 'trash',
        attrs: {
          size: 'mdl'
        }
      },
      visible: (item) => {
        return item.mode === Employees.itemModes.checkbox.mode && get(Employees.checkTaxOrdersStatus(item), 'actionVisible') && !item.isTest
      },
      call: async (employee, data, ctx) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: 'Підтвердити деактивацію',
          text: `Касир ${employee.name || ''} буде деактивований в ДПС`,
          onConfirm: async (ctx) => {
            const DRFOCode = ctx._.get(employee, 'T1RXXXXG2S') || ctx._.get(ctx.$Organization, 'edrpou', null) || ctx._.get(ctx.$Organization, 'taxNumber', null)
            await ctx.sendEntityTaxOrder({
              type: 'modify',
              entity: employee,
              orderModel: EmployeeOrder,
              model: Employees,
              data: {
                T1RXXXXG4S: EmployeeOrder.itemTypes.deactivate.value,
                T1RXXXXG1S: ctx._.get(employee, 'name', null),
                T1RXXXXG2S: DRFOCode,
                employee: `/employees/${ctx._.get(employee, 'id')}`
              }
            })
          }
        })
      }
    },
    {
      name: 'deleteTestData',
      text: 'Delete',
      icon: {
        type: 'e-svg-icon',
        text: 'trash',
        attrs: {
          size: 'mdl'
        }
      },
      visible: item => item.isTest,
      call: async (employee) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: 'Підтвердити видалення',
          text: 'Всі тестові дані, включаючи чеки та зміни, будуть видалені.',
          onConfirm: async () => {
            await Organization.api().deleteTestData()
            Employees.delete(employee.id)
          }
        })
      }
    }
  ]

  static ormDialogs = {
    default: 'employee-form-dialog',
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create'
      }
    },
    read: {
      title: item => ({ type: 'employee', name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'dateCreated',
            label: 'Date of created',
            value: val => this.$moment(val).format(formats.dateTime)
          },
          {
            model: 'reqErrorCode',
            label: 'Tax error code',
            hideEmpty: true
          },
          {
            model: 'reqErrorText',
            label: 'Tax error message',
            hideEmpty: true
          },
          {
            model: 'login',
            label: 'Login '
          },
          {
            model: 'pinCode',
            label: 'Pin code '
          },
          {
            model: 'name',
            label: 'Full name'
          },
          {
            model: 'type',
            label: 'Cashier type',
            value: val => this._.get(this.itemTypes, `${val}.text`, null)
          },
          {
            model: 'DRFOCode',
            label: 'DRFOCode'
          },
          {
            model: 'publicKeyId',
            label: 'Public key ID'
          },
          {
            model: 'signatureType',
            label: 'Signature type',
            value: val => this._.get(Object.values(this.signTypes).find(item => item.value === val), 'text', null)
          },
          {
            model: 'depositsignLogin',
            label: 'Depositsign login',
            hideEmpty: true
          },
          {
            model: 'depositsignPassword',
            label: 'Depositsign password',
            hideEmpty: true
          },
          {
            model: 'depositsignClientId',
            label: 'Depositsign client id',
            hideEmpty: true
          },
          {
            model: 'depositsignKeyPassword',
            label: 'Password for depositsign key',
            hideEmpty: true
          },
          {
            model: 'login',
            label: 'Password',
            hideEmpty: true,
            visible: item => item.isTest
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingSignin = function (employee, params = {}) {
        return this.post(Model.$routes.employee.processingSignin(employee.id), {}, {
          params,
          save: false
        })
      }
      configActions.processingLoginSignature = function (sign) {
        return this.post(Model.$routes.employee.processingLoginSignature(), { sign }, { save: false })
      }
      configActions.processingCheckAgent = function (token) {
        return this.get(Model.$routes.employee.processingCheckAgent(), {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.updateLocal = function (employee, payload) {
        return this.put(Model.$routes.employee.updateLocal(employee.id), payload)
      }
      configActions.resetPincode = function (employee) {
        return this.put(Model.$routes.employee.resetPincode(employee.id), {}, { save: false })
      }
      configActions.processingGetTransactions = function (token) {
        return this.get(Model.$routes.employee.processingGetTransactions(), {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingGetPendingTransactions = function (token) {
        return this.get(Model.$routes.employee.processingGetPendingTransactions(), {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingGetTransaction = function (token, id) {
        return this.get(Model.$routes.employee.processingGetTransaction(id), {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingUpdateTransaction = function (token, id, payload) {
        return this.patch(Model.$routes.employee.processingGetTransaction(id), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingUpdatePermissions = function (id, payload) {
        return this.put(Model.$routes.employee.processingPermissions(id), payload, {
          save: false
        })
      }
      configActions.processingGetPermissions = function (id) {
        return this.get(Model.$routes.employee.processingPermissions(id), {
          save: false
        })
      }
      configActions.checkKey = function (payload) {
        return this.post(Model.$routes.edsKey.check(), payload, {
          save: false
        })
      }
      configActions.uploadEdsKey = function (id, data) {
        return this.put(Model.$routes.employee.setEdsKey(id), data)
      }
      configActions.removeEdsKey = function (id) {
        return this.put(Model.$routes.employee.removeEdsKey(id), {})
      }
      return configActions
    }
  }
}

export default Employees
