<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('eTTN orders') }}
    div(
      v-if="showEttnOrdersHint"
      class="dialog__hint"
    )
      e-button-close(
        @click="closeBtnClick"
        class="dialog__hint-close-btn"
        x-small
      )
      div(
        v-if="!xsDevice"
        class="dialog__hint-left"
      ) 💡
      div
        p
          span(
            v-if="xsDevice"
            class="mr-1"
          ) 💡
          b(class="mr-1") Зверніть увагу –
          | створення чеку експрес накладної не працює із чеками передплати!
        p
          span(class="mr-1") Сума чека зі знижками та надбавками має повністю співпадати із сумою, вказаною в створеній накладній Нової Пошти, а також повинна повністю співпадати із значенням суми Контролю оплати.
          e-link(
            href="https://wiki.checkbox.ua/uk/portal/cash-registers/nova-poshta"
          ) Детальна інструкція
    m-orm-table(
      ref="table"
      :model="model"
      :default-filter="defaultFilter"
      @filtersChange="filtersChange"
      filters-full-width
      :custom-request="tableRequest"
    )
      template(v-slot:footer)
        div(v-if="!showTotalSum && orders.length" class="link")
          e-link(@click="showTotal" underline) {{$t('Show total sum')}}
        span(class="total-sum" v-if="showTotalSum") {{ _.upperFirst($t('total')) }}: &nbsp;{{ totalSum | currency }}
</template>

<script>
import { Model } from '@vuex-orm/core'
import EttnOrders from '~/modules/ettn/models/EttnOrders'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import authData from '~/modules/receipt/mixins/getters/authData'
import ELink from '~/components/elements/links/e-link'
import responsive from '~/mixins/pages/responsive'
import EButtonClose from '~/components/elements/buttons/e-button-close'
import APIPlatformConnector from '~/plugins/vuex-orm/plugins/_drivers/APIPlatformConnector'

export default {
  name: 'MBlockEttnOrders',
  components: {
    ELink,
    MOrmTable,
    EButtonClose
  },
  filters: {
    currency (val) {
      return (val / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
    }
  },
  mixins: [authData, responsive],
  data: () => ({
    totalSum: null,
    showTotalSum: false,
    showEttnOrdersHint: true
  }),
  computed: {
    model () {
      return EttnOrders
    },
    orders () {
      return this.model.query().all()
    },
    defaultFilter () {
      return {
        'cashRegister.id': this.cashRegister?.id
      }
    }
  },
  created () {
    this.showEttnOrdersHint = !this._.get(this.$localStorageClientData(), 'hideEttnOrdersHint', false)
  },
  methods: {
    filtersChange () {
      this.showTotalSum = false
    },
    closeBtnClick () {
      this.$setLocalStorageClientData({ hideEttnOrdersHint: true })
      this.showEttnOrdersHint = false
    },
    async showTotal () {
      await this.getTotalSum()
      this.showTotalSum = true
    },
    async tableRequest ({ model, requestParams }) {
      const connector = this.getConnectorForApiCall(model, requestParams)

      return await this.model.api().get(Model.$routes[this.model.entity].list(), {
        ...connector.config
      })
    },
    async getTotalSum () {
      const requestParams = this._.get(this.$refs, 'table.requestParams', null)
      const connector = this.getConnectorForApiCall(this.model, requestParams)

      const request = await this.model.api().all({}, {
        ...connector.config,
        route: this.model.$routes[this.model.entity].totalSum(),
        save: false
      })

      this.totalSum = this._.get(request, 'response.data', null)
    },
    getConnectorForApiCall (model, requestParams) {
      const connector = new APIPlatformConnector(model, requestParams)
      const dateBefore = this._.get(connector, 'config.params[\'dateCreated[before]\']')
      const dateAfter = this._.get(connector, 'config.params[\'dateCreated[after]\']')

      if (dateBefore && dateAfter) {
        const date = this._.get(requestParams, 'filterBy.dateCreated')
        const sortedValue = date.sort((a, b) => new Date(a) - new Date(b))
        let startDate = this._.get(sortedValue, '[0]')
        let endDate = this._.get(sortedValue, '[1]')

        if (this._.get(sortedValue, 'length') > 1) {
          endDate += ' 23:59:59'
        } else {
          endDate = startDate + ' 23:59:59'
          startDate += ' 00:00:00'
        }

        connector.config.params['dateCreated[after]'] = startDate
        connector.config.params['dateCreated[before]'] = endDate
      }

      return connector
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &__hint {
    margin: 4px 0 20px;
    padding: 12px 32px 12px 16px;
    background: #FEFAEB;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    position: relative;

    @media (min-width: map-get($breakpoints, 'sm')) {
      margin: 12px 0 32px;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 300;
    }

    b {
      font-weight: 700;
    }
  }

  &__hint-close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__hint-left {
    font-size: 24px;
  }
}

.total-sum {
  display: block;
  text-align: right;
  padding: 10px 88px 0 10px;
  font-size: 0.75rem;
  font-weight: bold;
}

.link {
  text-align: right;
  margin-top: 16px;
  font-size: 0.75rem;
}
</style>
