<template lang="pug">
  tr(:key="scopedItem.item.id || scopedItem.item.name" :active="scopedItem.isSelected" @dblclick="dblclick(scopedItem.item)" :class="trClass" ref="row")
    td(
      v-if="_.get(config, 'showSelect')"
      :class="tdClass"
    )
      div(
        v-if="_.get(config, 'checkbox')"
        :class="tdContClass"
      )
        v-checkbox(
          :value="isSelected"
          @change="select"
          color="#04AED4"
        )

    td(:class="[tdClass, alignClass(colIndex)]" v-for="(field, colIndex) in model.ormCols(config.short, scopedItem.headers)")
      template(v-if="!isResponsive")
        t-orm-wrapper(
          @click.native="itemActionFired(colIndex, scopedItem.item)"
          :item="scopedItem.item"
          :isSelected="scopedItem.isSelected"
          :field="field"
          :class="tdContClass"
          :requestParams="requestParams"
        )
      div(v-if="isResponsive" class="v-data-table__mobile-row__header") {{ scopedItem.headers[colIndex].text }}
      div(v-if="isResponsive" class="v-data-table__mobile-row__cell")
        t-orm-wrapper(
          :item="scopedItem.item"
          :field="field"
          :class="tdContClass"
          :requestParams="requestParams"
        )

    td(
      :class="[tdClass, 'actions', !intersectedActions.length && 'px-0']"
      :style="{ height: actionsHeight() }"
      v-if="showActions"
    )
      template(v-if="intersectedActions.length")
        template(v-if="isEnabledActions")
          template(v-if="!isResponsive")
            t-orm-menu-actions(:items="intersectedActions" :target="scopedItem.item")
          div(v-if="isResponsive" class="v-data-table__mobile-row__header")
            | {{ scopedItem.headers[scopedItem.headers.length - 1].text }}
          div(v-if="isResponsive" class="v-data-table__mobile-row__cell")
            t-orm-menu-actions(:items="intersectedActions" :target="scopedItem.item")
        template(v-else)
          v-divider(class="mx-7")
</template>

<script>
import {
  VSimpleCheckbox,
  Ripple
} from 'vuetify/lib'
import TOrmActions from '~/components/templates/orm/t-orm-actions'
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'
import TOrmWrapper from '~/components/templates/orm/t-orm-wrapper'

export default {
  directives: {
    Ripple
  },
  components: {
    VSimpleCheckbox,
    TOrmActions,
    TOrmMenuActions,
    TOrmWrapper
  },
  props: {
    scopedItem: {
      type: Object,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    requestParams: {
      type: Object,
      default: () => {
      }
    },
    actions: {
      type: Object,
      default: () => {
      }
    },
    selected: {
      type: Array,
      default: () => []
    },
    context: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'orm-select-dialog'].includes(value)
      }
    },
    dialogModel: {
      type: Function,
      default: null
    }
  },
  computed: {
    showActions () {
      return this._.filter(this._.get(this.scopedItem, 'headers', []), item => item.value === 'actions').length
    },
    isSelected () {
      const key = this._.get(this.config, 'checkbox.selectedKey') || 'id'
      const filtered = this._.filter(this.selected, selected => this._.get(selected, key) === this._.get(this.scopedItem.item, key))
      return filtered && Boolean(filtered.length)
    },
    trClass () {
      return [
        't-orm-item'
      ]
    },

    tdClass () {
      return [
        this.isResponsive ? 'v-data-table__mobile-row' : ''
      ]
    },

    tdContClass () {
      return [
        this.disabledState ? 'disabled' : ''
      ]
    },

    disabledState () {
      return this._.isFunction(this.config.disabled) ? this.config.disabled(this.scopedItem) : false
    },

    isEnabledActions () {
      if (Number.isFinite(+this.scopedItem.item.primaryVal)) {
        return this.scopedItem.item.primaryVal >= 0
      }

      return true
    },

    intersectedActions () {
      return this._.get(this.config, 'intersectedActions', [])
    },

    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    dblclick (item) {
      if (!this.isEnabledActions) {
        return
      }

      if (this._.isFunction(this.config.dblclick)) {
        this.$emit('update:dialogModel', this.dialogModel)
        this.config.dblclick(item)
      }
      // if (this.$ability.can(ormActions.update, this.model[ormConfig.modelKey]) || this.context === 'orm-select-dialog') {
      //   this.config.dblclick(item)
      // }
    },
    itemActionFired (index, item) {
      if (this._.isFunction(this._.get(this.actions, 'click', null))) {
        this.actions.click(item, this.requestParams)
        return
      }
      const header = this.scopedItem.headers[index]
      if (!this._.isEmpty(header.action)) {
        const actionItem = item[header.action.key]
        const action = this.intersectedActions.find(item => item.name === header.action.name)
        this.$emit('update:dialogModel', header.action.model)
        action.call(actionItem, true)
      }
    },
    actionsHeight () {
      return this._.get(this.$refs, 'row', {}).clientHeight + 'px'
    },
    alignClass (index) {
      const align = this._.get(this.scopedItem, `headers[${index}].align`, '')
      return align ? `text-${align}` : ''
    },
    select (val) {
      this.$emit('select', this._.get(this.scopedItem, 'item'), val)
    }
  }
}
</script>

<style lang="scss">
.t-orm-item {
  &:nth-child(2n) {
    background-color: #f7f7f7;
  }

  td {
    &:first-child {
      border-radius: 12px 0 0 12px;
    }

    &:last-child {
      border-radius: 0 12px 12px 0;
    }
  }

  .v-icon {
    font-size: 18px;
  }

  .v-radio {
    input {
      cursor: default;
    }
  }

  .v-input {
    &--checkbox {
      input {
        cursor: default;
      }

      .mdi-checkbox-marked {
        color: #04AED4;
        caret-color: #04AED4;
      }
    }

    &__slot {
      margin-bottom: 0;
    }

    &--selection-controls__input {
      margin-right: 0;
    }
  }
}

.disabled {
  opacity: 0.5;
}
</style>
