<template lang="pug">
  div()
    div(:id="pluginId" style="height:570px")
</template>

<script>
import { EndUser } from '~/lib/EUSignWidget/eusign'
/*
  Ідентифікатор iframe, який завантажує сторінку SignWidget
*/
const SIGN_WIDGET_ID = 'sign-widget'
/*
  URI з адресою за якою розташована сторінка SignWidget
  iit 'https://eu.iit.com.ua/sign-widget/v20200922/'
  id.gov.ua 'https://id.gov.ua/sign-widget/v20200922/'
*/
const SIGN_WIDGET_URI = 'https://eu.iit.com.ua/sign-widget/v20200922/'

export default {
  name: 'MIitSignPlugin',
  props: {
    signData: {
      type: [String, Object],
      default: null
    },
    pluginId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    euSign: null,
    keyLoaded: false,
    dataForSign: null,
    signedData: null,
    iframeLoaded: false
  }),
  watch: {
    async iframeLoaded (loaded) {
      if (loaded) {
        this.$emit('iframeLoading', false)

        try {
          const privateKeyData = await this.euSign.ReadPrivateKey()
          const signAlgos = this.signAlgos(privateKeyData)

          if (!signAlgos.includes(EndUser.PublicKeyType.DSTU4145)) {
            this.$handlers.error(this.$t('The read private key does not support EDS (DSTU 4145). Please use another EDS key'), this)
            this.$emit('keyLoaded', false)
            return
          }

          this.$emit('data:cert', privateKeyData)
          this.$emit('keyLoaded', privateKeyData)
          this.keyLoaded = true
          await this.sign()
        } catch (e) {
          this.keyLoaded = false
          this.$handlers.error(e, this)
        }
      }
    }
  },
  mounted () {
    const widgetId = SIGN_WIDGET_ID + this.pluginId
    this.euSign = new EndUser(
      this.pluginId,
      widgetId,
      SIGN_WIDGET_URI,
      EndUser.FormType.ReadPKey
    )

    if (this.euSign) {
      this.$emit('iframeLoading', true)
      this.euSign.iframe.onload = () => {
        this.iframeLoaded = true
      }
    }
  },
  methods: {
    async sign (data, emmit, signType) {
      try {
        const payload = data || (typeof this.signData === 'object' && this.signData !== null ? JSON.stringify(this.signData) : this.signData)
        if (!payload) { return }
        const signedData = await this.euSign.SignData(payload, false,
          true, EndUser.SignAlgo.DSTU4145WithGOST34311, null, signType || EndUser.SignType.CAdES_BES)
        if (emmit !== false) {
          this.$emit('data:signed', signedData)
        }
        return signedData
      } catch (e) {
        this.$handlers.error(e, this)
        return null
      }
    },
    async signHash (data) {
      try {
        return await this.euSign.SignHash(data)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async envelopData (certs = [], data) {
      try {
        const res = await this.euSign.EnvelopData(certs, data, false, true, true)
        this.$emit('data:enveloped', res)
        return res
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    toggleFrame () {
      this.keyLoaded = !this.keyLoaded
      this.signedData = null
      this.dataForSign = null
    },
    signAlgos (certs) {
      const signAlgos = []
      for (let i = 0; i < certs.length; i++) {
        const infoEx = certs[i].infoEx

        if ((infoEx.keyUsageType & EndUser.KeyUsage.DigitalSignature) !== EndUser.KeyUsage.DigitalSignature) {
          continue
        }

        switch (infoEx.publicKeyType) {
          case EndUser.PublicKeyType.DSTU4145:
            signAlgos.push(EndUser.SignAlgo.DSTU4145WithGOST34311)
            break
          case EndUser.PublicKeyType.RSA:
            signAlgos.push(EndUser.SignAlgo.RSAWithSHA)
            break
          case EndUser.PublicKeyType.ECDSA:
            signAlgos.push(EndUser.SignAlgo.ECDSAWithSHA)
            break
        }
      }

      return signAlgos
    }
  }
}
</script>

<style scoped>

</style>
