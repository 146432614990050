<template lang="pug">
  ValidationProvider(
    v-bind="_.get(config, 'validationProviderAttrs')"
    :vid="vid()"
    :name="label"
    mode="lazy"
    v-slot="{ errors }"
  )
    e-autocomplete(
      v-model="value"
      v-bind="_.get(config, 'attrs')"
      :item="autocompleteItem"
      :label="label"
      @change="handleChange"
      no-data-text="Елементи не знайдені"
      :error-messages="errors"
      :class="_.get(config, 'classes', [])"
      :menu-props="menuProps"
    )
</template>

<script>
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    EAutocomplete
  },
  mixins: [responsive],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    value: null
  }),
  computed: {
    label () {
      return this.$t(this._.get(this.config, 'attrs.label'))
    },
    menuProps () {
      return {
        offsetY: true,
        zIndex: 999,
        contentClass: 'w-100',
        maxWidth: this.xsDevice ? 300 : 600
      }
    },
    autocompleteItem () {
      return {
        attrs: {
          returnObject: true,
          requestOnMounted: false
        },
        searchModel: this._.get(this.config, 'searchModel'),
        asyncDefault: (items) => {
          if (items && items.length === 1) {
            const val = items[0]
            this.emitChange(val)
            return val
          }
        },
        query: (model) => {
          if (this._.isFunction(this._.get(this.config, 'query'))) {
            return this.config.query(model)
          }
        }
      }
    }
  },
  methods: {
    vid () {
      const vid = this._.get(this.config, 'validationProviderAttrs.vid')
      if (this._.isFunction(vid)) {
        return vid(this.item)
      }
      return vid
    },
    handleChange (val) {
      this.value = val
      this.emitChange(val)
    },
    async emitChange (val) {
      if (this._.isFunction(this._.get(this.config, 'onChange'))) {
        await this.config.onChange(this.item, val)
      }
    }
  }
}
</script>

<style lang="scss">
  //
</style>
