var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog add-notification"},[_c('div',{staticClass:"dialog__title pb-0 mb-8"},[_vm._v(_vm._s(_vm.title))]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addNotification($event)}}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Platform'),"vid":"channels","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"mt-0 mr-3",attrs:{"label":_vm.$t('Portal'),"value":"portal"},model:{value:(_vm.data.channels),callback:function ($$v) {_vm.$set(_vm.data, "channels", $$v)},expression:"data.channels"}}),_c('v-checkbox',{staticClass:"mt-0 mr-3",attrs:{"label":_vm.$t('Android'),"value":"android"},model:{value:(_vm.data.channels),callback:function ($$v) {_vm.$set(_vm.data, "channels", $$v)},expression:"data.channels"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":_vm.$t('IOS'),"value":"ios"},model:{value:(_vm.data.channels),callback:function ($$v) {_vm.$set(_vm.data, "channels", $$v)},expression:"data.channels"}})],1),_c('div',{staticClass:"add-notification__errors add-notification__errors--platform"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Title'),"vid":"title","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Title'),"error-messages":errors,"outlined":""},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Message'),"vid":"message","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('wysiwyg',{class:{ 'add-notification__editor--errors': errors.length },attrs:{"placeholder":_vm.$t('Message')},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}}),_c('div',{staticClass:"add-notification__errors"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('t-orm-fields',_vm._b({staticClass:"mt-3",attrs:{"items":_vm.fields}},'t-orm-fields',_vm.data,false,true)),(_vm.isFileUpload)?_c('ValidationProvider',{attrs:{"vid":"file","rules":"required|mimes:text/csv","name":_vm.$t('CSV file with organizations')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"name":"file","label":_vm.$t('CSV file with organizations'),"accept":"text/csv","appendIcon":"$file","error-messages":errors,"prependIcon":"","outlined":""},model:{value:(_vm.data.file),callback:function ($$v) {_vm.$set(_vm.data, "file", $$v)},expression:"data.file"}})]}}],null,false,2337768790)}):_vm._e()],1),_c('div',{staticClass:"dialog__actions"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"main-button",attrs:{"loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.btnText))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }