import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'
import Privatbank from '~/modules/acquiring/models/Privatbank'

export class Acquiring extends PersistAble(OrmModel) {
  static entity = 'acquiring'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Acquiring',
    multy: 'Acquiring'
  }

  static oschadProviderId = '8394d703-1d77-4e79-832f-f9ba030e2a7d'

  static fields () {
    return {
      id: this.attr(null),
      cashRegister: this.attr(null),
      merchant: this.attr(null),
      ptName: this.attr(null),
      oschadbankAcquiringOrder: this.attr(null),
      monoId: this.attr(null),
      isActive: this.attr(null)
    }
  }

  get cashRegisterString () {
    const cashRegister = this.cashRegister
    return cashRegister ? `№${cashRegister.number} (${cashRegister.fiscalNumber})` : '-'
  }

  get merchantClientLogin () {
    return this.merchant.clientLogin || '-'
  }

  get ptNameString () {
    return this.ptName || '-'
  }

  get statusString () {
    if (this.oschadbankAcquiringOrder?.status === Oschadbank.STATUSES.success && !this.isActive) {
      return 'active'
    } else if (this.oschadbankAcquiringOrder?.status === Oschadbank.STATUSES.success && this.isActive) {
      return 'connected'
    }
    return this.oschadbankAcquiringOrder?.status || (this.isActive ? 'active' : 'deactivated')
  }

  static ormHeaders = [
    { text: 'Status', value: 'statusString', sortable: false, filterable: false },
    { text: 'Bank', value: 'merchant.provider.name', sortable: false, filterable: false },
    { text: 'Login ', value: 'merchantClientLogin', sortable: false, filterable: false },
    { text: 'Terminal ID', value: 'ptNameString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegisterString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'cashRegisterString'

  static ormColsComponents = {
    statusString: {
      component: 'block-tap-to-phone-status'
    },
    cashRegisterString: {
      component: 'block-tap-to-phone-cash-register-status'
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'edit',
      visible: (item) => {
        return item.merchant?.clientLogin || item.ptName || item.cashRegister
      },
      call: async (item) => {
        const isPrivatBank = get(item, 'merchant.provider.name') === 'Приватбанк' // we don't have providerId
        if (isPrivatBank) {
          await Privatbank.api().read(item.id)
        }
        const privatBankItem = Privatbank.query().whereId(item.id).first()
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          component: 'block-tap-to-phone-form',
          width: '900px',
          contentNoGutters: true,
          componentProps: {
            item: isPrivatBank ? privatBankItem : item,
            isEditing: true,
            isPrivatBank
          }
        })
      }
    },
    {
      name: 'delete',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: 'Are you sure you want to delete TAP2Phone settings?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            const isPrivatBank = get(item, 'merchant.provider.name') === 'Приватбанк' // we don't have providerId
            try {
              if (isPrivatBank) {
                await Privatbank.api().del(item)
                Acquiring.delete(item.id) // update Acquiring store by deleting Privatbank entity
              } else {
                await Acquiring.api().del(item)
              }
              AppNotifications.success('TAP2Phone setup successfully deleted')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    },
    {
      name: 'unlink',
      text: 'Unlink terminal',
      icon: {
        text: 'banking',
        type: 'e-svg-icon'
      },
      visible: (item) => {
        const providerId = get(item, 'merchant.provider.providerId', null)
        return providerId === this.oschadProviderId
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: 'Are you sure you want to unlink terminal?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Acquiring.api().oschadUnlink({ pt_name: item.ptName })
              AppNotifications.success('Terminal successfully unlinked')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getMonobankTapToPhoneTerminals = function (id) {
        return this.post(Model.$routes.acquiring.monobankTapToPhoneTerminals(), {}, { save: false, params: { id } })
      }
      configActions.getMonobankTerminals = function (payload) {
        return this.post(Model.$routes.acquiring.monoTerminals(), payload, { save: false })
      }
      configActions.oschad = function (payload) {
        return this.post(Model.$routes.acquiring.oschad(), payload, { save: false })
      }
      configActions.oschadUnlink = function (payload) {
        return this.post(Model.$routes.acquiring.oschadUnlink(), payload, { save: false })
      }
      configActions.revokeToken = function () {
        return this.post(Model.$routes.acquiring.revokeMonobankToken(), {}, { save: false })
      }
      return configActions
    }
  }
}

export default Acquiring
