<template lang="pug">
  div(v-if="!loading")
    v-tabs(
      v-model="tab"
      class="pt-3 mb-3"
    )
      v-tab(:key="0") {{ $t('Prices') }}
      v-tab(:key="1") {{ $t('Discounts') }}
    v-tabs-items(v-model="tab" :show-arrows="false")
      v-tab-item(:key="0")
        v-row
          v-col(cols="12")
            v-btn(class="main-button" @click="openDialog(operationNames.ADD_PRICE)") {{ $t('Add new price') }}
        v-row(class="caption font-weight-black")
          v-col() {{$t('Cash register price')}}
          v-col() {{$t('New cash register price')}}
          v-col() {{$t('Receipts limit')}}
          v-col() {{$t('Start date')}}
          v-col() {{$t('Added by')}}
        v-row(:class="{'caption': true, 'green-text': item.active}" v-for="(item, index) in prices" :key="index")
          v-col(class="d-flex align-center") {{item.cashRegisterPrice/100}}
          v-col(class="d-flex align-center") {{item.priceForNewCashRegister/100}}
          v-col(class="d-flex align-center") {{item.overflowLimit}}
          v-col(class="d-flex align-center") {{item.startAt}}
          v-col(class="d-flex align-center") {{item.appliedBy}}
      v-tab-item(:key="1")
        v-row
          v-col(cols="12")
            v-btn(class="main-button" @click="openDialog(operationNames.ADD_DISCOUNT)") {{ $t('Add new discount') }}
        v-row(class="caption font-weight-black")
          v-col() {{$t('Discount')}}
          v-col() {{$t('Start date')}}
          v-col() {{$t('End date')}}
          v-col() {{$t('Added by')}}
          v-col(class="d-flex justify-end pr-6") {{$t('Actions')}}
        v-row(:class="{'caption': true, 'green-text': item.active}" v-for="(item, index) in discounts" :key="index")
          v-col(class="d-flex align-center") {{item.amount}} %
          v-col(class="d-flex align-center") {{item.startAt}}
          v-col(class="d-flex align-center") {{item.endAt || '-' }}
          v-col(class="d-flex align-center") {{item.appliedBy}}
          v-col(class="d-flex justify-end")
            t-orm-menu-actions(
              :items="actions"
              :target="item"
            )
    t-dialog(v-model="showDialog" max-width="500px")
      template(#title)
        div(class="text-center") {{ dialogTitle }}
      template(#content)
        component(
          :is="currentDialog.component"
          v-bind="currentDialog.componentProps"
          @onSuccess="onSuccess"
          @onError="onError"
        )
  div(v-else class="text-center py-10")
    e-progress-circular(size="xl", width=4)
</template>

<script>
import contentDialog from '~/mixins/dialogs/contentDialog'
import TDialog from '~/components/templates/t-dialog'
import MFormBillingSetNewPrice from '~/components/modules/forms/concrete/billing/m-form-billing-set-new-price'
import MFormBillingSetNewDiscount from '~/components/modules/forms/concrete/billing/m-form-billing-set-new-discount'
import MFormBillingEditDiscount from '~/components/modules/forms/concrete/billing/m-form-billing-edit-discount'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Billings from '~/models/billing/Billings'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'

export default {
  components: {
    ESvgIcon,
    MFormBillingSetNewDiscount,
    MFormBillingSetNewPrice,
    MFormBillingEditDiscount,
    TDialog,
    EProgressCircular,
    TOrmMenuActions
  },
  mixins: [contentDialog, responsive],
  props: {
    billingId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    tab: 0,
    showDialog: false,
    loading: false,
    prices: null,
    discounts: null,
    actionsMenu: {},
    discountId: null,
    currentDialog: {
      component: null,
      componentProps: null,
      title: null
    }
  }),
  computed: {
    dialogTitle () {
      return this.currentDialog.title
    },
    currentPrice () {
      return this.prices.find(item => item.active)
    },
    dialogs () {
      return {
        ADD_PRICE: {
          component: 'm-form-billing-set-new-price',
          componentProps: {
            billingId: this.billingId,
            currentPrice: this.currentPrice
          },
          title: 'Додайте нову ціну'
        },
        ADD_DISCOUNT: {
          component: 'm-form-billing-set-new-discount',
          componentProps: {
            billingId: this.billingId
          },
          title: 'Додайте нову знижку'
        },
        EDIT_DISCOUNT: {
          component: 'm-form-billing-edit-discount',
          componentProps: {
            discountId: this.discountId
          },
          title: 'Деактивуйте знижку'
        }
      }
    },
    operationNames () {
      return {
        ADD_PRICE: 'ADD_PRICE',
        ADD_DISCOUNT: 'ADD_DISCOUNT',
        EDIT_DISCOUNT: 'EDIT_DISCOUNT'
      }
    },
    actions () {
      return [
        {
          text: 'Deactivate discount',
          visible: true,
          call: item => this.openEditDialog(item.id),
          disabled: item => !item.active
        }
      ]
    }
  },
  created () {
    this.getPrices()
  },
  methods: {
    async getPrices () {
      try {
        this.loading = true
        const { prices, discounts } = this._.get(await Billings.api().getPrices(this.billingId), 'response.data', {})
        this.prices = prices
        this.discounts = discounts
        this.discounts.forEach((item, idx) => {
          this.$set(this.actionsMenu, idx, false)
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    openDialog (name) {
      this.currentDialog = this.dialogs[name]
      this.showDialog = true
    },
    openEditDialog (discountId) {
      this.discountId = discountId
      this.currentDialog = this.dialogs[this.operationNames.EDIT_DISCOUNT]
      this.showDialog = true
    },
    onSuccess () {
      this.showDialog = false
      this.getPrices()
    },
    onError () {
      this.currentDialog = {
        component: null,
        componentProps: null,
        title: null
      }
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="scss">
  .green-text{
    color: green;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
