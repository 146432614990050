import { render, staticRenderFns } from "./form-employee.vue?vue&type=template&id=47a9d22c&scoped=true&lang=pug&"
import script from "./form-employee.vue?vue&type=script&lang=js&"
export * from "./form-employee.vue?vue&type=script&lang=js&"
import style0 from "./form-employee.vue?vue&type=style&index=0&id=47a9d22c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a9d22c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VForm,VRadio,VRadioGroup})
