import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import clipboard from '~/mixins/global/_clipboard'
import AppNotifications from '~/services/Notifications/AppNotifications'
import Receipts from '~/modules/receipt/models/Receipts'
import Dialog from '~/models/system/Dialog'

export class AcquiringInvoices extends PersistAble(OrmModel) {
  static entity = 'acquiringinvoices'
  static paginated = true
  static defaultSortParam = 'dateCreated'
  static defaultSortOrder = true

  static statuses = {
    created: {
      value: 'created',
      text: 'Awaiting payment'
    },
    payed: {
      value: 'payed',
      text: 'Очікується видача чека'
    },
    paymentError: {
      value: 'payment_error',
      text: 'Помилка при оплаті'
    },
    done: {
      value: 'done',
      text: 'Оплачено'
    },
    receiptError: {
      value: 'receipt_error',
      text: 'Помилка при видачі чеку'
    },
    canceled: {
      value: 'canceled_qr',
      text: 'Відмінено'
    },
    invoiceExpired: {
      value: 'invoice_expired',
      text: 'Закінчився термін дії'
    }
  }

  static providers = {
    monobank: {
      value: 'monobank',
      text: 'monobank'
    },
    easypay: {
      value: 'easypay',
      text: 'EasyPay'
    },
    rozetkapay: {
      value: 'rozetkapay',
      text: 'RozetkaPay'
    }
  }

  static ormTrans = {
    single: 'Order',
    multy: 'Order'
  }

  static fields () {
    return {
      id: this.attr(null),
      dateCreated: this.attr(null),
      dateModified: this.attr(null),
      payedAt: this.attr(null),
      invoiceId: this.attr(null),
      pageUrl: this.attr(null),
      status: this.attr(null),
      receiptId: this.attr(null),
      totalSum: this.attr(null),
      employee: this.attr(null),
      cashRegister: this.attr(null),
      receiptBody: this.attr(null),
      failureReason: this.attr(null),
      provider: this.attr(null),
      comment: this.attr(null)
    }
  }

  get totalSumString () {
    return ((this.totalSum || 0) / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get dateCreatedString () {
    return this.getDateTime(this.dateCreated)
  }

  get payedAtString () {
    return this.getDateTime(this.payedAt)
  }

  get providerString () {
    return get(AcquiringInvoices, `providers[${this.provider}].text`) || '–'
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', width: '150', sortable: true, filterable: false },
    { text: 'Payment system', value: 'providerString', sortable: false, filterable: false },
    { text: 'Date created', value: 'dateCreatedString', sortable: true, filterable: false, sortQuery: 'dateCreated' },
    { text: 'Payed date', value: 'payedAtString', sortable: true, filterable: false, sortQuery: 'payedAt' },
    { text: 'Total sum', value: 'totalSumString', align: 'right', width: '120', sortable: false, filterable: false },
    { text: 'Cash register ', value: 'cashRegister.fiscalNumber', sortable: false, filterable: false },
    { text: 'Comment', value: 'comment', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            created,
            payed,
            paymentError,
            done,
            receiptError,
            canceled,
            invoiceExpired
          } = this.statuses
          return {
            id: item.id,
            [created.value]: {
              text: created.text,
              value: created.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [payed.value]: {
              text: payed.text,
              value: payed.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [done.value]: {
              text: done.text,
              value: done.value,
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            success: {
              text: done.text,
              value: 'success',
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            [paymentError.value]: {
              text: paymentError.text,
              value: paymentError.value,
              tooltip: item.failureReason,
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#FF002B'
              }
            },
            [receiptError.value]: {
              text: receiptError.text,
              value: receiptError.value,
              tooltip: item.failureReason,
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#FF002B'
              }
            },
            [invoiceExpired.value]: {
              text: invoiceExpired.text,
              value: invoiceExpired.value,
              tooltip: 'Тедмін дії даного замовлення вичерпано',
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#FF002B'
              }
            },
            failure: {
              text: 'Помилка',
              value: 'failure',
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#FF002B'
              }
            },
            [canceled.value]: {
              text: canceled.text,
              value: canceled.value,
              appearance: {
                color: 'rgba(22, 27, 37, 0.1)',
                textColor: '#161b25'
              }
            }
          }
        }
      }
    }
  }

  static ormActions = [
    {
      name: 'read',
      text: 'View order',
      call: (item) => {
        Dialog.query().where('type', 'content_top').first().open({
          component: 'm-block-acquiring-order-view',
          title: 'Замовлення від ' + item.dateCreatedString,
          componentProps: {
            order: item
          }
        })
      }
    },
    {
      name: 'delete',
      visible: item => [AcquiringInvoices.statuses.invoiceExpired.value, AcquiringInvoices.statuses.paymentError.value].includes(item.status)
    },
    {
      name: 'copyLink',
      text: 'Copy link',
      icon: {
        type: 'e-svg-icon',
        text: 'copy'
      },
      visible: item => item.status === AcquiringInvoices.statuses.created.value,
      call: (item) => {
        clipboard.methods.copy(item.pageUrl)
        AppNotifications.success('Copied to clipboard')
      }
    },
    {
      name: 'openReceiptView',
      icon: {
        type: 'e-svg-icon',
        text: 'receipt'
      },
      visible: item => [AcquiringInvoices.statuses.done.value, 'success'].includes(item.status),
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          component: 'block-receipt-view',
          width: '470px',
          contentFullHeight: true,
          componentProps: {
            item,
            config: {
              view: 'html',
              download: 'pdf',
              downloadNameField: 'payedAtString',
              downloadPrefix: 'receipt',
              model: Receipts,
              requestField: 'receiptId'
            }
          }
        })
      }
    },
    {
      name: 'createReceipt',
      text: 'Create receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'receipt'
      },
      visible: item => [AcquiringInvoices.statuses.receiptError.value].includes(item.status),
      call: async (order) => {
        await Dialog.query().where('type', 'content_top').first().open({
          component: 'm-block-acquiring-order-create-receipt',
          width: '400px',
          title: 'Create receipt',
          componentProps: {
            order
          }
        })
      }
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: this.ormTrans.single, name: 'від ' + item.dateCreatedString }),
      notification: item => ({ type: this.ormTrans.single })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.createReceipt = function (orderId) {
        return this.put(Model.$routes.acquiringinvoices.createReceipt(orderId), {}, { dataKey: null })
      }
      configActions.checkStatus = function (orderId) {
        return this.get(Model.$routes.acquiringinvoices.checkStatus(orderId), { save: false })
      }
      configActions.resetQr = function (orderId) {
        return this.put(Model.$routes.acquiringinvoices.resetQr(orderId), {}, { dataKey: null })
      }

      return configActions
    }
  }
}

export default AcquiringInvoices
