<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-form(@submit.prevent.stop="saveAndSendToTax")
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          v-card-title(class="text-center" v-if="!isDialogPart")
            div(style="width:100%") {{$t('Create cash register')}}
          t-orm-fields(v-bind.sync="editedItem"
            :items="fields"
            :tabs="model.ormTabs"
            :context="context")
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import Organization from '~/models/directories/Organization'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import taxAuth from '~/mixins/pages/tax-auth'
import { CashRegisterOrder } from '~/models/directories/Orders/CashRegisterOrder'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'

export default {
  components: {
    TOrmButtons,
    TOrmFields
  },
  mixins: [validate, filterOrmObject, checkPropCtx, taxAuth, edsIitCheckboxDialog, confirmationDialog],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    isDialogPart: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    model: CashRegisterOrder,
    editedItem: {},
    itemLoading: false,
    createdCashRegister: null,
    enterCustomOutlet: false
  }),
  computed: {
    fields () {
      return this._.get(this.model, 'ormFields', [])
    },
    organization () {
      return Organization.query().whereId(this.$User.organization[Organization.primaryKey]).first()
    },
    config () {
      return {}
    },
    buttons () {
      return [
        {
          text: 'Add cash register',
          contexts: this.$config.contexts.only('c'),
          attrs: {
            depressed: true,
            class: 'main-button',
            type: 'submit'
          },
          hint: 'This action must be signed by the EDS of the director',
          loading: this.itemLoading || this._.get(this.iitDialog, 'options.loading', false)
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    clear () {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    },
    async saveAndSendToTax () {
      if (!await this.validate()) {
        return false
      }
      try {
        this.itemLoading = true
        const payload = this.filterOrmObject(this.editedItem)
        if (!payload.outlet.name) {
          const confirmedClosing = await this.confirmationDialog.open({ title: this.$t('Confirmation cash register creating'), text: this.$t('Outlet without name'), width: '490px' })
          if (!confirmedClosing) {
            this.close()
            return
          }
        }
        payload.R04G11S = `checkbox_${payload.R04G12S}`
        await this.useEdsIitCheckboxDialog({
          fallbackMethod: this.saveAndSendToTax,
          checkAuthService: true,
          createReportService: true,
          onConfirm: async ({ error, noReturn, reportService }) => {
            try {
              if (error) {
                return !noReturn ? this.saveAndSendToTax() : null
              }

              payload.HKBOS = reportService.getHKBOSField({
                sentry: this.$sentry,
                organization: this.$Organization,
                entity: this._.get(this.model, 'entity'),
                type: 'creation'
              })

              const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

              if (!re.test(payload.HKBOS)) {
                this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
                return
              }

              if (!payload.HKBOS) {
                this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
                return
              }

              const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)
              const xmlResponse = await this.model.api().xmlDoc(createdEntity)
              const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
              const fname = this._.get(xmlResponse, 'response.data.fname', null)
              const signData = await reportService.getEnvelopedData(xml)

              if (!signData) {
                return null
              }

              const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
              const encodedTaxId = new TextEncoder().encode(String(taxId))
              const signedTaxId = await reportService.getEnvelopedData(encodedTaxId, false)

              if (!signedTaxId) {
                return null
              }

              await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })

              this.$notification.success(`entityActions.${this.context}.${this.model.entity}`)
              this.close()
            } catch (e) {
              this.$handlers.error(e, this.$refs[this.model.entity])
            }
          }
        })
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
