<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-form(@submit.prevent.stop="handleSubmit")
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          t-orm-fields(v-bind.sync="editedItem"
            :items="model.ormFields"
            :tabs="model.ormTabs"
            :context="context")
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import Organization from '~/models/directories/Organization'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import taxAuth from '~/mixins/pages/tax-auth'
import TaxBills from '~/models/billing/TaxBills'
import routes from '~/const/routes'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

export default {
  components: {
    TOrmButtons,
    TOrmFields
  },
  mixins: [validate, filterOrmObject, checkPropCtx, taxAuth, edsIitCheckboxDialog],
  props: {
    context: {
      type: String,
      default: 'update'
    },
    selectedItem: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => () => {}
    }
  },
  data: () => ({
    model: TaxBills,
    editedItem: {},
    itemLoading: false,
    editedTaxBill: null,
    justSave: false
  }),
  computed: {
    organization () {
      return Organization.query().whereId(this.$User.organization[Organization.primaryKey]).first()
    },
    config () {
      return {}
    },
    buttons () {
      return [
        {
          text: 'Save',
          contexts: this.$config.contexts.only('c.u'),
          attrs: {
            depressed: true,
            class: 'secondary-button color-main mr-5',
            type: 'submit'
          },
          loading: this.itemLoading
        },
        {
          text: 'Send way bill',
          contexts: this.$config.contexts.only('c.u'),
          attrs: {
            depressed: true,
            class: 'main-button'
          },
          call: this.saveAndSendToTax,
          loading: this.itemLoading || this._.get(this.iitDialog, 'options.loading', false)
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ from: this.selectedItem, ctx: this.context }))
  },
  methods: {
    handleSubmit () {
      this.justSave = true
      this.saveWithoutSending()
    },
    close (newOne = false) {
      this.$emit(['dialog', 'close'].join(':'), newOne ? 'new' : null)
    },
    clear () {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    },
    async saveWithoutSending () {
      const payload = this.filterOrmObject(this.editedItem)
      payload.sumDetails = JSON.parse(payload.sumDetails)
      payload.sum = parseFloat(payload.sum) * 100
      payload.number = parseInt(payload.number)

      try {
        this.itemLoading = true
        const response = await this.model.api().update(this.selectedItem, payload)
        this.$notification.success(this.$t('Tax bill was updated successfully'))
        if (this.justSave) {
          this.justSave = false
          this.closeModal()
        }
        return response
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      } finally {
        this.itemLoading = false
      }
    },
    async saveAndSendToTax () {
      if (!await this.validate()) {
        return false
      }
      try {
        this.editedTaxBill = this._.get(await this.saveWithoutSending(), 'entities.taxbills[0]', null)
        if (this.editedTaxBill) {
          this.itemLoading = true
          await this.useEdsIitCheckboxDialog({
            type: 'edsIitCheckboxCommon',
            keyCheck: false,
            createReportService: true,
            onConfirm: async ({ reportService }) => {
              try {
                if (!reportService) {
                  return null
                }
                const { fname, xml } = this._.get(await this.model.api().put(routes[this.model.entity].xmlDoc(this.editedTaxBill.id), {}, { save: false }), 'response.data', {})
                const signData = await reportService.getEnvelopedData(Base64js.toByteArray(xml), true)
                if (!signData) {
                  return
                }
                await this.model.api().put(routes[this.model.entity].taxRegister(this.editedTaxBill.id), { fname, signData })
                this.$notification.success(this.$t('Tax bill was successfully sent to sfs'))
                this.closeModal()
              } catch (e) {
                this.$handlers.error(e, this.$refs[this.model.entity])
              }
            }
          })
        }
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
