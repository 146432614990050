import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import FilterAble from '~/models/mixins/FilterAble'
import { formats } from '~/const/global'

export default class ClientBalances extends ChainInheritance(Directory, [FilterAble]) {
  static entity = 'clientbalances'
  static paginated = true
  static ormLoadWithRelations = false
  static defaultSortParam = 'name'
  static forceFiltered = true

  static ormTrans = {
    single: 'Client balance',
    multy: 'Client balances'
  }

  static BALANCE_TYPES = {
    cbm: {
      text: 'Check by mono',
      value: 'cbm'
    },
    checkbox: {
      text: 'Checkbox',
      value: 'checkbox'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      edrpou: this.attr(null),
      start_balance: this.attr(null),
      acts: this.attr(null),
      payments: this.attr(null),
      refunds: this.attr(null),
      end_balance: this.attr(null),
      verif_code: this.attr(null)
    }
  }

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['name'],
      'sort-desc': [false]
    })
  }

  static ormHeaders = [
    { text: 'Organization ', value: 'name', sortable: false, filterable: false },
    { text: 'Edrpou', value: 'edrpou', sortable: false, filterable: false },
    { text: 'Start balance', value: 'start_balance', sortable: false, filterable: false },
    { text: 'Acts sum', value: 'acts', sortable: false, filterable: false },
    { text: 'Payments sum', value: 'payments', sortable: false, filterable: false },
    { text: 'Refunds sum', value: 'refunds', sortable: false, filterable: false },
    { text: 'End balance', value: 'end_balance', sortable: false, filterable: false },
    { text: 'Payment code', value: 'verif_code', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'type',
      component: 'v-select',
      attrs: {
        label: 'Balance type',
        outlined: true,
        'hide-details': true
      },
      default: () => '',
      items: () => [{ text: 'Всі', value: '' }, ...Object.values(this.BALANCE_TYPES)]
    },
    {
      model: 'date',
      component: 'e-input-datetime-range',
      default: () => {
        const date = new Date()
        date.setMonth(date.getMonth() - 1)
        return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
      },
      attrs: {
        closeOnClick: false,
        clearable: false,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Date',
        max: () => {
          const today = new Date()
          return this.$moment(today).format(formats.dateISO8601)
        }
      }
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Edrpou',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'type'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'date'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = []

  static ormActions = []

  static ormDialogs = {}

  static ormDialogsConfig = {}

  // static apiConfig = {}
}
