<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-form(@submit.prevent.stop="submit")
      div(class="addition")
        div(class="addition__header")
          div()
            p() Відмітка про одержання
            p() (штамп контролюючого органу)
          div()
            p() Додаток 2
            p() до податкової декларації платника єдиного податку третьої групи на період дії воєнного, надзвичайного стану в Україні
        div(class="addition__data")
          div()
            h3(class="mb-3") ВІДОМОСТІ
            p(class="bigger")
              b про суми нарахованого доходу застрахованих осіб та суми нарахованого єдиного внеску
          div(class="addition__type-table")
            div(class="addition__type-table-row")
              div() 01
              div()
                ValidationProvider(
                  vid="reportingType"
                  mode="lazy"
                  rules="required"
                  v-slot="{ errors }"
                )
                  v-checkbox(
                    v-model="form.reportingType"
                    true-value="HZ"
                    :false-value="null"
                    class="mt-0"
                    :error-messages="errors"
                    hide-details
                  )
              div() Звітна
            div(class="addition__type-table-row")
              div() 02
              div()
                ValidationProvider(
                  vid="reportingType"
                  mode="lazy"
                  rules="required"
                  v-slot="{ errors }"
                )
                  v-checkbox(
                    v-model="form.reportingType"
                    true-value="HZN"
                    :false-value="null"
                    class="mt-0"
                    :error-messages="errors"
                    hide-details
                  )
              div() Звітна нова
        div(class="addition__text-input-block")
          div()
            p()
             span(class="mr-1") Реєстраційний номер облікової картки платника податків або серія (за наявності) та номер паспорта
             sup 1
          div()
            ValidationProvider(
              name=" "
              vid="HTIN"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HTIN"
                :label="$t('Number')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-input-block")
          div()
            p() Прізвище, імя, по батькові (за наявності)
          div()
            ValidationProvider(
              name=" "
              vid="HNAME"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HNAME"
                :label="$t('Full name')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__period")
          div()
            p() Податковий (звітний) період:
          div(class="addition__period-inputs")
            ValidationProvider(
              name=" "
              vid="reportingPeriod"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.reportingPeriod"
                :label="$t('Month')"
                :error-messages="errors"
                full-width
                outlined
              )
            ValidationProvider(
              name=" "
              vid="HZY"
              mode="lazy"
              rules="required|length:4|numeric"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HZY"
                :label="$t('Year ')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-input-block")
          div()
            p() Код основного виду економічної діяльності
          div()
            ValidationProvider(
              name=" "
              vid="HKVED"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HKVED"
                :label="$t('KVED')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-input-block addition__text-input-block--borderless")
          div()
            p() Період перебування фізичної особи - підприємця на спрощеній системі оподаткування
          div(class="addition__right-block-inputs")
            ValidationProvider(
              name=" "
              vid="R08G1D"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              e-input-datetime(
                v-model="form.R08G1D"
                :error-messages="errors"
                label="З"
                outlined
              )
            ValidationProvider(
              name=" "
              vid="R08G2D"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              e-input-datetime(
                v-model="form.R08G2D"
                :error-messages="errors"
                label="По"
                outlined
              )
        div(class="addition__text-input-block")
          div()
            p()
              span(class="mr-1") Код категорії застрахованої особи
              sup 2
          div()
            ValidationProvider(
              name=" "
              vid="R081G1"
              mode="lazy"
              rules="required|max:2|numeric"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.R081G1"
                :label="$t('Code')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-block")
          div()
            p() ВИЗНАЧЕННЯ СУМ НАРАХОВАНОГО ДОХОДУ ЗАСТРАХОВАНИХ ОСІБ ТА СУМИ НАРАХОВАНОГО ЄДИНОГО ВНЕСКУ
        div(class="addition__table")
          div(class="addition__table-row addition__table-row--head")
            div()
              p() Місяць
            div()
              p() Самостійно визначена сума доходу, на яку нараховується єдиний внесок з урахуванням максимальної величини
            div()
              p()
                span(class="mr-1") Розмір єдиного внеску, відсоток
                sup 3
            div()
              p() Сума єдиного внеску, яка підлягає сплаті на небюджетні рахунки, за даними звітного (податкового) періоду (графа 2 х графа 3) грн, коп
          div(
            class="addition__table-row"
            v-for="(item, index) in taxSumTableRows"
            :key="index"
          )
            div()
              p() {{ item.month }}
            div()
              ValidationProvider(
                name=" "
                :vid="item.field1"
                mode="lazy"
                v-slot="{ errors }"
              )
                v-text-field(
                  v-model="form.taxSum[item.field1]"
                  :label="$t('Sum')"
                  :error-messages="errors"
                  @input="onSumChange(item.field1)"
                  full-width
                  outlined
                )
            div()
              ValidationProvider(
                v-if="item.field2"
                name=" "
                :vid="item.field2"
                mode="lazy"
                v-slot="{ errors }"
              )
                v-text-field(
                  v-model="form.taxSum[item.field2]"
                  :label="$t('Percent')"
                  :error-messages="errors"
                  @input="onSumChange(item.field2)"
                  full-width
                  outlined
                )
            div()
              ValidationProvider(
                name=" "
                :vid="item.field3"
                mode="lazy"
                v-slot="{ errors }"
              )
                v-text-field(
                  v-model="form.taxSum[item.field3]"
                  :label="$t('Sum')"
                  :error-messages="errors"
                  @input="onSumChange(item.field3)"
                  full-width
                  outlined
                  disabled
                )
        div(class="addition__footer")
          div()
            p()
              sup 1
              span(class="ml-1") Серію (за наявності) та номер паспорта зазначають фізичні особи, які через релігійні переконання відмовляються від прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це відповідний контролюючий орган і мають відмітку в паспорті.
            p()
              sup 2
              span(class="ml-1") Зазначається код категорії застрахованої особи "6" - фізична особа - підприємець на спрощеній системі оподаткування.
            p()
              sup 3
              span(class="ml-1") Зазначається розмір єдиного внеску, встановлений законом.
        div(class="addition__footer-input")
          div()
            p() Наведена інформація є вірною:
            p() Фізична особа – платник єдиного внеску або уповноважена особа
          div()
            ValidationProvider(
              name=" "
              vid="HBOS"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HBOS"
                label="ПІБ"
                :error-messages="errors"
                full-width
                outlined
              )
      div(class="py-6")
        v-btn(
          class="main-button d-block mx-auto"
          type="submit"
          :loading="loading"
        ) {{ $t('Save') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import EInputDatetime from '~/components/elements/inputs/e-input-datetime'
import taxReportsAdditions from '~/modules/reports/mixins/taxReportsAdditions'

export default {
  name: 'BlockTaxReportAdditionFormThirdTwo',
  components: {
    EInputDatetime
  },
  mixins: [responsive, taxReportsAdditions],
  props: {
    type: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
$border-solid: 2px solid #000;
$border-thin: 1px solid rgba(0, 0, 0, 0.35);

.addition {
  border: $border-solid;
  font-size: 13px;

  p {
    font-size: 13px;
    margin-bottom: 0;

    &.bigger {
      font-size: 15px;
    }
  }

  &__header {
    border-bottom: $border-solid;
    display: flex;

    > div:first-child {
      width: 40%;
      border-right: $border-thin;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    > div:last-child {
      width: 60%;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      p {
        width: 90%;
      }
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;

      > div:first-child {
        width: 100%;
        border-bottom: $border-thin;
        border-right: none;
      }

      > div:last-child {
        width: 100%;
        align-items: flex-start;

        p {
          width: 100%;
        }
      }
    }
  }

  &__data {
    border-bottom: $border-solid;
    display: flex;
    align-items: stretch;

    > div:first-child {
      text-align: center;
      padding: 20px 10px;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;
    }
  }

  &__type-table {
    display: flex;
    flex-direction: column;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 100%;
    }

    &-row {
      border: $border-thin;
      border-top: none;
      border-right: none;
      display: flex;
      font-size: 13px;
      height: 100%;

      &:last-child {
        border-bottom: none;
      }

      > div {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          border-right: $border-thin;
        }
      }

      > div:nth-child(1) {
        width: 40px;
      }

      > div:nth-child(2) {
        padding: 10px 15px;

        &::v-deep {
          .v-input--selection-controls__input {
            margin-right: 0;
          }
        }
      }

      > div:nth-child(3) {
        width: 150px;
        text-align: center;
      }

      @media (max-width: map-get($breakpoints, 'sm')) {
        border-left: none;

        &:first-child {
          border-top: $border-thin;
        }

        > div:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
  }

  &__text-input-block {
    border-bottom: $border-solid;
    display: flex;

    &--borderless {
      border-bottom: none;
    }

    > div {
      padding: 15px 10px;
    }

    > div:first-child {
      border-right: $border-thin;
      width: 65%;
    }

    > div:last-child {
      width: 35%;
      padding: 15px 15px 0;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;

      > div:first-child {
        border-right: none;
        width: 100%;
      }

      > div:last-child {
        width: 100%;
        padding-top: 0;
      }
    }
  }

  &__period {
    padding: 15px 10px;
    border-bottom: $border-solid;

    &-inputs {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 10px;

      @media (max-width: map-get($breakpoints, 'sm')) {
        display: block;
      }
    }

    &::v-deep {
      .v-input--radio-group__input {
        gap: 7px;
      }
    }
  }

  &__right-block-inputs {
    display: flex;
    gap: 10px;

    @media (max-width: map-get($breakpoints, 'md')) {
      display: block;
    }
  }

  &__text-block {
    padding: 15px 10px;
    border-bottom: $border-solid;

    p {
      font-size: 13px;
      font-weight: 700;
    }
  }

  &__footer {
    padding: 15px 10px;

    p {
      font-size: 11px;
      font-weight: 400;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__small-table {
    border-bottom: $border-solid;

    &-row {
      border-bottom: $border-thin;
      display: flex;

      &:last-child {
        border-bottom: none;
      }

      > div:nth-child(1) {
        border-right: $border-thin;
        padding: 15px 10px;
        width: 5%;
        text-align: center;
      }

      > div:nth-child(2) {
        border-right: $border-thin;
        padding: 15px 10px;
        width: 60%;
      }

      > div:nth-child(3) {
        width: 35%;
        padding: 15px 10px 0;
      }

      @media (max-width: map-get($breakpoints, 'sm')) {
        flex-wrap: wrap;

        > div:nth-child(1) {
          min-width: 30px;
          max-width: 30px;
          border-right: none;
        }

        > div:nth-child(2) {
          width: calc(100% - 30px);
          border-right: none;
        }

        > div:nth-child(3) {
          width: 100%;
          padding: 0 10px;
        }
      }
    }
  }

  &__footer-input {
    border-top: $border-solid;
    display: flex;

    p {
      font-size: 13px;
      font-weight: 700;
    }

    > div:first-child {
      padding: 15px 10px;
      border-right: $border-thin;
      width: 65%;
    }

    > div:last-child {
      padding: 15px 10px 0;
      width: 35%;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;

      > div:first-child {
        border-right: none;
        width: 100%;
      }

      > div:last-child {
        padding: 0 10px;
        width: 100%;
      }
    }
  }

  &__table {
    border-bottom: $border-solid;
    overflow-x: auto;

    &-row {
      border-bottom: $border-thin;
      display: flex;
      min-width: 620px;

      &:last-child {
        border-bottom: none;
      }

      > div {
        padding: 15px 10px;
      }

      > div:nth-child(1) {
        width: 10%;
        border-right: $border-thin;
        min-width: 93px;
      }

      > div:nth-child(2) {
        width: 40%;
        padding-bottom: 0;
        border-right: $border-thin;
      }

      > div:nth-child(3) {
        width: 25%;
        padding-bottom: 0;
        border-right: $border-thin;
      }

      > div:nth-child(4) {
        width: 25%;
        padding-bottom: 0;
      }

      &--head {
        > div:nth-child(2),
        > div:nth-child(3),
        > div:nth-child(4) {
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>
