import { Model } from '@vuex-orm/core'
import html2pdf from 'html2pdf.js'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import DateTimeAble from '~/models/mixins/DateTimeAble'
import Dialog from '~/models/system/Dialog'
import css from '~/modules/reports/constants/receiptHtmlCss'

export class TaxReports extends ChainInheritance(Directory, [DateTimeAble]) {
  static entity = 'taxreports'
  static paginated = true
  static defaultSortParam = 'created_at'
  static defaultSortOrder = false

  static ormTrans = {
    single: 'Report',
    multy: 'Reports'
  }

  static orderStatuses = {
    100: {
      text: 'Створено',
      value: 100,
      type: 'order'
    },
    200: {
      text: 'Відправлено до ДПС',
      value: 200,
      type: 'order',
      tooltip: 'Очікуємо на обробку звіту у ДПС. Через 5-10 хвилин натисніть кнопку "Оновити дані"'
    },
    300: {
      text: 'Прийнято',
      value: 300,
      type: 'checkbox'
    },
    400: {
      text: 'Помилка',
      value: 400,
      type: 'error'
    }
  }

  static PERIOD_TYPES = {
    H1KV: {
      value: 'H1KV',
      text: '1 квартал'
    },
    HHY: {
      value: 'HHY',
      text: 'Півріччя'
    },
    H3KV: {
      value: 'H3KV',
      text: 'Три квартали'
    },
    HY: {
      value: 'HY',
      text: 'Рік'
    },
    1: {
      value: '1',
      text: 'Січень'
    },
    2: {
      value: '2',
      text: 'Лютий'
    },
    3: {
      value: '3',
      text: 'Березень'
    },
    4: {
      value: '4',
      text: 'Квітень'
    },
    5: {
      value: '5',
      text: 'Травень'
    },
    6: {
      value: '6',
      text: 'Червень'
    },
    7: {
      value: '7',
      text: 'Липень'
    },
    8: {
      value: '8',
      text: 'Серпень'
    },
    9: {
      value: '9',
      text: 'Вересень'
    },
    10: {
      value: '10',
      text: 'Жовтень'
    },
    11: {
      value: '11',
      text: 'Листопад'
    },
    12: {
      value: '12',
      text: 'Грудень'
    }
  }

  static REPORT_TYPES = {
    thirdFive: 'F0103308',
    thirdTwo: 'F0103203',
    second: 'F0103406',
    thirdFiveOld: 'F0103307',
    secondOld: 'F0103405'
  }

  static fields () {
    return {
      id: this.attr(null),
      esvId: this.attr(null),
      status: this.attr(null),
      docName: this.attr(null),
      name: this.attr(null),
      reportingPeriod: this.attr(null),
      dateCreated: this.attr(null)
    }
  }

  get nameString () {
    if (this.docName === TaxReports.REPORT_TYPES.thirdFive || this.docName === TaxReports.REPORT_TYPES.thirdFiveOld) {
      return 'Податкова декларація платника єдиного податку – фізичної особи – підприємця'
    } else if (this.docName === TaxReports.REPORT_TYPES.thirdTwo) {
      return 'Податкова декларація платника єдиного податку третьої групи на період дії воєнного, надзвичайного стану в Україні'
    } else if (this.docName === TaxReports.REPORT_TYPES.second || this.docName === TaxReports.REPORT_TYPES.secondOld) {
      return 'Податкова декларація платника єдиного податку – фізичної особи – підприємця, у якого податковий (звітний) період рік'
    }
  }

  get dateCreatedString () {
    return this.getDateTime(this.dateCreated)
  }

  get periodString () {
    return this.reportingPeriod ? TaxReports.PERIOD_TYPES[this.reportingPeriod]?.text : ''
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', align: 'left', filterable: false, sortable: false },
    { text: 'Document number', value: 'docName', align: 'left', filterable: false, sortable: false },
    { text: 'Document name', value: 'nameString', align: 'left', filterable: false, sortable: false },
    { text: 'Date of created', value: 'dateCreatedString', align: 'left', filterable: false, sortable: false },
    { text: 'Period', value: 'periodString', align: 'left', filterable: false, sortable: false },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '72' }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) {
            return {}
          }
          const orderStatus = this.orderStatuses[item.status]
          return {
            id: item.id,
            model: TaxReports,
            [item.status]: {
              text: orderStatus.text,
              type: orderStatus.type,
              tooltip: orderStatus.tooltip
            }
          }
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'start'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'viewReport',
      text: 'View the report',
      icon: {
        type: 'e-svg-icon',
        text: 'read'
      },
      visible: item => item.status === 300 || item.status === 400,
      call: async (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open(
          {
            title: 'View the report',
            component: 'block-tax-report-view',
            width: '1000px',
            contentFullHeight: true,
            componentProps: {
              reportType: item.docName,
              payload: item.id
            }
          }
        )
      }
    },
    {
      name: 'downloadReceipt',
      text: 'Download the receipt №2',
      icon: {
        type: 'e-svg-icon',
        text: 'download-black'
      },
      visible: item => item.status === 300 || item.status === 400,
      call: async (item) => {
        const res = await TaxReports.api().reportPdf(item)
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      }
    },
    {
      name: 'downloadReceiptConcat',
      text: 'Download report + receipt №2',
      icon: {
        type: 'e-svg-icon',
        text: 'download-black'
      },
      visible: item => item.status === 300 || item.status === 400,
      call: async (item) => {
        let method = 'getReportHtml'
        if (item.docName === TaxReports.REPORT_TYPES.thirdTwo) {
          method = 'getTemporaryReportHtml'
        } else if (item.docName === TaxReports.REPORT_TYPES.second) {
          method = 'getAnnualReportHtml'
        }
        let reportDocument = this._.get(await TaxReports.api()[method](item?.id), 'response.data')
        if (item?.reportingPeriod === TaxReports.PERIOD_TYPES.HY.value && item?.esvId) {
          try {
            const reportEsvHtml = this._.get(await TaxReports.api().getEsvReportHtml(item.esvId), 'response.data')
            if (reportEsvHtml) {
              reportDocument += `<div style="margin-top: 100px">${reportEsvHtml}</div>`
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
          }
        }
        const receiptHtml = this._.get(await TaxReports.api().reportHtml(item), 'response.data')
        if (receiptHtml) {
          reportDocument += `<div style="margin-top: 100px"><style>${css}</style>${receiptHtml}</div>`
        }
        const frame = document.createElement('IFRAME')
        frame.domain = document.domain
        frame.style.position = 'absolute'
        frame.style.top = '-10000px'
        document.body.appendChild(frame)
        frame.contentDocument.write(reportDocument)
        html2pdf().set({
          margin: 3,
          filename: 'tax-report-receipt.pdf'
        }).from(frame.contentDocument.body).save()
        frame.parentNode.removeChild(frame) // remove frame
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getReportInfo = function (payload, config = {}) {
        return this.post(Model.$routes.taxreports.getReportInfo(), payload, { save: false, ...config })
      }
      configActions.createReport = function (payload) {
        return this.post(Model.$routes.taxreports.report(), payload)
      }
      configActions.reportTaxId = function (id, payload) {
        return this.put(Model.$routes.taxreports.reportTaxId(id), payload, { save: false })
      }
      configActions.reportXmlDoc = function (id) {
        return this.get(Model.$routes.taxreports.reportXmlDoc(id), { save: false })
      }
      configActions.reportSendToTax = function (id, payload) {
        return this.post(Model.$routes.taxreports.reportSendToTax(id), payload)
      }
      configActions.getReportHtml = function (id) {
        return this.get(Model.$routes.taxreports.getReportHtml(id), { save: false })
      }
      configActions.getReportDraftHtml = function (payload) {
        return this.post(Model.$routes.taxreports.getReportDraftHtml(), payload, { save: false })
      }

      configActions.getTemporaryReportInfo = function (config = {}) {
        return this.get(Model.$routes.taxreports.getTemporaryReportInfo(), { save: false, ...config })
      }
      configActions.createTemporaryReport = function (payload) {
        return this.post(Model.$routes.taxreports.temporaryReport(), payload)
      }
      configActions.temporaryReportTaxId = function (id, payload) {
        return this.put(Model.$routes.taxreports.temporaryReportTaxId(id), payload, { save: false })
      }
      configActions.temporaryReportXmlDoc = function (id) {
        return this.get(Model.$routes.taxreports.temporaryReportXmlDoc(id), { save: false })
      }
      configActions.temporaryReportSendToTax = function (id, payload) {
        return this.post(Model.$routes.taxreports.temporaryReportSendToTax(id), payload)
      }
      configActions.getTemporaryReportHtml = function (id) {
        return this.get(Model.$routes.taxreports.getTemporaryReportHtml(id), { save: false })
      }
      configActions.getTemporaryReportDraftHtml = function (payload) {
        return this.post(Model.$routes.taxreports.getTemporaryReportDraftHtml(), payload, { save: false })
      }

      configActions.getAnnualReportInfo = function (config = {}) {
        return this.get(Model.$routes.taxreports.getAnnualReportInfo(), { save: false, ...config })
      }
      configActions.createAnnualReport = function (payload) {
        return this.post(Model.$routes.taxreports.annualReport(), payload)
      }
      configActions.annualReportTaxId = function (id, payload) {
        return this.put(Model.$routes.taxreports.annualReportTaxId(id), payload, { save: false })
      }
      configActions.annualReportXmlDoc = function (id) {
        return this.get(Model.$routes.taxreports.annualReportXmlDoc(id), { save: false })
      }
      configActions.annualReportSendToTax = function (id, payload) {
        return this.post(Model.$routes.taxreports.annualReportSendToTax(id), payload)
      }
      configActions.getAnnualReportHtml = function (id) {
        return this.get(Model.$routes.taxreports.getAnnualReportHtml(id), { save: false })
      }
      configActions.getAnnualReportDraftHtml = function (payload) {
        return this.post(Model.$routes.taxreports.getAnnualReportDraftHtml(), payload, { save: false })
      }
      configActions.getEsvReportHtml = function (id) {
        return this.get(Model.$routes.taxreports.getEsvReportHtml(id), { save: false })
      }

      configActions.reportHtml = function (item) {
        return this.get(Model.$routes.taxreports.reportHtml(item.id), {
          save: false,
          params: {
            docname: item.docName
          }
        })
      }

      configActions.reportPdf = function (item) {
        return this.get(Model.$routes.taxreports.reportPdf(item.id), {
          save: false,
          responseType: 'blob',
          params: {
            docname: item.docName
          }
        })
      }

      configActions.synchronization = function (payload) {
        return this.post(Model.$routes.taxreports.synchronization(), payload, { save: false })
      }

      return configActions
    }
  }
}

export default TaxReports
