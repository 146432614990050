<template lang="pug">
  div(class="d-flex justify-center align-center w-100 h-100" v-if="modalLoading")
    div(class="pb-8")
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
  div(class="block-prepayment-payment pb-6" v-else)
    div(class="block-prepayment-payment__info mb-3")
      div(class="d-flex align-center justify-space-between")
        span(v-if="item.relation_id" class="block-prepayment-payment__relation-id") {{ item.relation_id }}
        e-models-cols-map(v-bind="colsMapAttrs")
      div(class="d-flex align-center justify-space-between")
        span(class="block-prepayment-payment__label") {{ $t('Postpayment sum') }}
        span(v-if="item.left_to_pay" class="block-prepayment-payment__total") {{ (_.get(item, 'left_to_pay', 0) / 100) | currencyFormat }} &#8372;
    ValidationProvider(
      :name="$t('Sent to email')"
      ref="emailValidation"
      vid="email"
      mode="passive"
      rules="email"
      v-slot="{ errors }"
      slim
    )
      v-text-field(
        class="filled-input mb-8"
        :placeholder="$t('Sent to email')"
        v-model="email"
        color="#161b25"
        :error-messages="errors"
        outlined
      )
    m-block-receipt-payment-type(
      ref="paymentTypeBlock"
      :is-prepayment="true"
      :prepayment.sync="prepayment"
      :inside-pre-payment-modal="true"
      @changePaymentType="changePaymentType"
      @changeEttnNumber="onChangeEttnNumber"
      @input="setPaymentsPayload"
      @change:prepayment="handleChangePrepayment"
    )
    div(v-if="paymentType === _.get(paymentTypes, 'ettn.value')" class="mt-5")
      ValidationProvider(
        ref="phoneValidation"
        vid="phone"
        v-if="hasSmsBilling"
        mode="passive"
        rules="phone"
        v-slot="{ errors }"
        :name="$t('Phone')"
        slim
      )
        e-input-mask(
          mask="mobile"
          class="mt-1 m-block-receipt-sale-receipt-view__input"
          color="#161b25"
          v-model="phone"
          :placeholder="$t('Send to sms')"
          :error-messages="errors"
          outlined
        )
      e-input-switch-tooltip(
        label="Send eTTN by sms"
        v-model="ettnSmsEnabled"
        tooltip="Connect SMS billing for eTTN"
        :disabled="!hasSmsBilling"
      )
    e-input-switch-tooltip(
      v-model="commentInput"
      label="Add comment to receipt"
      :tooltip="commentTooltip"
      class="mt-5"
    )
    ValidationProvider(
      v-if="commentInput"
      :name="$t('Comment')"
      ref="commentValidation"
      vid="comment"
      mode="passive"
      rules="max:150"
      v-slot="{ errors }"
      slim
    )
      v-text-field(
        class="mt-4 block-prepayment-payment__input"
        :class="{ 'filled-input': !xsDevice }"
        :placeholder="$t('Comment')"
        v-model="comment"
        color="#161b25"
        :error-messages="errors"
        outlined
      )
    div(class="pt-8")
      v-btn(
        color="black"
        dark
        style="height:48px"
        class="w-100"
        :loading="loading"
        :disabled="!canCreateReceipt"
        @click="submit"
      )
        | {{$t('Create receipt')}}
        e-svg-icon(size="sm" class="ml-2") little_arrow
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import DOMPurify from 'dompurify'
import currencyFormat from '~/mixins/filters/currencyFormat'
import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'
import authData from '~/modules/receipt/mixins/getters/authData'
import EModelsColsMap from '~/components/elements/selections/e-models-cols-map'
import dateTime from '~/mixins/datetime/dateTime'
import MBlockReceiptPaymentType from '~/modules/receipt/views/components/m-block-receipt-payment-type'
import { ProcessingWebSocketMessageHandler } from '~/services/_utils/ProcessingWebSoket/ProcessingWebSocketMessageHandler'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import shiftData from '~/modules/receipt/mixins/getters/shiftData'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialogOverTop from '~/mixins/dialogs/contentDialogOverTop'
import processingShifts from '~/modules/receipt/mixins/actions/processingShifts'
import AcquiringInvoices from '~/modules/acquiring/models/AcquiringInvoices'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import paymentTypes from '~/modules/receipt/mixins/paymentTypes'
import Iban from '~/modules/acquiring/models/Iban'
import Dialog from '~/models/system/Dialog'
import EInputSwitchTooltip from '~/components/elements/inputs/e-input-switch-tooltip'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EInputMask from '~/components/elements/inputs/e-input-mask'
import responsive from '~/mixins/pages/responsive'
import EttnOrders from '~/modules/ettn/models/EttnOrders'

export default {
  name: 'BlockPrepaymentPayment',
  components: {
    EInputMask,
    EModelsColsMap,
    MBlockReceiptPaymentType,
    ESvgIcon,
    EProgressCircular,
    EInputSwitchTooltip
  },
  mixins: [
    currencyFormat,
    dateTime,
    processingReceipt,
    authData,
    shiftData,
    contentDialogOverTop,
    processingShifts,
    confirmationDialog,
    paymentTypes,
    responsive
  ],
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      prepayment: null,
      loading: false,
      modalLoading: false,
      ettnSmsEnabled: true,
      paymentsPayload: null,
      paymentType: null,
      cashPaymentSumLimit: 5e6,
      paymentSumLimit: 5e7,
      commentInput: false,
      comment: null,
      phone: null,
      ettn: null,
      email: null
    }
  },
  computed: {
    colsMapAttrs () {
      return {
        chips: true,
        template: '{text}',
        map: this.colsMapFunc,
        value: this.item.pre_payment_status
      }
    },
    colsMapFunc () {
      if (!this.item) {
        return
      }
      return {
        id: this.item.id,
        PARTIAL_PAID: {
          text: 'Часткова оплата',
          appearance: {
            color: 'rgba(67, 204, 180, 0.08)',
            textColor: '#43CCB4'
          }
        },
        FULL_PAID: {
          text: 'Сплачено',
          appearance: {
            color: '#161b25',
            textColor: '#ffffff'
          }
        },
        CANCELLED: {
          text: 'Відмінений',
          appearance: {
            color: 'rgba(255, 23, 68, 0.08)',
            textColor: '#FF1744'
          }
        }
      }
    },
    canCreateReceipt () {
      return !(this.paymentType === this._.get(this.paymentTypes, 'ettn.value') && this.ettn === null)
    },
    cashPaymentLimitText () {
      return '<div class="pt-6"><p>Зверніть увагу, що згідно п. 1 постанови НБУ від 06.06.2013 р. №210, гранична сума розрахунків між фізичною особою та підприємством (підприємцем) протягом одного дня в розмірі <span class="font-weight-bold">50000 (п’ятдесяти тисяч)</span> гривень заборонені!</p><p> Ви можете скористатись змішаним типом оплати "Готівка + Карта".</p><p class="font-weight-bold mb-0">Продовжити оплату готівкою?</p></div>'
    },
    overTopContentDialog () {
      return Dialog.query().where('type', 'content_over_top').first()
    },
    commentTooltip () {
      return 'This data will be in receipt footer'
    },
    hasSmsBilling () {
      return this._.get(this.$Organization, 'enableSmsModule')
    }
  },
  watch: {
    paymentType (val) {
      if (val === this.paymentTypes.cash.value) {
        this.prepayment = this.roundCashPayment(this.item.left_to_pay / 100)
      } else {
        this.prepayment = this.item.left_to_pay / 100
      }
    },
    ettnSmsEnabled (val) {
      this.$setLocalStorageClientData({ ettnSmsEnabledInReceipt: val })
    }
  },
  async created () {
    this.modalLoading = true
    const validateRequest = await EttnOrders.api().validateRelationId({
      relationId: this.item.relation_id
    })

    if (this._.get(validateRequest, 'response.data.exists', false)) {
      const ettnNumber = this._.get(validateRequest, 'response.data.ettn_number', '')
      const acquiringNumber = this._.get(validateRequest, 'response.data.acquiring_number', '')

      if (ettnNumber) {
        this.$handlers.error(this.$t('ettnAfterPaymentError', { ettnNumber }), this)
      }
      if (acquiringNumber) {
        this.$handlers.error(this.$t('monoAfterPaymentError', { acquiringNumber }), this)
      }

      this.$attrs.closeModal()
      return
    }

    this.ettnSmsEnabled = this.hasSmsBilling ? this._.get(this.$localStorageClientData(), 'ettnSmsEnabledInReceipt', true) : false
    this.modalLoading = false
  },
  methods: {
    handleChangePrepayment (val) {
      if (this.paymentType === this.paymentTypes.cash.value && val) {
        const parsedVal = parseFloat(val)
        this.prepayment = parsedVal.toFixed(1) + '0'
      }
    },
    roundCashPayment (sum) {
      if (sum <= 0 || Number.isInteger(sum)) {
        return sum
      }
      const coins = sum.toFixed(2).slice(-2)
      if (coins.slice(-1) === '0') {
        return sum
      }
      const intCoins = parseInt(coins)
      const roundedCoins = Math.round(intCoins / 10) * 10
      const totalSum = Math.round(sum * 100)
      const val = parseFloat((totalSum - intCoins + roundedCoins) / 100)
      return val.toFixed(1) + '0'
    },
    setPaymentsPayload (val) {
      this.paymentsPayload = val
    },
    changePaymentType (val) {
      this.paymentType = val
    },
    onChangeEttnNumber (val) {
      this.ettn = val
      this.phone = val?.phone
      this.$refs.phoneValidation && this.$refs.phoneValidation.reset()
    },
    async showTerminalUsageInfoModal (posTerminalSettings) {
      const localStorageClientData = localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))
      const terminalUsageInfo = this._.get(localStorageClientData, 'terminalUsageInfo')
      const dontShowTerminalUsageReminder = this._.get(terminalUsageInfo, 'dontShowTerminalUsageReminder')
      const currentShiftId = this._.get(this.shift, 'id')
      const shiftId = this._.get(terminalUsageInfo, 'shiftId')
      if (!posTerminalSettings && !dontShowTerminalUsageReminder && currentShiftId !== shiftId && this.noEpzData) {
        await this.contentDialogOverTop.open({
          component: 'block-terminal-usage-info',
          width: '560px',
          componentProps: {
            currentShiftId
          }
        })
      }
    },
    async checkSignAgentHealth () {
      if (this._.get(this.authenticatedCashier, 'signType') === 'eds_key') {
        await this.useEdsIitCheckboxDialog({
          type: 'edsIitCheckboxSignAgent',
          keyCheck: false,
          onlyFileSign: true,
          pluginTitles: {
            fileSign: this.$t('signPlugin.employeeFileKey')
          },
          hints: {
            fileSign: this.$t('signPlugin.edsWindow')
          },
          onConfirm: async ({ sign }) => {
            if (!sign) {
              return
            }
            ProcessingWebSocketMessageHandler.updateSignInterface({ sign })
            await sign(this._.get(this.authenticatedCashier, 'cashier.login', ''))
          }
        })
      }
    },
    getLocalStorageClientField (field) {
      const localStorageClientData = localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))
      return this._.get(localStorageClientData, field)
    },
    async showIbanUsageInfoModal () {
      const ibanUsageInfo = this.getLocalStorageClientField('ibanUsageInfo')
      const currentShiftId = this._.get(this.shift, 'id')
      const shiftId = this._.get(ibanUsageInfo, 'shiftId')
      if (currentShiftId !== shiftId) {
        const goToAcquiringPage = await this.overTopContentDialog.open({
          component: 'block-iban-usage-info',
          width: '560px',
          componentProps: {
            currentShiftId
          }
        })
        if (goToAcquiringPage) {
          return true
        }
      }
    },
    async processEttnOrder (payload) {
      await EttnOrders.api().create({
        employee: `employees/${this._.get(this.authenticatedCashier, 'cashier.id', '')}`,
        cashRegister: `cash-registers/${this._.get(this.authenticatedCashier, 'cashRegister.id', '')}`,
        receiptRelationId: payload.relationId,
        receiptBody: payload
      })
    },
    async processAcquiringOrder ({ receiptBody, paymentType } = {}) {
      let qrId = null
      let qrLink = null
      let provider = AcquiringInvoices.providers.easypay.value

      if (paymentType === this._.get(this.paymentTypes, 'monobank.value')) {
        const useQr = this._.get(this.$refs, 'paymentTypeBlock.monoUseQr', false)
        const qrCashRegister = (useQr || null) && this._.get(this.$refs, 'paymentTypeBlock.defaultAcquiringQr', null)
        qrId = qrCashRegister ? qrCashRegister.qrId : null
        qrLink = this._.get(qrCashRegister, 'pageUrl', null)
        provider = AcquiringInvoices.providers.monobank.value
      } else if (paymentType === this._.get(this.paymentTypes, 'rozetkaPay.value')) {
        provider = AcquiringInvoices.providers.rozetkapay.value
      }

      const res = await AcquiringInvoices.api().create({
        qrId,
        provider,
        receiptBody,
        employee: `employees/${this._.get(this.authenticatedCashier, 'cashier.id', '')}`,
        cashRegister: `cash-registers/${this._.get(this.authenticatedCashier, 'cashRegister.id', '')}`
      })
      const order = this._.get(res, 'response.data', null)

      this.contentDialog.open({
        component: 'block-acquiring-payment-info',
        width: '450px',
        componentProps: {
          order,
          qrLink
        }
      })
    },
    async submit () {
      const invalidEmail = this.$refs.emailValidation && !this._.get(await this.$refs.emailValidation.validate(), 'valid', false)
      const invalidPrepayment = this.$refs.paymentTypeBlock.$refs.prepaymentField && !this._.get(await this.$refs.paymentTypeBlock.$refs.prepaymentField.validate(), 'valid', false)
      const invalidPhone = this.hasSmsBilling && this.$refs.phoneValidation && !this._.get(await this.$refs.phoneValidation.validate(), 'valid', false)

      if (invalidPrepayment || invalidPhone || invalidEmail) {
        return false
      }

      this.loading = true

      const payload = {
        id: uuidv4(),
        relationId: this.item.relation_id,
        payments: this.paymentsPayload,
        rounding: true,
        footer: null
      }

      if (this.comment && this.$refs.commentValidation) {
        const comment = DOMPurify.sanitize(this.comment)
        payload.footer = comment.replace(/\\n/g, '\n')
      }
      if (this.email && this.$refs.emailValidation) {
        payload.delivery = { email: this.email }
      }

      if (!payload.payments.length) {
        this.loading = false
        this.$notification.info('Спосіб оплати не обрано. Оберіть спосіб оплати')
        return
      }

      let paymentVal = 0
      payload.payments.forEach(val => (paymentVal += val.value))
      let prepaymentSum

      if (this.prepayment) {
        prepaymentSum = Math.round(this.prepayment * 100)
      }

      await this.checkSignAgentHealth()

      try {
        const paymentType = this._.get(this.$refs, 'paymentTypeBlock.paymentType', null)
        const ettnPaymentTypeValue = this._.get(this.paymentTypes, 'ettn.value')
        const otherPaymentTypeValue = this._.get(this.paymentTypes, 'other.value')
        const monobankPaymentTypeValue = this._.get(this.paymentTypes, 'monobank.value')
        const cardInPayments = this._.find(this.paymentsPayload, i => i?.type === this.paymentTypes.card.value)
        const ibanInPayments = this._.find(this.paymentsPayload, i => i?.id === 'iban')
        const cardInMixedPayment = paymentType === this.paymentTypes.other.value && cardInPayments
        const ibanInMixedPayment = paymentType === otherPaymentTypeValue && ibanInPayments
        let posTerminalSettings = null

        if (paymentType === this.paymentTypes.card.value || cardInMixedPayment) {
          const cashRegisterPosTerminalSettings = await PosTerminals.api().filter({ 'cashRegister.id': this._.get(this.authenticatedCashier, 'cashRegister.id') }).all()
          posTerminalSettings = this._.get(cashRegisterPosTerminalSettings, 'response.data.data[0]')

          await this.showTerminalUsageInfoModal(posTerminalSettings)
        }

        if ((paymentType === this.paymentTypes.card.value || cardInMixedPayment) && this._.get(posTerminalSettings, 'usage', false)) {
          const amount = cardInMixedPayment ? this._.get(cardInPayments, 'value') : Math.round(parseFloat((this.prepayment * 100).toFixed(2)))
          payload.isPostpaymentReceipt = true
          await this.processPosTerminalPayment({
            payload,
            posTerminalSettings,
            terminalPayload: {
              amount
            },
            isInsidePrepaymentModal: true
          })
        } else if (paymentType === monobankPaymentTypeValue) {
          payload.payments[0].value = prepaymentSum

          await this.processAcquiringOrder({
            receiptBody: payload,
            paymentType
          })
        } else if (paymentType === ettnPaymentTypeValue) {
          const ettnPaymentIndex = this._.findIndex(payload.payments, i => i.type === this.paymentTypes.ettn.value)

          if (this.phone) {
            if (!payload.delivery) {
              payload.delivery = {}
            }

            if (this.ettnSmsEnabled) {
              payload.delivery.phone = this.phone.replace('+', '').replace(/-/g, '')
            } else {
              delete payload.delivery.phone
            }
          }

          payload.payments[ettnPaymentIndex].value = prepaymentSum
          await this.processEttnOrder(payload)
        } else {
          if (paymentType === this.paymentTypes.cash.value && prepaymentSum >= this.cashPaymentSumLimit && prepaymentSum < this.paymentSumLimit) {
            const confirm = await this.confirmationDialog.open({
              text: this.cashPaymentLimitText,
              width: '600px',
              buttonText: {
                approve: 'Yes',
                dismiss: 'No'
              }
            })
            if (!confirm) {
              return
            }
          }

          if (ibanInMixedPayment || ibanInPayments) {
            await Iban.api().all()
            let isRedirectedToAcquiringPage
            let totalSum
            // TODO: rethink this!
            if (ibanInPayments && !ibanInPayments.value && payload.payments.length === 1) {
              totalSum = this.item.left_to_pay
            } else {
              totalSum = ibanInPayments.value
            }
            if (ibanInPayments.value > this.item.left_to_pay) {
              this.loading = false
              this.$notification.info('Сума післясплати більша, ніж сума післясплати за товар. Відредагуйте післясплату')
              return false
            }
            if (Iban.exists()) {
              const isPayerByIban = await this.overTopContentDialog.open({
                width: '900px',
                component: 'block-iban-payment',
                contentNoGutters: true,
                componentProps: {
                  totalSum
                }
              })
              if (!isPayerByIban) {
                return
              }
            } else {
              isRedirectedToAcquiringPage = await this.showIbanUsageInfoModal()
            }
            if (isRedirectedToAcquiringPage) {
              return
            }
          }

          const preview = await this.createReceiptCompletely({
            token: this.token,
            payload,
            receiptViewType: 'text',
            isPostpaymentReceipt: true
          })
          await this.checkShiftForCashier(this.authenticatedCashier)
          this.$attrs.closeModal(preview)
        }

        this.$attrs.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-prepayment-payment {
  &__label {
    color: #808080;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
  &__info {
    padding: 8px 16px 16px 16px;
    border-radius: 16px;
    background: #F8F8F8;
  }
  &__relation-id {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &__created-at {
    color: #808080;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
  &__total {
    color: #161B25;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  &__input {
    border-radius: 12px;
  }
}
</style>
