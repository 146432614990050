import Prepayment from '~/modules/prepayment/models/Prepayment'
import Receipts from '~/modules/receipt/models/Receipts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import _commonData from '~/modules/receipt/mixins/getters/commonData'
import converters from '~/mixins/methods/converters'
import receiptDialogs from '~/modules/receipt/mixins/receiptDialogs'
import contentDialogOverTop from '~/mixins/dialogs/contentDialogOverTop'
import wait from '~/mixins/methods/wait'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

const processingReceipt = {
  mixins: [_commonData, converters, receiptDialogs, contentDialogOverTop, wait, processingApiRequest],
  methods: {
    createPosTerminalPaymentDialogConfig (componentProps) {
      return {
        component: 'block-pos-terminal-payment-process',
        width: '500px',
        componentProps
      }
    },
    async processPosTerminalPayment ({ payload, posTerminalSettings, isReturn = false, terminalPayload, onError, isInsidePrepaymentModal = false }) {
      let res
      const posTerminalPaymentDialogConfig = this.createPosTerminalPaymentDialogConfig({
        receiptPayload: payload,
        posTerminalSettings,
        terminalPayload,
        isReturn,
        onError
      })
      if (isInsidePrepaymentModal) {
        res = await this.contentDialogOverTop.open(posTerminalPaymentDialogConfig)
      } else {
        res = await this.contentDialog.open(posTerminalPaymentDialogConfig)
      }
      if (!res) {
        return
      }
      await this.wait(500)
      await this.openReceiptDialog({
        isInsidePrepaymentModal,
        view: this._.get(res, 'receiptHtml'),
        item: this._.get(res, 'receipt'),
        config: {
          posTerminalSettings
        }
      })
      return res
    },

    // top level methods
    async createServiceReceipt (accessToken, amount) {
      const payload = {
        payment: {
          type: 'CASH',
          value: amount,
          label: 'Готівка'
        }
      }
      if (this.authenticatedCashier?.maybeStartSignAgent) {
        await this.authenticatedCashier.maybeStartSignAgent()
      }
      return await this.processingApiRequest({
        request: token => Receipts.api().processingCreateService(payload, token || accessToken)
      })
    },

    async createReceiptCompletely ({ token, payload, receiptViewType, onError, isPrepaymentReceipt = false, isPostpaymentReceipt = false }) {
      if (receiptViewType !== 'htmlAndId' && this.authenticatedCashier?.maybeStartSignAgent) {
        await this.authenticatedCashier.maybeStartSignAgent()
      }
      let receiptId

      if (isPrepaymentReceipt) {
        receiptId = this._.get(await this.createPrepaymentReceipt(token, payload), 'response.data.id')
      } else if (isPostpaymentReceipt) {
        receiptId = this._.get(await this.createPostpaymentReceipt(token, payload.relationId, payload), 'response.data.id')
      } else {
        receiptId = this._.get(await this.createReceipt(token, payload), 'response.data.id')
      }

      const intervalRequest = new IntervalRequest(() => this.readReceipt(token, receiptId))
      const resolveCondition = response => response.response.data.status === Receipts.processingStatuses.DONE || response.response.data.status === Receipts.processingStatuses.ERROR
      // const rejectCondition = response => response.response.data.status === Receipts.processingStatuses.ERROR
      const resultReceipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
      const qrCodeUrl = this._.get(resultReceipt, 'tax_url', null)
      if (resultReceipt.transaction.status === 'ERROR' && resultReceipt.transaction.response_error_message) {
        if (typeof onError === 'function') {
          await onError(resultReceipt)
        }
        throw new Error(this.$t('Error when get receipt:') + ' ' + resultReceipt.transaction.response_error_message)
      }
      switch (receiptViewType) {
        case 'html': {
          return await this.createHtmlReceiptPreview(receiptId)
        }
        case 'img' : {
          return await this.createImageReceiptPreview(receiptId)
        }
        case 'text' : {
          return await this.createTextReceiptPreview(receiptId, qrCodeUrl)
        }
        case 'htmlAndId': {
          const receiptHtml = await this.createHtmlReceiptPreview(receiptId)
          return { receiptHtml, resultReceipt }
        }
        default : {
          return resultReceipt
        }
      }
    },

    async createTextReceiptPreview (receiptId, qrCodeUrl) {
      const preview = this._.get(await Receipts.api().processingReadText(receiptId), 'response.data')
      const qrCode = this._.get(await Receipts.api().processingReadQrCode(receiptId), 'response.data', '')
      return {
        receipt: preview,
        qrCode: await this.blobToBase64(qrCode),
        qrCodeUrl,
        receiptId
      }
    },

    async createHtmlReceiptPreview (receiptId) {
      return this._.get(await Receipts.api().processingReadHtml(receiptId), 'response.data')
    },

    async createImageReceiptPreview (receiptId) {
      const res = this._.get(await Receipts.api().processingReadPng(receiptId), 'response.data')
      return {
        imgSrc: await this.blobToBase64(res)
      }
    },

    // low level methods
    async createReceipt (accessToken, payload) {
      return await this.processingApiRequest({
        request: token => Receipts.api().processingCreate(payload, token || accessToken)
      })
    },
    async readReceipt (accessToken, id) {
      return await this.processingApiRequest({
        request: token => Receipts.api().processingRead(id, token || accessToken)
      })
    },
    async createPrepaymentReceipt (accessToken, payload) {
      return await this.processingApiRequest({
        request: token => Prepayment.api().createPrepaymentReceipt(payload, token || accessToken)
      })
    },
    async createPostpaymentReceipt (accessToken, relationId, payload) {
      return await this.processingApiRequest({
        request: token => Prepayment.api().createPostpaymentReceipt(relationId, payload, token || accessToken)
      })
    }
  }

}

export default processingReceipt
