<template lang="pug">
  div(class="dialog")
    div(class="dialog__content-flex")
      div(class="dialog__left")
        h3(class="dialog__title") {{ $t('Add NP token') }}
        div(
          v-if="xsDevice"
          class="mb-6"
        )
          m-block-set-ettn-settings-hint(mobile)
        ValidationObserver(ref="validator"  slim)
          v-form(@submit.prevent.stop="saveToken")
            ValidationProvider(
              mode="passive"
              :name="$t('NP token')"
              vid="token"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="token"
                outlined=true
                :placeholder="$t('NP token')"
                :error-messages="errors"
              )
            v-btn(
              class="main-button"
              type="submit"
              :loading="loading"
            ) {{ $t('Save') }}
      div(
        v-if="!xsDevice"
        class="dialog__right"
      )
        m-block-set-ettn-settings-hint
</template>

<script>
import CashRegisters from '~/models/directories/CashRegisters'
import MBlockSetEttnSettingsHint from '~/components/modules/blocks/concrete/ettn/m-block-set-ettn-settings-hint'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    MBlockSetEttnSettingsHint
  },
  mixins: [responsive],
  props: {
    cashRegisterId: {
      type: String,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    token: null
  }),
  computed: {
    cashRegister () {
      return CashRegisters.query().whereId(this.cashRegisterId).first()
    }
  },
  created () {
    this.token = this._.get(this.cashRegister, 'ettnSettings.token', null)
  },
  methods: {
    async saveToken () {
      const valid = await this.$refs.validator.validate()
      if (!valid) { return null }

      const payload = {
        ettn: {
          token: this.token || null
        }
      }

      try {
        this.loading = true
        await CashRegisters.api().updateSettings(this.cashRegister, payload)
        this.$notification.success(this.$t('NP token was saved'))
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this.$refs.validator)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
    padding: 0;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }

  .main-button {
    margin: 12px 0 0 auto;
    width: 100%;
    max-width: 180px;
    display: block;
  }
}
</style>
