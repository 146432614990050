<template lang="pug">
  div(
    class="hints-block-content"
    :class="{ 'hints-block-content--mobile': mobile }"
  )
    div(
      v-if="contentHtml"
      v-html="contentHtml"
    )
    div(v-else-if="currentStep === steps.options")
      p Підготували запит в ДПС, потрібно його підписати ключем ЕЦП
      p Ви можете зробити це декількома способами: за допомогою файлового ключа ЕЦП або через SmartID
      p Для подачі документів у ДПС необхідно використовувати ЕЦП “ключ першого документа”
      p
        b(class="mr-1") Ключ першого документа –
        | це ЕЦП ключ, яким був підписаний перший документ (заява, запит, звіт тощо) у ДПС
    div(v-else-if="currentStep === steps.fileSign")
      p {{ $t('signPlugin.edsWindow') }}
      p Для подачі документів у ДПС необхідно використовувати ЕЦП “ключ першого документа”
      p
        b(class="mr-1") Ключ першого документа –
        | це ЕЦП ключ, яким був підписаний перший документ (заява, запит, звіт тощо) у ДПС
    div(v-else-if="currentStep === steps.smartIdRead || currentStep === steps.smartIdSign")
      p Натисність або зчитайте QR-код сканером у застосунку Приват24 та дотримуйтесь інструкцій
      p Зверніть увагу, що сканування QR-коду відбувається декілька разів
      p Для подачі документів у ДПС необхідно використовувати ЕЦП “ключ першого документа”
      p
        b(class="mr-1") Ключ першого документа –
        | це ЕЦП ключ, яким був підписаний перший документ (заява, запит, звіт тощо) у ДПС
    div(v-else-if="currentStep === steps.diiaRead || currentStep === steps.diiaSign")
      p Зчитайте QR-код сканером у застосунку Дія й дотримуйтесь інструкцій
      p Зверніть увагу, що сканування QR-коду відбувається декілька разів
</template>

<script>
export default {
  name: 'BlockEdsIitCheckboxHintContent',
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    contentHtml () {
      let step = this.currentStep
      if (this.currentStep === this.steps.smartIdRead || this.currentStep === this.steps.smartIdSign) {
        step = 'smartId'
      }
      return this._.get(this.content, step, null)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
