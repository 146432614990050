import { Model } from '@vuex-orm/core'
import moment from 'moment-timezone'
import { formats } from '~/const/global'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import Directory from '~/models/abstracts/Directory'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export default class Refunds extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'refunds'
  static paginated = true
  static defaultSortParam = 'requestDate'

  static ormTrans = {
    single: 'Refunds',
    multy: 'Refunds'
  }

  static STATUSES = {
    new: 'new',
    signaturePending: 'signature_pending',
    signed: 'signed',
    approved: 'approved',
    declined: 'declined',
    error: 'error',
    successfullyPaid: 'successfully_paid'
  }

  static statuses = {
    new: {
      text: 'створено',
      value: 'new'
    },
    signaturePending: {
      text: 'очікує підпис',
      value: 'signature_pending'
    },
    signed: {
      text: 'підписано',
      value: 'signed'
    },
    approved: {
      text: 'схвалено',
      value: 'approved'
    },
    declined: {
      text: 'відхилено',
      value: 'declined'
    },
    error: {
      text: 'помилка',
      value: 'error'
    },
    successfullyPaid: {
      text: 'повернено',
      value: 'successfully_paid'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      number: this.attr(null),
      organization: this.attr(null),
      paymentDate: this.attr(null),
      requestDate: this.attr(null),
      requestStatus: this.attr(null),
      sum: this.attr(null),
      user: this.attr(null),
      comment: this.attr(null)
    }
  }

  get requestDateString () {
    return moment(this.requestDate).format(formats.date)
  }

  get paymentDateString () {
    return this.paymentDate ? moment(this.paymentDate).format(formats.date) : ''
  }

  get statusString () {
    if (this.requestStatus === Refunds.STATUSES.new) {
      return 'Created '
    } else if (this.requestStatus === Refunds.STATUSES.signaturePending) {
      return 'Waiting for signature'
    } else if (this.requestStatus === Refunds.STATUSES.signed) {
      return 'Signed'
    } else if (this.requestStatus === Refunds.STATUSES.approved) {
      return 'Approved'
    } else if (this.requestStatus === Refunds.STATUSES.declined) {
      return 'Declined'
    } else if (this.requestStatus === Refunds.STATUSES.successfullyPaid) {
      return 'Successfully paid'
    } else {
      return 'Error'
    }
  }

  get userString () {
    if (this.user) {
      const { profile, login } = this.user
      return `${profile?.firstName} ${profile?.lastName} (${login})`
    }
    return '–'
  }

  get sumString () {
    return ((this.sum || 0) / 100).toFixed(2)
  }

  static ormHeaders = [
    { text: 'Status', value: 'statusString', sortable: false, filterable: false },
    { text: 'organization', value: 'organization.name', sortable: false, filterable: false },
    { text: 'Number', value: 'number', sortable: false, filterable: false },
    { text: 'Request date', value: 'requestDateString', sortable: true, filterable: true, sortQuery: 'requestDate' },
    { text: 'Payment date ', value: 'paymentDateString', sortable: false, filterable: false },
    { text: 'Comment', value: 'comment', sortable: false, filterable: false },
    { text: 'User', value: 'userString', sortable: false, filterable: false },
    { text: 'Sum', value: 'sumString', align: 'right', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'requestStatus',
      component: 'v-select',
      attrs: {
        outlined: true,
        'hide-details': true,
        itemText: 'text',
        placeholder: 'Status'
      },
      items: () => [{ text: 'Всі', value: '' }, ...Object.values(this.statuses)]
    },
    {
      model: 'requestDate',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      attrs: {
        closeOnClick: false,
        clearable: false,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Request date'
      }
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4
              },
              fields: [
                'requestStatus'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4
              },
              fields: [
                'requestDate'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'pdf',
      text: 'Read document',
      icon: {
        type: 'e-svg-icon',
        text: 'read'
      },
      visible: item => item.requestStatus !== Refunds.STATUSES.new && item.requestStatus !== Refunds.STATUSES.signaturePending,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          width: '800px',
          component: 'block-application-pdf-view',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'downloadDocument',
      text: 'Download PDF',
      icon: {
        type: 'e-svg-icon',
        text: 'pdf'
      },
      visible: item => item.requestStatus !== Refunds.STATUSES.new && item.requestStatus !== Refunds.STATUSES.signaturePending,
      call: async (item) => {
        if (item && item.id) {
          const res = await Refunds.api().get(Refunds.$routes[Refunds.entity].exportDocument(item.id), {
            save: false,
            responseType: 'blob'
          })
          const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
          window.open(URL.createObjectURL(blob), '_blank')
        }
      }
    },
    {
      name: 'approve',
      text: 'Approve',
      icon: {
        type: 'e-svg-icon',
        text: 'done'
      },
      visible: item => item.requestStatus === Refunds.STATUSES.signed,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Are you sure you want to confirm your refund request?',
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Refunds.api().approveRequest(item.id)
              AppNotifications.success('The refund request has been confirmed')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    },
    {
      name: 'decline',
      text: 'Dismiss',
      icon: {
        type: 'e-svg-icon',
        text: 'decline-3'
      },
      visible: item => item.requestStatus === Refunds.STATUSES.signed,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Are you sure you want to decline your refund request?',
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Refunds.api().declineRequest(item.id)
              AppNotifications.success('Refund request rejected')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    },
    {
      name: 'delete',
      visible: item => item.requestStatus === Refunds.STATUSES.new || item.requestStatus === Refunds.STATUSES.signaturePending,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Are you sure you want to delete your refund request?',
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Refunds.api().del(item)
              AppNotifications.success('Refund request deleted')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getStatus = function () {
        return this.get(Model.$routes.refunds.status())
      }
      configActions.getFile = function (id) {
        return this.get(Model.$routes.refunds.getFile(id), { save: false })
      }
      configActions.getSignedFile = function (id) {
        return this.get(Model.$routes.refunds.getSignedFile(id), { save: false })
      }
      configActions.sendFile = function (id, payload) {
        return this.put(Model.$routes.refunds.sendFile(id), payload, { save: false })
      }
      configActions.approveRequest = function (id, payload = {}) {
        return this.put(Model.$routes.refunds.approveRequest(id), payload)
      }
      configActions.declineRequest = function (id, payload = {}) {
        return this.put(Model.$routes.refunds.declineRequest(id), payload)
      }

      return configActions
    }
  }
}
