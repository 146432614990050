<template lang="pug">
  div(
    class="hints-block-content"
    :class="{ 'hints-block-content--mobile': mobile }"
  )
    div
      p
        b 01
        | Перед початком налаштування необхідно обов'язково підписати договір із Nova Pay
      p
        b 02
        | Перейдіть в особистий кабінет Нової Пошти → Налаштування → Безпека та згенеруйте ключ API для інтеграцій → Скопіюйте створений ключ
      p
        b 03
        | Готово 🙌 Якщо все налаштовано коректно - в розділі “Продаж” з'явиться кнопка “Експрес накладні”
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/uk/portal/cash-registers/nova-poshta"
      ) Детальна інструкція →
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'MBlockSetEttnSettingsHintContent',
  components: {
    ELink
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.hints-block-content {
  p {
    display: flex;
    gap: 9px;
  }
}
</style>
