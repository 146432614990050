<template lang="pug">
  div(class="tap-to-phone-connecting-options")
    div(class="tap-to-phone-connecting-options__wrapper")
      h4(class="tap-to-phone-connecting-options__subtitle") {{ $t('Connect your Tap to Phone') }}
      p(class="tap-to-phone-connecting-options__text") {{ $t('Connect your Tap to Phone to Checkbox system') }}
      v-btn(
        :class="{'w-100': xsDevice }"
        color="black"
        dark
        @click="selectOption(false)"
      ) {{ $t('Connect') }}
    div(class="tap-to-phone-connecting-options__breaker")
      div(class="tap-to-phone-connecting-options__breaker-text") {{ _.upperFirst($t('or')) }}
    v-btn(
      class="tap-to-phone-connecting-options__btn secondary-button"
      outlined
      @click="selectOption(true)"
    )
      div(class="tap-to-phone-connecting-options__btn-beta-wrapper")
        div(class="tap-to-phone-connecting-options__btn-beta") beta
      div(class="d-flex align-center")
        div(class="tap-to-phone-connecting-options__btn-icon tap-to-phone-connecting-options__btn-icon--terminal mr-2")
          e-svg-icon terminal
        h5(class="tap-to-phone-connecting-options__btn-title") {{ $t('Register new Tap to Phone') }}
      div(class="tap-to-phone-connecting-options__btn-title") →
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockTapToPhoneConnectingOptions',
  components: {
    ESvgIcon
  },
  mixins: [responsive],
  methods: {
    selectOption (isNewOption) {
      this.$emit('nextStep', {
        prevStep: 'options',
        nextStep: 'bankSelection',
        isNewOption
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tap-to-phone-connecting-options {
  &__wrapper {
    padding: 16px;
    border-radius: 12px;
    background: #43CCB414;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #161B25;
    margin-bottom: 12px;
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #161B25BF;
    margin-bottom: 24px;
  }

  &__breaker {
    width: 100%;
    height: 1px;
    background: #00000014;
    margin: 24px 0;

    position: relative;

    &-text {
      padding: 0 12px;
      background: #FFFFFF;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #00000080;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &__btn {
    padding: 16px !important;
    border-radius: 12px;
    height: 64px !important;
    width: 100%;
    position: relative;

    &::v-deep {
      .v-btn__content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }
    }
  }

  &__btn-icon {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--terminal {
      svg {
        margin-right: -3px;
      }
    }
  }

  &__btn-title {
    font-size: 14px;
    font-weight: 400;
    color: #161B25;

    @media (max-width: map-get($breakpoints, 'sm')) {
      white-space: normal;
      text-align: left;
    }
  }

  &__btn-beta-wrapper {
    background: #FFFFFF;
    padding: 0 5px;

    position: absolute;
    left: -5px;
    top: -25px;
    z-index: 2;
  }

  &__btn-beta {
    padding: 4px 8px 4px 8px;
    border-radius: 7px;
    background: #FFAA01;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #FFFFFF;
  }
}
</style>
