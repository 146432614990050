<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ titleText }}
    v-form(@submit.prevent.stop="addOutlet")
      div()
        ValidationObserver(ref="form" slim)
          ValidationProvider(
            name="Оберіть торгову точку"
            vid="outlet"
            mode="lazy"
            rules="required"
            v-slot="{ errors }"
          )
            e-autocomplete(
              v-model="form.outlet"
              :item="outletAutocompleteItem"
              label="Оберіть торгову точку"
              @change="handleOutletChange"
              :error-messages="errors"
              no-data-text="Елементи не знайдені"
              outlined
              clearable
            )
          ValidationProvider(
            v-if="form.outlet"
            name="Назва торгової точки"
            vid="name"
            mode="lazy"
            rules="required"
            v-slot="{ errors }"
          )
            v-text-field(
              v-model="form.name"
              label="Назва торгової точки"
              :error-messages="errors"
              outlined
            )
            div(
              v-if="!(errors && errors.length)"
              class="input-hint"
            ) Ви можете змінити назву
          div(
            v-if="locationLoading"
            class="loader-container"
          )
            e-progress-circular(
              :width="3"
              size="lg"
            )
          div(
            v-else-if="location"
            class="map-container"
          )
            h4(class="map-container__title") {{ mapLabel }}
            div(class="map-container__gmap")
              GMap(
                ref="gMap"
                language="uk"
                :zoom="mapZoom"
                :center="location"
                :options="{ fullscreenControl: false, styles: mapStyle }"
                @click="handleMapClick"
                :key="mapKey"
              )
                GMapMarker(
                  :position="location"
                  ref="marker"
                )
          t-orm-fields(
            v-bind.sync="form"
            :items="secondPartFields"
          )
      div(class="dialog__actions text-center mt-3")
        v-btn(
          type="submit"
          class="main-button"
        ) {{ btnText }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Outlets from '~/models/directories/Outlets'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import WeAreWorking from '~/modules/we-are-working/models/WeAreWorking'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'

export default {
  name: 'BlockAddWorkingOutlet',
  components: {
    EAutocomplete,
    EProgressCircular,
    TOrmFields
  },
  mixins: [scrollToFailedValidation],
  props: {
    action: {
      type: String,
      default: 'add'
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    form: {
      id: null,
      lat: null,
      lng: null,
      name: null,
      address: null,
      category: null,
      description: null,
      isWorking: true,
      tel: null,
      siteUrl: null,
      instagramUrl: null,
      facebookUrl: null,
      outlet: null
    },
    mapKey: 0,
    mapStyle: [{ maxHeight: 200 }],
    locationLoading: false,
    mapZoom: 17,
    mapLabel: 'Ми визначили точку на карті автоматично, але ви можете змінити ії:'
  }),
  computed: {
    outletModel () {
      return Outlets
    },
    model () {
      return WeAreWorking
    },
    location () {
      const lat = this._.get(this.form, 'lat')
      const lng = this._.get(this.form, 'lng')
      return lat && lng ? { lat, lng } : null
    },
    titleText () {
      return this.action === 'edit' ? this.$t('Edit outlet') : this.$t('Add outlet')
    },
    btnText () {
      return this.action === 'edit' ? this.$t('Save') : this.$t('Add')
    },
    selectedItemsIds () {
      return this._.map(this.selectedItems, item => this._.get(item, 'outlet.id'))
    },
    outletCategories () {
      return [
        { value: 'Продукти', text: 'Продукти' },
        { value: 'Побутові товари', text: 'Побутові товари' },
        { value: 'Автомобілі', text: 'Автомобілі' },
        { value: 'Хвостикам', text: 'Хвостикам' },
        { value: 'Гроші', text: 'Гроші' },
        { value: 'Техніка', text: 'Техніка' },
        { value: 'Краса', text: 'Краса' },
        { value: 'Пошта', text: 'Пошта' },
        { value: 'Спорт', text: 'Спорт' },
        { value: 'Здоровʼя', text: 'Здоровʼя' },
        { value: 'Одяг, взуття', text: 'Одяг, взуття' },
        { value: 'Інше', text: 'Інше' }
      ]
    },
    outletAutocompleteItem () {
      return {
        attrs: {
          itemsFilter: items => this.action === 'add' ? this._.filter(items, item => !this.selectedItemsIds.includes(item.id)) : items,
          returnObject: true
        },
        searchModel: OutletsSearch,
        query: model => model.api().filter({ mode: this.outletModel.itemModes.checkbox.mode })
      }
    },
    secondPartFields () {
      return [
        {
          component: 'v-select',
          model: 'category',
          provider: {
            vid: 'category',
            name: 'Сфера діяльності',
            rules: 'required'
          },
          attrs: {
            label: 'Сфера діяльності',
            outlined: true,
            items: this.outletCategories
          }
        },
        {
          component: 'v-text-field',
          model: 'siteUrl',
          provider: {
            vid: 'siteUrl',
            name: 'Вебсайт',
            rules: 'url'
          },
          attrs: {
            label: 'Вебсайт',
            outlined: true,
            appendIcon: 'mdi-web'
          }
        },
        {
          component: 'v-text-field',
          model: 'instagramUrl',
          provider: {
            vid: 'instagramUrl',
            name: 'Інстаграм',
            rules: 'url'
          },
          attrs: {
            label: 'Інстаграм',
            outlined: true,
            appendIcon: 'mdi-instagram'
          }
        },
        {
          component: 'v-text-field',
          model: 'facebookUrl',
          provider: {
            vid: 'facebookUrl',
            name: 'Фейсбук',
            rules: 'url'
          },
          attrs: {
            label: 'Фейсбук',
            outlined: true,
            appendIcon: 'mdi-facebook'
          }
        },
        {
          component: 'v-textarea',
          model: 'description',
          provider: {
            vid: 'description',
            name: 'Короткий опис торгової точки'
          },
          attrs: {
            label: 'Короткий опис торгової точки',
            outlined: true,
            'auto-grow': true
          },
          hint: 'Наприклад, ресторан італійської кухні, магазин дитячих іграшок'
        }
      ]
    }
  },
  watch: {
    'form.outlet' (val) {
      this.form.name = this._.get(val, 'name')
      this.form.address = this._.get(val, 'address')
      this.form.id = this._.get(val, 'id')
    }
  },
  created () {
    if (this.item && this.action === 'edit') {
      this.$set(this.$data, 'form', {
        ...JSON.parse(JSON.stringify(this.item))
      })
    }
  },
  methods: {
    async handleOutletChange (outlet) {
      if (outlet) {
        await this.getOutletCoordinates(outlet)
      } else {
        this.$set(this.form, 'lat', null)
        this.$set(this.form, 'lng', null)
      }
    },
    async getOutletCoordinates (outlet) {
      try {
        this.locationLoading = true
        const res = await this.outletModel.api().getCoordinates(this._.get(outlet, 'id'))
        const location = this._.get(res, 'response.data.loc', '').split(',') || []
        const lat = location[0] && parseFloat(location[0])
        const lng = location[1] && parseFloat(location[1])
        if (lat && lng) {
          this.$set(this.form, 'lat', lat)
          this.$set(this.form, 'lng', lng)
        }
      } catch (e) {
        if (this._.get(e, 'response.status') === 404) {
          this.locationNotFound()
        }
        this.$handlers.error(e, this)
      } finally {
        this.locationLoading = false
      }
    },
    handleMapClick (e) {
      const lat = this._.get(e, 'event.latLng.lat')()
      const lng = this._.get(e, 'event.latLng.lng')()
      this.$set(this.form, 'lat', lat)
      this.$set(this.form, 'lng', lng)
      this.mapKey += 1
    },
    async addOutlet () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.scrollToFailedValidation(this.$refs.form.$el)
        return
      }
      const payload = {
        ...this.form,
        tel: this._.get(this.$Organization, 'owner.phone'),
        isWorking: true
      }
      await this.$attrs.closeModal(payload)
    },
    locationNotFound () {
      this.$set(this.form, 'lat', 50.4501)
      this.$set(this.form, 'lng', 30.5234)
      this.mapZoom = 14
      this.mapLabel = 'На жаль, ми не змогли автоматично визначити місцезнаходження вашої торгової точки. Оберіть, будь ласка, на карті:'
    }
  }
}
</script>

<style lang="scss" scoped>
.map-container {
  margin: 10px 0 30px;

  &__title {
    font-weight: 400;
    font-size: 0.825rem;
    margin: 0 0 10px;
  }

  &__gmap {
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 7px;
    overflow: hidden;

    &::v-deep {
      .GMap__Wrapper {
        height: 200px;
      }
    }
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
</style>
