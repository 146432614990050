<template lang="pug">
  div(class="dialog")
    div(
      v-if="loader"
      class="dialog__loader"
    )
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
    div(v-else)
      div(class="dialog__banner") {{ $t('Action "Replacements of RRO"') }}
      v-img(
        class="dialog__img"
        :src="require(`~/assets/images/racoons/${racoonImage}.svg`)"
      )
      h3(
        v-if="title"
        class="dialog__title"
      ) {{ $t(title) }}

      div(v-if="hasNoOrganization")
        p(class="dialog__text")
          | Для того щоб прийняти участь в акції, вам необхідно вказати дані вашої організації. Після натискання кнопки
          b(class="mx-1") "Зареєструватися"
          | , ви відразу можете перейти у розділ організації та синхронізувати дані з ДПС.
        p(class="dialog__text") Якщо виникли питання чи необхідна допомога, звертайтеся до нашої служби підтримки, контакти якої зазначені на сайті Checkbox.
      div(v-else-if="hasNoRro")
        p(class="dialog__text") Але ви завжди можете перейти у розділ реєстрації програмних кас та підключити безкоштовний тестовий період на 1 місяць.
        p(class="dialog__text") Якщо виникли питання чи необхідна допомога, звертайтеся до нашої служби підтримки, контакти якої зазначені на сайті Checkbox.
      div(v-else)
        p(class="dialog__text")
          | Для того щоб взяти участи в акції, потрібно деактивувати старі РРО протягом
          b(class="mx-1") 1 місяця з дати реєстрації програмної каси.
          | Ми автоматично надамо можливість безкоштовно користуватися такою кілкістю програмних кас Checkbox, скільки було деактивовано РРО на строк до кінця їх терміну дії.
        p(class="dialog__text")
          | Ми знайшли в публічних реєстрах, що у вас є
          b(class="ml-1") {{ rroCount }} {{ rroCountText }} РРО:
          div(
            v-if="rrosData.length"
            class="dialog__rros-wrapper"
          )
            p(
              v-for="(item, index) in rrosData"
              :key="index"
            ) {{ item.rroCount }} – {{ item.rroCount === 1 ? 'дійсний' : 'дійсні' }} ще {{ item.rroMonth }} {{ monthsText(item.rroMonth) }}
        p(class="dialog__text")
          | Тобто ви можете користуватись безоплатним ПРРО Checkbox:
          b(class="ml-1") {{ freeMonthsCount }} {{ monthsText(freeMonthsCount) }}!
        p(class="dialog__text")
          | Після натискання кнопки
          b(class="mx-1") "Взяти участь"
          | , ви відразу можете перейти у розділ реєстрації програмних кас.

      div(class="dialog__actions")
        v-btn(
          v-if="!hasNoRro"
          class="secondary-button"
          @click="handleSkip"
          :loading="loading"
          black
          text
        ) {{ $t('Later') }}
        v-btn(
          class="main-button"
          @click="handleClick"
          :loading="loading"
        ) {{ $t(btnText) }}
</template>

<script>
import promoCashRegisters from '~/modules/promotion/mixins/promoCashRegisters'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Organization from '~/models/directories/Organization'

export default {
  name: 'BlockPromotionDialog',
  components: {
    EProgressCircular
  },
  mixins: [promoCashRegisters],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loader: false,
    loading: false,
    rroCount: 0,
    freeMonthsCount: 0,
    hasNoRro: false,
    rrosData: []
  }),
  computed: {
    racoonImage () {
      if (this.hasNoOrganization || this.hasNoRro) {
        return 'racoon-dont-know'
      }
      return 'racoon-rock'
    },
    title () {
      if (this.hasNoOrganization) {
        return 'Unfortunately, you are not yet registered in the system'
      } else if (this.hasNoRro) {
        return 'Unfortunately, the public registers do not contain information about your PRO.'
      }
      return 'Free for at least 7 years!'
    },
    btnText () {
      if (this.hasNoOrganization) {
        return 'To register'
      } else if (this.hasNoRro) {
        return 'Clear'
      }
      return 'Take part'
    },
    rroCountText () {
      const variant = this.getNumberVariant(this.rroCount)
      if (variant === 1) {
        return 'діючий'
      } else if (variant === 2) {
        return 'діючі'
      }
      return 'діючих'
    }
  },
  created () {
    if (!this.hasNoOrganization) {
      this.getClientRros()
    }
  },
  methods: {
    getNumberVariant (value) {
      const val = parseInt(value)
      const lastChar = val % 10
      if (lastChar === 1 && val !== 11) {
        return 1
      } else if (lastChar >= 2 && lastChar <= 4 && (val < 10 || val > 20)) {
        return 2
      }
      return 3
    },
    monthsText (val) {
      const variant = this.getNumberVariant(val)
      if (variant === 1) {
        return 'місяць'
      } else if (variant === 2) {
        return 'місяці'
      }
      return 'місяців'
    },
    handleSkip () {
      this.closeModal()
    },
    async getClientRros () {
      try {
        this.loader = true
        const res = this._.get(await Organization.api().getRro(), 'response.data')
        this.rroCount = this._.get(res, 'totalRroCount', 0)
        this.freeMonthsCount = this._.get(res, 'totalRroMonth', 0)
        this.rrosData = this._.get(res, 'data', [])
        this.hasNoRro = !this.rroCount
      } catch (e) {
        this.closeModal()
        this.$handlers.error(e, this)
      } finally {
        this.loader = false
      }
    },
    async handleClick () {
      if (this.hasNoOrganization) {
        this.$router.push('/dashboard/myorganization')
      } else if (this.hasNoRro) {
        this.$router.push('/dashboard')
      } else {
        try {
          this.loading = true
          await Organization.api().rroPromotionApply()
          this.sendGtmEvent(2)
          this.$notification.success('The application for participation in the promotion has been created')
          this.$router.push('/dashboard/cashregisters')
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__banner {
    border-radius: 6px;
    background: #000;
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    width: 100%;
    max-width: 156px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px;
  }

  &__text {
    color: rgba(0, 0, 0, 0.8);
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    & > .v-btn {
      width: 100%;
      min-height: 48px;

      @media (min-width: map-get($breakpoints, 'xs')) {
        width: calc(50% - 8px);
      }
    }
  }

  &__img {
    width: 100%;
    max-width: 138px;
    margin: 0 auto 12px;
    display: block;
  }

  &__rros-wrapper {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    max-height: 110px;
    overflow-y: auto;

    p {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
