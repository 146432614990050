<template lang="pug">
  div(class="dialog")
    template(v-if="receiptLoading")
      div(class="d-flex justify-center align-center w-100 h-100")
        e-progress-circular(
          color="#161b25"
          size="xl"
          width="4"
        )
    template(v-else)
      div(class="dialog__content")
        div(
          class="dialog__preview"
          ref="preview-wrap"
        )
          iframe(
            ref="receiptDoc"
            class="d-none"
          )
          pre(ref="preview") {{ _.get(preview, 'receipt', null) || preview }}
          img(
            v-if="preview.qrCode"
            class="dialog__qr-code"
            :src="preview.qrCode"
            width="250px"
          )
      div(class="dialog__actions")
        div(class="pagination-wrapper")
          v-pagination(
            :length="receiptIds.length"
            :value="currentPage"
            @previous="onPrev"
            @next="onNext"
          )
          span(v-if="currentPage > 1" class="pagination-prev" @click="onPrev") {{ $t('Previous') }}
          span(class="pagination-counter") {{ currentPage }} - {{ totalPages }}
          span(v-if="currentPage < totalPages" class="pagination-next" @click="onNext") {{ $t('Next one') }}
        v-col(cols="12" class="receipt__buttons")
          e-link(v-if="isReceipt" @click="copyToClipboard")
            e-svg-icon(class="mr-2") receipt-link
            | {{ $t('Copy link') }}
          e-link(v-if="actionButton" @click="dialogAction" :loading="loading")
            e-svg-icon(class="mr-2") receipt-download
            | {{ $t(actionText) }}
          e-link(v-if="printButton" @click="print" :loading="loading")
            e-svg-icon(class="mr-2") printer-3
            | {{$t('Print')}}
</template>

<script>
import html2pdf from 'html2pdf.js'

import TDialog from '~/components/templates/t-dialog'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'

import clipboard from '~/mixins/global/_clipboard'
import converters from '~/mixins/methods/converters'
import contentDialog from '~/mixins/dialogs/contentDialog'
import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'

import Receipts from '~/modules/receipt/models/Receipts'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import processingShifts from '~/modules/receipt/mixins/actions/processingShifts'

export default {
  name: 'BlockReceiptsChainView',
  components: {
    EProgressCircular,
    TDialog,
    ESvgIcon,
    ELink
  },
  mixins: [
    clipboard,
    converters,
    contentDialog,
    processingReceipt,
    processingShifts
  ],
  props: {
    receiptIds: {
      type: Array,
      default: null
    },
    action: {
      type: String,
      default: 'download',
      validate: value => ['print', 'download', 'close'].includes(value)
    },
    actionText: {
      type: String,
      default: 'Download'
    },
    isReceipt: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: 'file'
    },
    printButton: {
      type: Boolean,
      default: true
    },
    actionButton: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: null
    },
    isReturn: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    receiptLoading: false,
    preview: null,
    currentPage: 1
  }),
  computed: {
    totalPages () {
      return this.receiptIds?.length
    }
  },
  created () {
    if (this.isReturn) {
      this.checkShiftForCashier(this.authenticatedCashier)
    }
    this.createReceiptPreview()
  },
  methods: {
    async createReceiptPreview () {
      try {
        this.receiptLoading = true
        this.preview = await this.createTextReceiptPreview(this.receiptIds[this.currentPage - 1])
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptLoading = false
      }
    },
    onNext () {
      if (this.currentPage >= this.receiptIds.length) {
        return
      }
      this.currentPage++
      this.createReceiptPreview()
    },
    onPrev () {
      if (this.currentPage <= 1) {
        return
      }
      this.currentPage--
      this.createReceiptPreview()
    },
    close () {
      const closeModal = this._.get(this.$attrs, 'closeModal')
      if (this._.isFunction(closeModal)) {
        closeModal()
      }
    },
    dialogAction () {
      switch (this.action) {
        case 'print' : {
          return this.print()
        }
        case 'download' : {
          return this.downloadReceipt()
        }
        case 'close' : {
          return this.close()
        }
      }
    },
    async print () {
      if (this.isReceipt) {
        await this.printImg()
      } else {
        this.printText()
      }
    },
    printText () {
      const cw = this.$refs.receiptDoc.contentWindow
      cw.document.open()
      cw.document.write('<pre>')
      cw.document.write(this._.get(this.preview, 'receipt', null) || this.preview)
      cw.document.write('</pre>')

      if (this._.get(this.preview, 'qrCode', null)) {
        const wrapperWidth = this._.get(this.$Organization, 'receiptSetting.width', 32) * 8
        cw.document.write('<div style="width:' + wrapperWidth + 'px; text-align: center; margin: 0; padding: 0;">')
        cw.document.write(`<img src="${this.preview.qrCode}" style="width: 100%; max-width: 250px; display: block; margin: 0 auto;">`)
        cw.document.write('</div>')
      }

      cw.document.close()
      setTimeout(() => {
        cw.print()
      })
    },
    async printImg () {
      try {
        this.loading = true
        const res = this._.get(await Receipts.api().processingReadPng(this._.get(this.preview, 'receiptId', null)), 'response.data', '')
        const receiptUrl = await this.blobToBase64(res)
        const cw = this.$refs.receiptDoc.contentWindow
        const paperWidth = this._.get(this.$Organization, 'receiptSetting.paperWidth') || 58
        const imgWidth = (paperWidth * 3.78).toFixed(2) + 'px'
        cw.document.open()
        cw.document.write(`<img src="${receiptUrl}" alt="Receipt" style="width: ${imgWidth};">`)
        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No check view was found, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.loading = false
      }
    },
    async downloadReceipt () {
      try {
        const name = `${this.fileName}.pdf`
        this.loading = true

        await html2pdf().set({
          margin: 3,
          filename: name
        }).from(this.$refs.preview).save()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    copyToClipboard () {
      try {
        this.copy('https://check.checkbox.ua/' + this.preview.receiptId)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 15px 0 0;
  margin: 0 -15px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    margin: 0 -20px;
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    margin: 0 -32px;
  }

  &__content {
    overflow-y: auto;
    padding: 0 15px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 0 20px;
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      padding: 0 32px;
    }
  }

  &__preview {
    height: 400px;
    width: 100%;
    max-width: 370px;
    margin: 0 auto;

    pre {
      display: flex;
      justify-content: center;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;
    }
  }

  &__qr-code {
    display: block;
    margin: 0 auto;
  }

  &__actions {
    padding: 0 0 10px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 0 5px 10px;
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      padding: 0 15px 10px;
    }
  }
}
.pagination-wrapper {
  position: relative;
  text-align: center;
}
.pagination-prev {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-next {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-counter {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 5;
}
.v-pagination::v-deep {
  justify-content: space-between !important;

  .v-pagination__item {
    display: none;
  }
  .v-pagination__navigation {
    background: none;
    box-shadow: none;
  }

  .v-pagination__more {
    display: none!important;
  }
}
</style>
