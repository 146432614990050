<template lang="pug">
  div()
    block-epz-data-form(
      v-if="showEpzDataForm"
      btn-text="Fiscalize"
      title="Enter the data of the EPZ manually"
      :close-modal="handleFiscalize"
    )
    div(
      class="dialog"
      v-else
    )
      div(
        v-if="paymentPending"
        class="dialog__title text-center"
      ) {{ isReturn ? 'Повернення' : 'Оплата' }} через POS-термінал
      div(class="dialog__content")
        v-row(class="mt-2" justify="center" v-if="paymentPending")
          v-col(cols="12")
            p(class="mb-4") Очікується {{ isReturn ? 'повернення' : 'оплата' }}... Слідкуйте за підказками на терміналі
            e-progress-linear(size="md")

        v-row(class="mt-2" justify="center" v-else-if="paymentError")
          v-col(cols="auto")
            e-svg-icon(name="warning-2" size="xbg")
          v-col(cols="12")
            p(class="text-center mb-0") Помилка при {{ isReturn ? 'поверненні' : 'оплаті' }}:
            p(class="text-center mt-2 mb-0 color-error") {{ errorText || 'Перевірте налаштування термінала' }}

        v-row(class="mt-2" justify="center" v-else-if="paymentSuccess")
          v-col(cols="auto")
            e-svg-icon(name="money" size="xbg")
          v-col(cols="12")
            p(class="text-center") Оплата успішна

        v-row(class="mt-2 mb-4" justify="center" v-else-if="receiptPending")
          v-col(cols="auto")
            e-svg-icon(name="receipt" size="xbg")
          v-col(cols="12")
            p(class="text-center mb-4") Видача фіскального чеку
            e-progress-linear(size="md")

        v-row(class="mt-2" justify="center" v-else-if="receiptError")
          v-col(cols="auto")
            e-svg-icon(name="warning-2" size="xbg")
          v-col(cols="12")
            p(class="text-center mb-0") Помилка при видачі чеку!

        v-row(class="mt-2 mb-5" justify="center" v-else-if="receiptSuccess")
          v-col(cols="auto")
            e-svg-icon(name="done-all" size="xbg")
          v-col(cols="12")
            p(class="text-center mb-0") Фіскальний чек успішно створений
      div(
        v-if="paymentError || receiptError || (showEpzDataFormBtn && paymentPending)"
        class="dialog__actions pt-5"
      )
        v-btn(
          v-if="paymentError || receiptError"
          @click="close"
          class="secondary-button color-error"
          outlined
        ) {{ $t('Close') }}
        v-btn(
          v-if="paymentError || (showEpzDataFormBtn && paymentPending)"
          @click="handleAddEpzData"
          class="secondary-button"
          outlined
        ) {{ $t('Enter the data of the EPZ manually') }}
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Receipts from '~/modules/receipt/models/Receipts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import converters from '~/mixins/methods/converters'
import authData from '~/modules/receipt/mixins/getters/authData'
import wait from '~/mixins/methods/wait'
import Prepayment from '~/modules/prepayment/models/Prepayment'
import BlockEpzDataForm from '~/modules/receipt/views/components/dialogs/block-epz-data-form'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'
import posTerminalProcesses from '~/modules/receipt/mixins/posTerminalProcesses'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'

const workflow = {
  paymentPending: 'paymentPending',
  paymentError: 'paymentError',
  paymentSuccess: 'paymentSuccess',
  receiptPending: 'receiptPending',
  receiptError: 'receiptError',
  receiptSuccess: 'receiptSuccess'
}

export default {
  name: 'BlockPosTerminalPaymentProcess',
  components: {
    BlockEpzDataForm,
    EProgressLinear,
    ESvgIcon
  },
  mixins: [converters, authData, wait, processingApiRequest, posTerminalProcesses],
  props: {
    receiptPayload: {
      type: Object,
      required: true
    },
    posTerminalSettings: {
      type: Object,
      required: true
    },
    terminalPayload: {
      type: Object,
      required: true
    },
    isReturn: {
      type: Boolean,
      default: false
    },
    onError: {
      type: Function,
      default: null
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    state: workflow.paymentPending,
    socket: null,
    errorText: null,
    showEpzDataForm: false,
    showEpzDataFormBtn: false,
    requestId: null,
    timeout: null
  }),
  computed: {
    paymentPending () {
      return this.state === workflow.paymentPending
    },
    paymentError () {
      return this.state === workflow.paymentError
    },
    paymentSuccess () {
      return this.state === workflow.paymentSuccess
    },
    receiptPending () {
      return this.state === workflow.receiptPending
    },
    receiptError () {
      return this.state === workflow.receiptError
    },
    receiptSuccess () {
      return this.state === workflow.receiptSuccess
    }
  },
  created () {
    this.requestId = uuidv4()
    this.connectToTerminal()
    this.$root.$on('payLinkSocketEvent', this.processPayLinkSocketEvent)
  },
  beforeDestroy () {
    this.requestId = null
    this.closeSocket()
    this.$root.$off('payLinkSocketEvent', this.processPayLinkSocketEvent)
    clearTimeout(this.timeout)
  },
  methods: {
    close () {
      this.closeModal(false)
    },
    closeSocket () {
      if (this.socket) {
        this.socket.close()
      }
    },
    logToSentry ({ name, payload = {} } = {}) {
      this.$sentry.captureException(new Error(name), {
        user: {
          id: this._.get(this.$User, 'id', null),
          email: this._.get(this.$User, 'email', null)
        },
        contexts: {
          payload: {
            name,
            organization: this._.get(this.$Organization, 'id'),
            cashRegister: this._.get(this.posTerminalSettings, 'cashRegister.id'),
            ...(payload || {})
          }
        },
        tags: {
          name
        }
      })
    },
    async handleOnError (params) {
      this.logToSentry(params)
      if (this._.isFunction(this.onError)) {
        await this.onError()
      }
    },
    async processCreateReceipt (payload, paymentSuccess = true) {
      try {
        if (paymentSuccess) {
          this.state = workflow.paymentSuccess
          await this.wait(2000)
        }

        this.state = workflow.receiptPending

        this.logToSentry({
          name: 'terminalReceiptPending',
          payload: {
            receiptPayload: payload
          }
        })

        let receiptId

        if (payload.isPrepaymentReceipt) {
          receiptId = this._.get(await this.processingApiRequest({
            request: token => Prepayment.api().createPrepaymentReceipt(payload, token || this.token)
          }), 'response.data.id')
        } else if (payload.isPostpaymentReceipt) {
          receiptId = this._.get(await this.processingApiRequest({
            request: token => Prepayment.api().createPostpaymentReceipt(payload.relationId, payload, token || this.token)
          }), 'response.data.id')
        } else {
          receiptId = this._.get(await this.processingApiRequest({
            request: token => Receipts.api().processingCreate(payload, token || this.token, !this.isReturn)
          }), 'response.data.id')
        }

        this.logToSentry({
          name: 'terminalReceiptCreated',
          payload: {
            receiptId
          }
        })

        const intervalRequest = new IntervalRequest(async () => {
          return await this.processingApiRequest({
            request: token => Receipts.api().processingRead(receiptId, token || this.token)
          })
        })
        const resolveCondition = res => res.response.data.status === Receipts.processingStatuses.DONE || res.response.data.status === Receipts.processingStatuses.ERROR
        const receipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')

        this.logToSentry({
          name: 'terminalReceiptResolved',
          payload: {
            receipt,
            receiptId
          }
        })

        if (receipt.status === Receipts.processingStatuses.DONE) {
          const receiptId = receipt.id
          const receiptHtml = this._.get(await Receipts.api().processingReadHtml(receiptId), 'response.data')
          this.state = workflow.receiptSuccess
          const printReceiptOnAndroidTerminal = this._.get(this.posTerminalSettings, 'printReceiptOnAndroidTerminal', false)
          const displayReceiptOnAndroidTerminal = this._.get(this.posTerminalSettings, 'displayReceiptOnAndroidTerminal', false)
          let posTerminalPayload = {}

          if (printReceiptOnAndroidTerminal || displayReceiptOnAndroidTerminal) {
            const receiptText = this._.get(await Receipts.api().processingReadText(receiptId), 'response.data')
            posTerminalPayload = {
              posTerminalSettings: this.posTerminalSettings,
              payload: {
                qr_url: this._.get(receipt, 'tax_url', null),
                receipt: receiptText
              }
            }
          }

          if (printReceiptOnAndroidTerminal) {
            await this.makePosTerminalRequest({
              ...posTerminalPayload,
              operationType: 'print-receipt',
              url: `print-receipt/${this._.get(this.posTerminalSettings, 'terminalName', null)}`
            })
          }

          if (displayReceiptOnAndroidTerminal) {
            await this.makePosTerminalRequest({
              ...posTerminalPayload,
              operationType: 'display-receipt',
              url: `display-receipt/${this._.get(this.posTerminalSettings, 'terminalName', null)}`
            })
          }

          this.logToSentry({
            name: 'terminalReceiptSuccess',
            payload: {
              receiptHtml,
              receiptId
            }
          })
          await this.logPosTerminalAnalytics('success_pos_terminal_receipt_fiscalize')
          await this.wait(3000)
          this.closeModal({
            receiptHtml,
            receipt
          })
        } else {
          this.handleOnError({
            name: 'terminalReceiptError',
            payload: {
              receipt,
              receiptId
            }
          })
          this.state = workflow.receiptError
        }
      } catch (e) {
        this.state = workflow.receiptError
        this.$handlers.error(e, this)
      }
    },
    handleAddEpzData () {
      this.closeSocket()
      this.showEpzDataForm = true
    },
    async handleFiscalize (data) {
      const payload = JSON.parse(JSON.stringify(this.receiptPayload))
      payload.payments[0] = Object.assign(payload.payments[0], data)
      this.showEpzDataForm = false
      await this.processCreateReceipt(payload, false)
    },
    async processPayLinkSocketEvent (res) {
      try {
        let success = this._.get(res, 'success')
        const payLinkTerminalId = this._.get(this.posTerminalSettings, 'payLinkTerminalId')

        this.logToSentry({
          name: 'terminalSocketMessage',
          payload: { message: res }
        })

        if (res.sender && res.sender === 'proxy' && res.event === 'disconnected') {
          success = false
        } else if (res.operation_type !== 'cancel' && res.operation_type !== 'purchase') {
          return
        } else if (payLinkTerminalId !== res.terminal_id) {
          return
        } else if (res.request_id !== this.requestId) {
          return
        }

        if (success) {
          const payload = JSON.parse(JSON.stringify(this.receiptPayload))
          payload.payments[0].card_mask = this._.get(res, 'result.pan', '')
          payload.payments[0].auth_code = this._.get(res, 'result.auth_code', '')
          payload.payments[0].rrn = this._.get(res, 'result.rrn', '')
          payload.payments[0].payment_system = this._.get(res, 'result.issuer_name', '')
          payload.payments[0].owner_name = this._.get(res, 'result.card_holder', '')
          payload.payments[0].terminal = this._.get(res, 'result.terminal_id', '')
          payload.payments[0].bank_name = this._.get(res, 'result.bank_acquirer', '')
          payload.payments[0].receipt_no = this._.get(res, 'result.invoice_num', '')
          // payload.payments[0].provider_type = 'ANDROID'

          await this.processCreateReceipt(payload)
        } else {
          await this.handleOnError({
            name: 'terminalPaymentError',
            payload: res
          })
          this.errorText = this._.get(res, 'error', 'Зв\'язок з терміналом втрачено')
          this.state = workflow.paymentError
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    connectToTerminal () {
      const port = this._.get(this.posTerminalSettings, 'port', null)
      const platform = this._.get(this.posTerminalSettings, 'platform', null)
      const ipAddress = this._.get(this.posTerminalSettings, 'ipAddress', null)
      const websocketProtocol = (platform === PosTerminals.platforms.android.value) ? 'wss' : 'ws'
      const payLinkTerminalId = this._.get(this.posTerminalSettings, 'payLinkTerminalId')
      const deviceId = this._.get(this.posTerminalSettings, 'terminalName', null)
      const method = this.isReturn ? 'cancel' : 'purchase'

      if (deviceId && !payLinkTerminalId) {
        this.timeout = setTimeout(() => {
          this.showEpzDataFormBtn = true
        }, 2e4)

        const address = `${websocketProtocol}://${ipAddress}:${port}/api/pos/${deviceId}/${method}`
        this.socket = new WebSocket(address)
        this.socket.onopen = () => {
          this.socket.send(JSON.stringify(this.terminalPayload))
        }
        this.socket.onmessage = async (e) => {
          try {
            clearTimeout(this.timeout)
            const res = JSON.parse(this._.get(e, 'data'))
            const success = this._.get(res, 'success')

            this.logToSentry({
              name: 'terminalSocketMessage',
              payload: {
                message: res
              }
            })

            if (success) {
              const payload = JSON.parse(JSON.stringify(this.receiptPayload))
              payload.payments[0].card_mask = this._.get(res, 'result.pan', '')
              payload.payments[0].auth_code = this._.get(res, 'result.auth_code', '')
              payload.payments[0].rrn = this._.get(res, 'result.rrn', '')
              payload.payments[0].payment_system = this._.get(res, 'result.issuer_name', '')
              payload.payments[0].owner_name = this._.get(res, 'result.card_holder', '')
              payload.payments[0].terminal = this._.get(res, 'result.terminal_id', '')
              payload.payments[0].bank_name = this._.get(res, 'result.bank_acquirer', '')
              payload.payments[0].receipt_no = this._.get(res, 'result.invoice_num', '')

              if (platform === PosTerminals.platforms.android.value) {
                // payload.payments[0].provider_type = 'ANDROID'
              }

              await this.processCreateReceipt(payload)
            } else {
              this.handleOnError({
                name: 'terminalPaymentError',
                payload: {
                  address,
                  method,
                  error: e
                }
              })
              this.errorText = this._.get(res, 'error')
              this.state = workflow.paymentError
            }
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
        this.socket.onerror = (e) => {
          clearTimeout(this.timeout)
          this.handleOnError({
            name: 'terminalSocketError',
            payload: {
              address,
              method,
              error: e
            }
          })
          this.state = workflow.paymentError
        }
      }
      if (payLinkTerminalId) {
        this.sentEventToPayLinkSocket({
          operation_type: method,
          terminal_id: payLinkTerminalId,
          request_id: this.requestId,
          payload: this.terminalPayload
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;

    &::v-deep {
      .v-btn {
        width: 100%;

        @media (min-width: map-get($breakpoints, 'xs')) {
          max-width: 220px;
        }
      }
    }
  }
}
</style>
