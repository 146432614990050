<template lang="pug">
  div(class="dialog")
    p(class="dialog__text")
      span(class="mr-1") Для того, щоб Ви змогли спробувати сервіс до реєстрації каси, ми підготували для вас демо доступ робочого місця, щоб ви змогли оцінити роботу в
      b Checkbox
    p(class="dialog__text mt-4")
      b Для цього:
    ul
      li(v-if="!isAuthenticated") Авторизуйтесь тестовим касиром та касою
      li Додайте товар в чек
      li Натисніть "Видати чек"
    div(class="dialog__actions mt-4")
      v-btn(
        @click="handleSkip"
      ) Пропустити
      v-btn(
        class="main-button"
        @click="handleConfirm"
      ) Почати
</template>

<script>
export default {
  name: 'BlockCreateReceiptOnboarding',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    isAuthenticated: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleSkip () {
      this.$setLocalStorageClientData({ createReceiptOnBoardingDone: true })
      this.closeModal(false)
    },
    handleConfirm () {
      this.closeModal(true)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 40px 30px 10px;
  color: #161b25;

  &__text {
    margin: 0;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;

    &::v-deep {
      .v-btn {
        width: 100%;
        max-width: 180px;
      }
    }
  }
}
</style>
