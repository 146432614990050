<template lang="pug">
  v-row(no-gutters justify="center")
    v-col(cols="12" sm="8" :class="{ 'pa-8': !xsDevice, 'pa-4': xsDevice }")
      h2(class="m-block-receipt-sale-mixed-payment-types__title pb-3") {{ $t('Other payment type') }}
      v-simple-table(class="m-block-receipt-sale-mixed-payment-types")
        template(v-slot:default)
          thead
            tr
              th(class="text-left" style="padding-left:0")
                span(class="m-block-receipt-sale-mixed-payment-types__header") {{$t('Payment type')}}
              th(class="text-left" width="110")
                span(class="m-block-receipt-sale-mixed-payment-types__header") {{$t('Sum')}}
              th(width="25")
          tbody
            template(v-for="(item, key) in amountPayments")
              tr(:key="key + 'payment-method'")
                td(style="padding-left:0")
                  | {{item.label}}
                td
                  v-text-field(v-model="item.amount" @blur="formatValue(item)" @keypress="floatKeyPress" class="money-input" height="40" hide-details outlined)
                    template(v-slot:append)
                      span(class="pt-1 font-weight-bold text--disabled") &#x20b4;
                td(height="20px" align="center" style="padding-left:0")
                  e-svg-icon(style="margin-top:5px; cursor:pointer" v-if="item.dynamic && !item.loading" @click.native="deletePaymentMethod(item)" ) delete
                  e-progress-circular(size="sm" v-if="item.loading")
            tr(v-if="paymentsLoading")
              td(colspan="3")
                e-progress-linear(size="sm")
            tr(v-for="(payment, index) in newPayments" :key="index")
              td(style="padding-left:0")
                v-text-field(v-model="payment.label" :label="$t('Enter own payment type')" height="40" hide-details outlined)
              td(class="relative")
                v-text-field(v-model="payment.amount" @blur="formatValue(newPayments[index])" @keypress="floatKeyPress" class="money-input" height="40" hide-details outlined)
                  template(v-slot:append)
                    span(class="pt-1 font-weight-bold text--disabled") &#x20b4;
              td(height="20px" align="center" style="padding-left:0")
                e-svg-icon(style="margin-top:5px; cursor:pointer" v-if="index !== 0" @click="deleteNewPaymentInput(index)") delete
            tr
              td(colspan="3" :class="{ 'text-center': xsDevice }")
                e-link(@click="addNewPaymentInput" class="m-block-receipt-sale-mixed-payment-types__add-new-payment-btn") {{ $t('Add new type') }}
      div(class="mt-5 d-flex justify-end")
        v-btn(color="primary" :loading="loading" class="main-button" :class="{ 'w-100': xsDevice }" @click="submitPaymentTypes" height="48" depressed)
          | {{$t('Continue')}}
    v-col(v-if="!xsDevice" cols="12" sm="4" class="m-block-receipt-sale-mixed-payment-types__hint")
      div(class="m-block-receipt-sale-mixed-payment-types__hint-title") 💡 {{ $t('Hint') }}
      div(class="m-block-receipt-sale-mixed-payment-types__hint-text") {{ $t('Choose a convenient payment method, enter the amount and click "Add"') }}
</template>

<script>
import currencyFormat from '~/mixins/filters/currencyFormat'
import ReceiptPaymentMethods from '~/modules/receipt/models/ReceiptPaymentMethods'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import routes from '~/const/routes'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import responsive from '~/mixins/pages/responsive'
import ELink from '~/components/elements/links/e-link'

export default {
  components: { EProgressLinear, EProgressCircular, ESvgIcon, ELink },
  mixins: [currencyFormat, responsive],
  props: {
    closeModal: {
      type: Function,
      default: null
    },
    onPaymentMethodsReceived: {
      type: Function,
      default: null
    },
    paymentMethodsReceived: {
      type: Boolean,
      default: false
    },
    payments: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      loading: false,
      paymentsLoading: false,
      staticPayments: [
        {
          type: 'CASH',
          label: 'Готівка',
          amount: null,
          id: 'cash'
        },
        {
          type: 'CARD',
          label: 'Картка',
          amount: null,
          id: 'card'
        },
        {
          type: 'CASHLESS',
          label: 'Бонуси',
          amount: null,
          id: 'bonus'
        },
        {
          type: 'CASHLESS',
          label: 'За реквізитами (IBAN)',
          amount: null,
          id: 'iban'
        }
      ],
      newPayments: [
        {
          type: 'CASHLESS',
          label: null,
          amount: null,
          id: null
        }
      ],
      amountPayments: []
    }
  },
  computed: {
    model () {
      return ReceiptPaymentMethods
    },
    mergedPayments () {
      const dynamic = this.model.query().all().map((item) => {
        return {
          type: item.paymentType,
          label: item.label,
          id: item.id,
          amount: null,
          dynamic: true,
          loading: false
        }
      })
      return this.staticPayments.concat(dynamic)
    }
  },
  async created () {
    if (!this.$Organization) {
      return
    }
    await this.getPaymentMethods()
    this.calculatePayments()
    this.setPaymentsAmount()
  },
  methods: {
    async getPaymentMethods () {
      if (this.paymentMethodsReceived) {
        return
      }
      try {
        this.paymentsLoading = true
        await this.model.api().all()
        this.onPaymentMethodsReceived(true)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      } finally {
        this.paymentsLoading = false
      }
    },
    async deletePaymentMethod (item) {
      try {
        item.loading = true
        await this.model.api().delete(routes.receiptpaymentmethod.concrete(item.id), { delete: item.id })
        this.calculatePayments()
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    calculatePayments () {
      this.$set(this, 'amountPayments', this.mergedPayments)
      // TODO set external payments
    },
    setPaymentsAmount () {
      this.payments.forEach((item) => {
        const element = this.amountPayments.find(el => el.id === item.id)
        element.amount = item.amount
      })
    },
    formatValue (item) {
      if (item?.type === 'CASH' && item?.amount) {
        item.amount = parseFloat(item.amount).toFixed(1)
      }
      item.amount = this.moneyUahFormatter(item.amount)
    },
    addNewPaymentInput () {
      const copy = JSON.parse(JSON.stringify(this.newPayments))
      this.newPayments = [...copy, {
        type: 'CASHLESS',
        label: null,
        amount: null,
        id: null
      }]
    },
    deleteNewPaymentInput (index) {
      const copy = JSON.parse(JSON.stringify(this.newPayments))
      this.newPayments = this._.remove(copy, (v, i) => i !== index)
    },
    async submitPaymentTypes () {
      await this._.forEach(this.newPayments, async (payment) => {
        const newId = await this.addNewPaymentMethod(payment)
        if (newId) {
          payment.id = newId.id
        }
      })

      const merged = this.amountPayments.concat(this.newPayments)
      this.closeModal(merged.filter(item => item.amount))
    },
    async addNewPaymentMethod (payment) {
      if (!payment.label || !this.$Organization) {
        return
      }
      try {
        this.loading = true
        const response = await this.model.api().create({
          label: payment.label
        })
        return this._.get(response, 'entities.receiptpaymentmethod[0]')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }

}
</script>

<style scoped lang="scss">
.m-block-receipt-sale-mixed-payment-types {
  td, th {
    padding: 5px 8px;
    font-size: 0.75rem !important;
    color: #000 !important;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 0.875rem !important;
    }
  }

  tr {
    &:hover {
      background-color: transparent !important;
    }

    ::v-deep.v-input__control {
      .v-text-field__details {
        padding: 0;

        .v-messages__message {
          font-size: 9px;
        }
      }

      .v-input__slot {
        min-height: 32px !important;

        fieldset {
          border-color: #e0e0e0;
        }

        .v-input__append-inner {
          margin-top: 9px !important;
        }

        .v-text-field__slot {
          label {
            font-size: 10px !important;
            top: 9px;

            @media (min-width: map-get($breakpoints, 'sm')) {
              font-size: 13px !important;
            }
          }

          input {
            font-size: 13px;
            padding: 0;
            line-height: 16px;
          }
        }
      }
    }

    .money-input {
      ::v-deep.v-text-field__slot {
        input {
          text-align: right;
        }
      }
    }
  }

  tr, th, td {
    border-bottom: 0 !important;
  }

  &__add-new-payment-btn {
    background: none !important;

    color: #43CCB4;
    font-size: 13px !important;
    font-weight: 500;
    line-height: 20px;
  }

  &__header {
    color: rgba(22, 27, 37, 0.50);
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
  }

  &__title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.24px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__hint {
    background: rgba(0, 0, 0, 0.03);
    padding: 40px 32px;

    &-title {
      color: #161B25;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    &-text {
      padding-top: 12px;
      color: rgba(22, 27, 37, 0.75);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }
}

</style>
