<template lang="pug">
  div(class="pos-terminal-bank-selection")
    h3(class="pos-terminal-bank-selection__title") {{ $t('Choose the bank you want to connect and click "Continue"') }}
    div(class="pos-terminal-bank-selection__cards-wrap")
      div(
        v-for="item in banksCards"
        class="pos-terminal-bank-selection__card"
        :class="{ selected: selectedBank === item.key, disabled: !item.readyToUse }"
        :key="item.key"
        @click="cardClick(item.key)"
      )
        div(class="pos-terminal-bank-selection__card-header")
          img(
            :src="require(`~/assets/images/icons/${item.logo}`)"
            alt="logo"
          )
          span(class="pos-terminal-bank-selection__card-tooltip")
            e-info-tooltip(v-if="item.key === banks.aBank") {{ $t('To order a POS terminal, you need to perform several steps:') }}
              ul
                li {{ $t('Install the ABank24 mobile application and register') }}
                li {{ $t('Sign an application for opening a FOP account') }}
                li {{ $t('Sign the application for connecting acquiring') }}
        div(class="pos-terminal-bank-selection__card-content")
          div(class="mb-3")
            p(v-if="item.readyToUse" class="pos-terminal-bank-selection__card-title") {{ item.percent }}
            p(v-else class="pos-terminal-bank-selection__card-title") {{ $t('Soon') }}
            p(class="pos-terminal-bank-selection__card-text") {{ item.percentText }}
          div()
            p(v-if="item.readyToUse" class="pos-terminal-bank-selection__card-title") {{ item.price }}
            p(v-else class="pos-terminal-bank-selection__card-title") {{ $t('Soon') }}
            p(class="pos-terminal-bank-selection__card-text") {{ item.priceText }}
    div(
      class="d-flex justify-end pb-5"
    )
      v-btn(
        class="main-button"
        @click="btnClick"
      ) {{ $t('Continue') }}
</template>

<script>
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip'

export default {
  name: 'BlockPosTerminalBankSelection',
  components: { EInfoTooltip },
  props: {
    banks: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    selectedBank: null
  }),
  computed: {
    banksCards () {
      return [
        {
          key: this.banks.aBank,
          logo: 'a-bank-logo.svg',
          percent: '1.7%',
          percentText: 'Комісія від суми транзакцій за Android POS (з моб. додатком Checkbox)',
          price: '500 грн/міс',
          priceText: 'Вартість оренди Android POS',
          readyToUse: true
        },
        {
          key: this.banks.oschad,
          logo: 'oschadbank-logo.svg',
          percent: '1.7%',
          percentText: 'Комісія від суми транзакцій за Android POS (з моб. додатком Checkbox)',
          price: '500 грн/міс',
          priceText: 'Вартість оренди Android POS',
          readyToUse: false
        }
      ]
    }
  },
  created () {
    this.selectedBank = this.banks.aBank
    this.$gtm.push({
      event: 'order_new_pos_terminal_dialog',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    cardClick (key) {
      // TODO: remove when other banks will be added
      if (key === 'oschad') {
        this.selectedBank = null
        return
      }
      this.selectedBank = key
    },
    btnClick () {
      this.$gtm.push({
        event: `bank_selection_pos_terminal_${this.selectedBank}`,
        organization: this._.get(this.$Organization, 'owner.email')
      })
      this.$emit('changeSelectedBank', this.selectedBank)
      this.$emit('showOrderTerminalForm', true)
    }
  }
}
</script>

<style scoped lang="scss">
.pos-terminal-bank-selection {
  &__title {
    font-size: 13px;
    font-weight: 300;
    color: rgba(22, 27, 37, 0.75);
  }

  &__cards-wrap {
    display: flex;
    gap: 12px;
    padding: 20px 0 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;
    }
  }

  &__card {
    border: 1px solid #D0D5DD;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    max-width: 34%;

    &.selected {
      border: 1px solid transparent;
      box-shadow: 0 0 0 2px #161B25;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      max-width: 100%;
    }
  }

  &__card-header {
    height: 80px;
    background: #F0F1F2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    position: relative;
  }

  &__card-content {
    padding: 20px;
    background: #fff;
  }

  &__card-title {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }

  &__card-text {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 0;
  }

  &__card-tooltip {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
}
</style>
