<template lang="pug">
  div(class="a-bank-download-app")
    m-block-banner(
      :image="require(`~/assets/images/a-bank-app-qr.svg`)"
      :is-image-cover="false"
      title="Install Abank24 app"
    )
      template(#text)
        p(class="a-bank-download-app__text") Завантажуйте застосунок з
          |
          |
          a(class="a-bank-download-app__link" href="https://play.google.com/store/apps/details?id=ua.com.abank" target="_blank") Google Play
          |
          | або
          |
          a(class="a-bank-download-app__link" href="https://apps.apple.com/ua/app/abank24/id1261267716?l=uk" target="_blank") App Store
        div(class="d-flex align-center")
          a(href="https://play.google.com/store/apps/details?id=ua.in.checkbox" target="_blank" class="mr-4")
            img(src="~/assets/images/google-play.png" alt="google play")
          a(href="https://play.google.com/store/apps/details?id=ua.in.checkbox" target="_blank")
            img(src="~/assets/images/app-store-2.png" alt="app store")
</template>

<script>
import MBlockBanner from '~/components/modules/blocks/concrete/m-block-banner'

export default {
  name: 'BlockABankDownloadApp',
  components: {
    MBlockBanner
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    }
  },
  created () {
    this.changeTitle('Install mobile app and go through registration')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">
.a-bank-download-app {
  &__text {
    color: #000;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
  &__link {
    color: #04AED4;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
}
</style>
