<template lang="pug">
  div(
    class="offline-status"
    :class="{ 'offline-status--offline': isOffline, 'offline-status--mini': mini }"
  ) {{ statusText }}
</template>

<script>
import OfflineStatus from '~/modules/offline-status/models/OfflineStatus'
import auth from '~/modules/auth/auth'

export default {
  name: 'BlockOfflineStatus',
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    status: OfflineStatus.STATUSES.online.value,
    interval: null
  }),
  computed: {
    model () {
      return OfflineStatus
    },
    statusText () {
      const status = this.model.STATUSES[this.status]
      return this.mini ? status.textShort : status.text
    },
    isOffline () {
      return this.status === this.model.STATUSES.offline.value
    }
  },
  created () {
    this.pingStatus()
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    async pingStatus () {
      await this.getStatus()
      this.interval = setInterval(async () => {
        if (this.allowRequest()) {
          await this.getStatus()
        }
      }, 600000) // 10 minutes
    },
    allowRequest () {
      return this.isTokenValid && this.isPageVisible()
    },
    isPageVisible () {
      return !document.hidden
    },
    isTokenValid () {
      const token = auth.getAuthToken()
      const tokenExpiredAt = +token.expiredAt
      const timestamp = Math.floor(new Date().getTime() / 1000)
      return timestamp < tokenExpiredAt
    },
    async getStatus () {
      try {
        this.status = this._.get(await this.model.api().all({}, { save: false }), 'response.data.status')
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.offline-status {
  font-size: 11px;
  color: #fff;
  background: #43CCB4;
  padding: 12px 6px 8px;
  margin-top: -6px;
  border-radius: 0 0 8px 8px;
  line-height: 16px;

  &--offline {
    background: #FF1744;
  }

  &--mini {
    margin-top: 0;
    border-radius: 8px;
    padding: 6px;
    text-align: center;
    width: 100%;
  }
}
</style>
