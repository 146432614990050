import { Model } from '@vuex-orm/core'
import { get, reduce } from 'lodash'
import Dialog from '~/models/system/Dialog'
import Directory from '~/models/abstracts/Directory'
import Employees from '~/modules/employees/models/Employees'
import AssignAble from '~/models/mixins/AssignAble'
import FilterAble from '~/models/mixins/FilterAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import AppNotifications from '~/services/Notifications/AppNotifications'
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'
// import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
// import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
// import Organization from '~/models/directories/Organization'

class OrderDelivery extends ChainInheritance(Directory, [FilterAble, PersistAble, TimestampAble, AssignAble]) {
  static entity = 'orderDelivery'
  static paginated = true

  static ormMobileText = 'formattedTotalSum'
  static ormMobileTitle = 'order_id'
  static ormMobileSuptitle = 'createdAtString'

  static ormTrans = {
    single: 'Delivery order',
    multy: 'Delivery order'
  }

  static SELL_ORDER_TYPES = {
    ONLINE: 'online',
    OFFLINE: 'offline'
  }

  static statuses = {
    saving: {
      value: 'SAVING',
      text: 'Збережений'
    },
    pending: {
      value: 'PENDING',
      text: 'Чекає на обробку'
    },
    success: {
      value: 'SUCCESS',
      text: 'Виконаний'
    },
    cancelled: {
      value: 'CANCELLED',
      text: 'Відмінений'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      status: this.attr(null),
      is_paid: this.attr(null),
      order_id: this.attr(null),
      custom_status: this.attr(null),
      payment_method: this.attr(null),
      delivery_details: this.attr(null),
      receipt_draft: this.attr(null),
      not_fiscalize: this.attr(null),
      stock_code: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null)
    }
  }

  get createdAtString () {
    return this.getDateTime(this.created_at)
  }

  get formattedCashier () {
    return get(this.receipt_draft, 'cashier_name', '-')
  }

  get formattedContact () {
    const name = get(this.delivery_details, 'client_name', null)
    const phone = get(this.delivery_details, 'client_phone_number', null)

    return name && phone ? `${name}, ${phone}` : '-'
  }

  get formattedAddress () {
    const street = get(this.delivery_details, 'address.street', '-')
    const apartment = get(this.delivery_details, 'address.apartment_number', null)

    return apartment ? `${street}, кв. ${apartment}` : street
  }

  get formattedTotalSum () {
    return reduce(get(this.receipt_draft, 'goods', []), (sum, i) => sum + i.total_sum, 0) || 0
  }

  static ormHeaders = [
    { text: 'Order number', value: 'order_id', sortable: false, filterable: false },
    { text: 'Status', value: 'status', width: '150', sortable: false, filterable: false },
    { text: 'Date created', value: 'createdAtString', sortable: false, filterable: false },
    { text: 'Courier', value: 'formattedCashier', sortable: false, filterable: false },
    { text: 'Contact', value: 'formattedContact', sortable: false, filterable: false },
    { text: 'Address', value: 'formattedAddress', sortable: false, filterable: false },
    // { text: 'Sum', value: 'formattedTotalSum', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '50', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        outlined: true,
        'hide-details': true,
        itemText: 'text',
        placeholder: 'Status'
      },
      items: () => [{ text: 'Всі', value: '' }, ...Object.values(this.statuses)]
    },
    {
      type: 'dateRange',
      model: 'date',
      component: 'e-input-datetime-range',
      attrs: {
        type: 'date',
        label: 'Delivery time',
        range: true,
        outlined: true,
        clearable: true,
        closeOnClick: false,
        'hide-details': true,
        emmitOnlyOnSelectClicked: true,
        fastDates: true
      }
    }
    // new AutocompleteBuilder({
    //   model: 'cashier',
    //   label: 'Select employee',
    //   itemClass: 'ws-pre',
    //   hideDetails: true,
    //   customQuery: ctx => ctx?.$User?.isSuperAdmin,
    //   query: (model, ctx, config, user) => {
    //     const filter = { mode: Employees.itemModes.checkbox.mode }
    //     const viewedOrgId = get(user, 'viewedOrganization.id')
    //     if (user?.isSuperAdmin && viewedOrgId) {
    //       const route = Organization.$routes[Organization.entity].employees(viewedOrgId)
    //       return model.api().filter(filter).all(config?.params, { route })
    //     }
    //     return model.api().filter(filter)
    //   }
    // }, EmployeesSearch).build(),
    // {
    //   model: 'search',
    //   component: 'v-text-field',
    //   attrs: {
    //     outlined: true,
    //     'hide-details': true,
    //     placeholder: 'Search by order number',
    //     'prepend-inner-icon': 'mdi-magnify'
    //   },
    //   classes: ['filled-input']
    // }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'date'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }

          const { saving, pending, success, cancelled } = this.statuses

          return {
            id: item.id,
            [success.value]: {
              text: success.text,
              value: success.value,
              appearance: {
                color: 'rgba(67, 204, 180, 0.08)',
                textColor: '#43CCB4'
              }
            },
            [pending.value]: {
              text: pending.text,
              value: pending.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [saving.value]: {
              text: saving.text,
              value: saving.value,
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            [cancelled.value]: {
              text: cancelled.text,
              value: cancelled.value,
              appearance: {
                color: 'rgba(255, 23, 68, 0.08)',
                textColor: '#FF1744'
              }
            }
          }
        }
      }
    }
  }

  static ormActions = [
    {
      name: 'delete',
      visible: item => [OrderDelivery.statuses.pending.value, OrderDelivery.statuses.saving.value].includes(item.status),
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: 'Are you sure you want to delete delivery order?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            const token = await OrderDelivery.cashierToken()
            await OrderDelivery.api().cancelOrder(item.id, token)
            AppNotifications.success('Delivery order successfully deleted')
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.createOrder = function (payload, token) {
        return this.post(Model.$routes.orderDelivery.list(), payload, {
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.cancelOrder = function (id, token) {
        return this.put(Model.$routes.orderDelivery.cancelOrder(id), {}, {
          headers: { 'X-Processing-Authorization': token }
        })
      }

      return configActions
    }
  }

  static cashierToken = async () => {
    const token = get(AuthenticatedCashier.query().first(), 'accessToken')

    if (token) {
      return token
    }

    await Employees.api().filter({ mode: Employees.itemModes.checkbox.mode, limit: 10 }).all()
    const employee = Employees.query().where((item) => {
      if (item.certificateEnd) {
        return item.mode === Employees.itemModes.checkbox.mode && new Date().getTime() < new Date(item.certificateEnd).getTime()
      }
    }).first()

    return get(await Employees.api().processingSignin(employee), 'response.data.access_token')
  }
}

export default OrderDelivery
