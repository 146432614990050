<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-5 mt-1") {{ $t('Excise stamps of product') }} "{{ _.get(item, 'goods.name') }}"
    ValidationObserver(ref="form" slim)
      v-form(@submit.prevent.stop="setExciseStamps")
        div
          p(class="excise-stamps__hint") {{ $t('To add multiple excise stamps, enter a value in the field') }}
          ValidationProvider(
            v-for="stamp in stampsCount"
            :key="stamp"
            rules="exciseStamp"
            :name="$t('Excise stamp')"
            :vid="stamps[stamp]"
            v-slot="{ errors }"
            mode="lazy"
          )
            e-input-scanner(
              :placeholder="$t('Enter excise stamp')"
              v-model="stamps[stamp]"
              class="mb-1"
              @change="onStampChange"
              :error-messages="errors"
              translate
              outlined
            )
          p(
            v-if="notUniqError"
            class="excise-stamps__error"
          ) {{ $t(notUniqErrorText) }}
        div(class="dialog__actions text-center")
          v-btn(
            color="primary"
            class="main-button"
            type="submit"
          ) {{ $t('Continue') }}
</template>

<script>
import EInputScanner from '~/components/elements/inputs/e-input-scanner'

export default {
  components: {
    EInputScanner
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      required: true
    },
    goods: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    stamps: {
      0: null
    },
    notUniqError: false,
    notUniqErrorText: ''
  }),
  computed: {
    stampsCount () {
      return Object.keys(this.stamps)
    }
  },
  watch: {
    stamps: {
      handler (stamps) {
        const lastIndex = this._.last(Object.keys(stamps))
        if (stamps[lastIndex]) {
          this.$set(this.stamps, +lastIndex + 1, null)
        }
      },
      deep: true
    }
  },
  created () {
    this.fillStampsData()
  },
  methods: {
    isUniq () {
      const uniqValues = []
      const notUniqValues = []
      this._.map(this.stamps, (val) => {
        if (!val) {
          return
        }
        if (uniqValues.includes(val)) {
          notUniqValues.push(val)
        } else {
          uniqValues.push(val)
        }
      })
      if (!notUniqValues.length) {
        const filteredGoods = this._.filter(this.goods, (item) => {
          return this._.get(item, 'goods.id') !== this._.get(this.item, 'goods.id')
        })
        this._.each(uniqValues, (val) => {
          this._.each(filteredGoods, (item) => {
            const itemExciseBarcodes = this._.get(item, 'goods.excise_barcodes') || []
            if (itemExciseBarcodes.includes(val)) {
              notUniqValues.push(val)
            }
          })
        })
        if (notUniqValues.length) {
          this.notUniqErrorText = 'You have one or more identical excise stamps in the receipt'
        }
      } else {
        this.notUniqErrorText = 'You have one or more identical excise stamps in the product'
      }
      if (notUniqValues.length) {
        this.notUniqError = true
      }
      return !notUniqValues.length
    },
    async setExciseStamps () {
      this.notUniqError = false
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      const isUniq = this.isUniq()
      if (!isUniq) {
        return
      }
      const stamps = this._.filter(Object.values(this.stamps), val => val !== null && val !== '')
      const goodsClone = JSON.parse(JSON.stringify(this.goods))
      this._.each(goodsClone, (good) => {
        const foundProduct = this._.get(good, 'goods.id') === this._.get(this.item, 'goods.id') && this._.get(good, 'price') === this._.get(this.item, 'price')
        if (foundProduct) {
          good.goods.excise_barcodes = stamps || []
        }
      })
      this.closeModal(goodsClone)
    },
    fillStampsData () {
      this._.map(this._.get(this.item, 'goods.excise_barcodes', []), (stamp, index) => {
        this.$set(this.stamps, index, stamp)
      })
    },
    onStampChange () {
      this._.each(this.stamps, (value, key) => {
        if (value === '') {
          this.$delete(this.stamps, key)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.excise-stamps {
  &__hint {
    font-size: 0.75rem;
    line-height: 1.3;
    margin: -15px 0 15px;
  }

  &__error {
    font-size: 12px;
    line-height: 1;
    margin: -5px 0 10px;
    color: $color-error;
  }
}
</style>
