import { render, staticRenderFns } from "./m-form-cash-register.vue?vue&type=template&id=4d2e0a7c&scoped=true&lang=pug&"
import script from "./m-form-cash-register.vue?vue&type=script&lang=js&"
export * from "./m-form-cash-register.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d2e0a7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardTitle,VCol,VForm,VRow})
