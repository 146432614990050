<template lang="pug">
  div(
    class="hints-block-content"
    :class="{ 'hints-block-content--mobile': mobile }"
  )
    div(v-if="currentStep === steps.options")
      p Щоб додати касира, потрібен ЕЦП ключ співробітника або його ідентифікатор, та “ключ першого документа”
      p
        b(class="mr-1") Ключ першого документа –
        | це ЕЦП ключ, яким був підписаний перший документ (заява, запит, звіт тощо) у ДПС
      p
        | Якщо у вас немає ключа касира, то ви можете
        b(class="mx-1") “Створити ключ через DepositSign” –
        | це хмарний КЕП від DepositSign для реєстрації касира Checkbox та підпису чеків DepositSign
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/portal/cashiers"
      ) Детальна інструкція →
    div(v-else-if="currentStep === steps.selectEdsKey")
      p Оберіть ключ ЕЦП, який буде використовуватись для роботи каси та підпису чеків
    div(v-else-if="formIsShown")
      p
        span(class="mr-1") Для реєстрації потрібно вказати назву касира, а також придумати логін і пароль, котрий буде використовуватись для входу касира в мобільний додаток або на
        e-link(
          href="https://my.checkbox.ua/"
        ) my.checkbox.ua
      template(v-if="currentStep === steps.employeeFormEdsKey")
        p
          b(class="mr-1") CheckboxПідпис –
          | ключ, який автоматично використовується для фіскальних операцій (рекомендуємо обирати “Так”)
        p Якщо не використовувати CheckboxПідпис на захищеному хмарному сервісі, тоді вам необхідно буде ключ запускати в тимчасовий кеш браузера або використовувати CheckboxПідпис локально
      template(v-if="currentStep === steps.existingDsKey")
        p
          b(class="mr-1") DepositSign –
          | дані необхідно взяти з кабінету DepositSign
      template(v-if="currentStep === steps.manualEmployeeForm")
        p
          span(class="mr-1") {{ $t('How to find the key id –') }}
          e-link(
            href="https://docs.google.com/document/d/1oARtTaDOeco2McGeeqNVIWxJeVzyjhyJgB4VDlHrdC8"
          ) {{ $t('Instructions') }} 🔗
      template(v-if="currentStep === steps.creatingDsKey")
        e-link(
          class="mt-6"
          href="https://wiki.checkbox.ua/portal/cashiers"
        ) Детальна інструкція →
    div(v-else-if="currentStep === steps.creatingDsKey")
      p
        span(class="mr-1") Для реєстрації потрібно вказати назву касира, номер телефону, а також придумати логін і пароль, котрий буде використовуватись для входу касира в мобільний додаток або на
        e-link(
          href="https://my.checkbox.ua/"
        ) my.checkbox.ua
    div(v-else-if="currentStep === steps.sign")
      p Це вікно від ДПС, використовується для накладання підпису ФОПа/директора ТОВа, власника аккаунту Checkbox
      p Для подачі документів у ДПС, необхідно використовувати ЕЦП “ключ першого документа”
      p
        b(class="mr-1") Ключ першого документа –
        | це ЕЦП ключ, яким був підписаний перший документ (заява, запит, звіт тощо) у ДПС
    div(v-else-if="currentStep === steps.keyReplacementOptions")
      p Якщо у вас вже є новий ключ касира оберіть зчитати дані через ключ ЕЦП
      p Або створити хмарний ключ DepositSign – ключ буде створено автоматично, уникнувши необхідності окремо отримувати та зберігати файловий ЕЦП на особистому ПК
      p Оберіть зручний для вас спосіб та дотримуйтесь інструкцій
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/uk/portal/cashiers"
      ) Детальна інструкція →
    div(v-else-if="currentStep === steps.keyReplacementFormEdsKey")
      p Оберіть новий ключ ЕЦП який буде використовуватись для роботи каси та підпису чеків
      p
        b(class="mr-1") CheckboxПідпис –
        | ключ, який автоматично використовується для фіскальних операцій (рекомендуємо обирати “Так”)
      p Якщо не використовувати CheckboxПідпис на захищеному хмарному сервісі, тоді вам необхідно буде ключ запускати в тимчасовий кеш браузера або використовувати CheckboxПідпис локально
    div(v-else-if="currentStep === steps.keyReplacementDsCreating")
      p Важливо, щоб ви вказали актуальний номер телефону, на який ви можете отримати смс-повідомлення і продовжити реєстрацію!
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/portal/cashiers"
      ) Детальна інструкція →
    div(v-else-if="currentStep === steps.keyReplacementDsExisting")
      p
        b(class="mr-1") DepositSign –
        | дані необхідно взяти з кабінету DepositSign
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockEmployeeFormHintContent',
  components: {
    ELink
  },
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formIsShown () {
      return this.currentStep === this.steps.employeeFormEdsKey ||
        this.currentStep === this.steps.existingDsKey ||
        this.currentStep === this.steps.manualEmployeeForm
    }
  }
}
</script>

<style scoped lang="scss">
</style>
