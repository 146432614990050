import { Model } from '@vuex-orm/core'
import User from '~/models/administration/User'
import { formats } from '~/const/global'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '@/services/Notifications/AppNotifications'

export default class AdminUsers extends User {
  static entity = 'adminusers'

  static ormTrans = {
    single: 'Admin user',
    multy: 'Admin users'
  }

  static ADMIN_ROLES = {
    partnersManager: 'ROLE_PARTNERS_MANAGER',
    clientsManager: 'ROLE_CLIENTS_MANAGER',
    billingManager: 'ROLE_BILLING_MANAGER'
  }

  static fields () {
    return {
      ...super.fields(),
      dateModified: this.attr(null),
      active: this.attr(null)
    }
  }

  get dateModifiedString () {
    return this.dateModified ? this.$moment(this.dateModified).format(formats.dateTimeSec) : ''
  }

  static rolesArray = () => {
    const items = []
    const adminRoles = Object.values(AdminUsers.ADMIN_ROLES)
    Object.values(User.ROLES).forEach((role) => {
      if (adminRoles.includes(role)) {
        items.push({
          text: User.ROLE_NAMES[role],
          value: role
        })
      }
    })
    return items
  }

  get rolesString () {
    const rolesArr = []
    this.roles.map((role, key) => {
      if (role !== User.ROLES.user && User.ROLE_NAMES[role]) {
        rolesArr.push(User.ROLE_NAMES[role])
      }
    })
    return rolesArr.join(', ') || '-'
  }

  get activeString () {
    return this.active ? 'active' : 'deactivated'
  }

  static ormHeaders = [
    { text: 'Status', value: 'activeString', sortable: true, filterable: true },
    { text: 'Full name', value: 'fullName', sortable: true, filterable: true },
    { text: 'Roles', value: 'rolesString', sortable: false, filterable: false },
    { text: 'Login ', value: 'login', sortable: true, filterable: true },
    { text: 'Phone', value: 'phone', sortable: true, filterable: true },
    { text: 'Visited at', value: 'dateModifiedString', sortable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormRowsConfig = {
    disabled: false
  }

  static ormColsComponents = {
    activeString: {
      component: 'e-radio-status',
      attrs: {
        valueMap: {
          active: true,
          deactivated: true
        },
        colorMap: {
          active: '#161b25',
          deactivated: '#FF002B'
        }
      }
    }
  }

  static ormFields = [
    ...User.ormFields,
    {
      model: 'roles',
      component: 'v-select',
      provider: {
        vid: 'roles',
        name: 'Additional roles',
        rules: ''
      },
      attrs: {
        label: 'Additional roles',
        outlined: true,
        multiple: true
      },
      items: () => AdminUsers.rolesArray()
    }
  ]

  static ormActions = [
    {
      name: 'read',
      text: 'View',
      icon: {
        text: 'info',
        type: 'e-svg-icon'
      }
    },
    {
      name: 'edit',
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Edit admin user',
          width: '600px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Save',
            successMessage: 'Employee edited successfully',
            fields: [
              {
                model: 'roles',
                component: 'v-select',
                provider: {
                  vid: 'roles',
                  name: 'Additional roles',
                  rules: ''
                },
                attrs: {
                  label: 'Additional roles',
                  outlined: true,
                  multiple: true
                },
                items: () => AdminUsers.rolesArray(),
                fieldVal: () => item.roles
              }
            ],
            onSubmit: async (data) => {
              await AdminUsers.api().update(item, data)
              await contentDialog.close()
            }
          }
        })
      }
    },
    {
      name: 'delete',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: 'Are you sure you want to delete the employee?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await AdminUsers.api().update(item, {
              roles: [],
              active: false
            })
            AppNotifications.success('Employee deleted successfully')
          }
        })
      }
    }
  ]

  static ormDialogs = {
    default: 'm-orm-crud-dialog',
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ name: item.fullName }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'profile.firstName',
            label: 'First name'
          },
          {
            model: 'profile.lastName',
            label: 'Last name',
            value: val => val || '-'
          },
          {
            model: 'login',
            label: 'Login '
          },
          {
            model: 'email',
            label: 'Email',
            value: val => val || '-'
          },
          {
            model: 'phone',
            label: 'Phone',
            value: val => val || '-'
          },
          {
            model: 'rolesString',
            label: 'Additional roles'
          },
          {
            model: 'dateModifiedString',
            label: 'Visited at'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      // configActions.changePassword = function (id, payload) {
      //   return this.put(Model.$routes.users.changePassword(id), payload)
      // }
      return configActions
    }
  }
}
