<template lang="pug">
  div(class="form-employee-upload-eds-key")
    form-employee-upload-eds-key-info(
      v-if="showKeyInfo && localKeyData"
      :key-data="localKeyData"
      @confirm="handleConfirm"
    )
    ValidationObserver(
      ref="form"
      slim
      v-else
    )
      v-form(@submit.prevent.stop="onSubmitForm")
        ValidationProvider(
          rules="required"
          vid="ca"
          :name="$t('Qualified provider trust services')"
          v-slot="{ errors }"
          mode="lazy"
        )
          v-select(
            v-model="form.ca"
            :label="$t('Qualified provider trust services')"
            item-text="issuerCNs[0]"
            item-value="address"
            :items="acskProviders"
            :error-messages="errors"
            outlined
          )
        div(
          class="form-employee-upload-eds-key__file-input-wrapper"
          :class="{ 'form-employee-upload-eds-key__file-input-wrapper--error': fileError }"
        )
          div(class="form-employee-upload-eds-key__file-input-content")
            input(
              v-show="false"
              @input="handleFileSelected"
              :accept="isIOS ? '' : '.dat,.pfx,.pk8,.zs2,.jks'"
              type="file"
              ref="fileInput"
            )
            div(class="form-employee-upload-eds-key__file-input-key-icon")
              e-svg-icon key-2
            div(class="form-employee-upload-eds-key__file-input-text")
              h3 {{ fileName || $t('Select eds key') }}
              p {{ $t('Possible formats: *.dat, *.pfx, *.pk8, *.zs2, *.jks') }}
            v-btn(
              @click="handleFileSelect"
              class="secondary-button form-employee-upload-eds-key__file-input-btn"
              outlined
            ) {{ $t('choose') }}
          div(
            v-if="fileError"
            class="form-employee-upload-eds-key__error"
          ) {{ $t(fileError) }}
        ValidationProvider(
          rules="required"
          vid="keyPassword"
          :name="$t('Enter the EDS key password')"
          v-slot="{ errors }"
          mode="lazy"
        )
          e-input-password(
            v-model="form.keyPassword"
            :label="$t('Enter the EDS key password')"
            type="password"
            :error-messages="errors"
            outlined
          )
        v-radio-group(
          v-if="showKeyLaunchButtons"
          v-model="form.uploadCashierEdsKey"
          class="form-employee-upload-eds-key__radio-group"
          row
          hide-details
        )
          template(v-slot:label)
            div() {{ $t('Launch Checkbox Signature on a secure cloud service') }}?
          v-radio(
            :ripple="false"
            color="black"
            key="yes"
            :value="true"
            :label="$t('Yes (recommended)')"
          )
          v-radio(
            :ripple="false"
            color="black"
            key="no"
            :value="false"
            :label="$t('No')"
          )
        div(class="form-employee-upload-eds-key__actions")
          v-btn(
            class="main-button"
            :loading="loading || btnLoading"
            type="submit"
          ) {{ $t(buttonText) }}
</template>

<script>
import Base64js from 'base64-js'
import CAs from 'assets/data/iit/CAs.json'
import Employees from '~/modules/employees/models/Employees'
import EInputPassword from '~/components/elements/inputs/e-input-password'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import FormEmployeeUploadEdsKeyInfo from '~/modules/employees/views/components/form-employee-upload-eds-key-info'

export default {
  name: 'FormEmployeeUploadEdsKey',
  components: {
    ESvgIcon,
    EInputPassword,
    FormEmployeeUploadEdsKeyInfo
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    },
    showKeyInfo: {
      type: Boolean,
      default: false
    },
    showKeyLaunchButtons: {
      type: Boolean,
      default: false
    },
    btnLoading: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: null
    },
    keyData: {
      type: Object,
      default: null
    },
    keyForm: {
      type: Object,
      default: null
    },
    keyFileName: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Run'
    }
  },
  data: () => ({
    form: {
      ca: null,
      keyFile: null,
      keyPassword: null,
      uploadCashierEdsKey: true
    },
    acskProviders: CAs,
    loading: false,
    fileName: null,
    fileError: '',
    localKeyData: null
  }),
  computed: {
    isIOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // iPad on iOS 13 detection
    }
  },
  created () {
    if (this.keyData) {
      this.localKeyData = this.keyData
    }
    if (this.keyForm) {
      this.form = {
        ...this.form,
        ...this.keyForm
      }
    }
    if (this.keyFileName) {
      this.fileName = this.keyFileName
    }
  },
  methods: {
    handleFileSelect () {
      if (this.$refs?.fileInput) {
        this.$refs.fileInput.click()
      }
    },
    handleFileSelected (e) {
      this.fileError = ''
      const file = this._.get(e, 'target.files[0]')
      if (file instanceof File) {
        this.fileName = file.name
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => {
          this.$set(this.form, 'keyFile', reader.result)
        }
      }
    },
    async checkKey (data) {
      const payload = {
        ca: data.ca,
        keyPassword: data.keyPassword,
        keyFile: Base64js.fromByteArray(new Uint8Array(data.keyFile))
      }
      return this._.get(await Employees.api().checkKey(payload), 'response.data', null)
    },
    handleConfirm () {
      const payload = {
        form: this.form,
        keyData: this.localKeyData,
        keyFileName: this.fileName
      }
      this.$emit('confirm', payload)
      if (this.inModal) {
        this.closeModal(payload, true)
      }
    },
    async onSubmitForm () {
      try {
        this.loading = true
        this.fileError = ''
        const valid = await this.$refs.form.validate()
        const selectedFile = this.form.keyFile
        if (!selectedFile) {
          this.fileError = 'Поле "Оберіть ключ ЕЦП" повинно мати значення'
        }
        if (!valid || !selectedFile) {
          return
        }

        const keyData = await this.checkKey(this.form)
        const payload = { form: this.form, keyData }
        this.localKeyData = keyData

        this.$emit('select', payload)

        if (this.inModal && !this.showKeyInfo) {
          this.closeModal(payload, true)
        }
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-employee-upload-eds-key {
  &__file-input-wrapper {
    padding: 20px;
    border: 2px dashed #F0F1F2;
    border-radius: 16px;
    margin-bottom: 27px;

    &--error {
      border-color: #ff1744;
    }
  }

  &__file-input-content {
    gap: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: map-get($breakpoints, 'md')) {
      flex-wrap: nowrap;
    }
  }

  &__file-input-key-icon {
    padding: 12px;
    border-radius: 12px;
    background: #F0F1F2;
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__file-input-text {
    flex-grow: 1;

    h3 {
      font-weight: 600;
      font-size: 14px;
      color: #000;
      margin-bottom: 4px;
    }

    p {
      font-weight: 300;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.75);
      margin: 0;
    }
  }

  &__file-input-btn {
    width: 100%;
    max-width: 140px;
  }

  &__error {
    font-size: 12px;
    color: #ff1744;
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }

  &__radio-group {
    margin: 0 0 24px;
    padding: 0;

    &::v-deep {
      legend.v-label {
        width: 100%;
        margin-bottom: 12px;
        height: auto;
      }
    }
  }
}

.hint {
  font-size: 0.75rem !important;
  line-height: 1.2 !important;
}
</style>
