<template lang="pug">
  v-row()
    v-col(cols="12")
      v-row(class="mt-2" justify="center" v-if="paymentPending")
        v-col(cols="12")
          p(class="title  font-weight-bold text-center" style="color: black" ) {{$t('Payment QR code')}}
        v-col(cols="12")
          qrcode-vue(:value="qrLink" level="H" size="180" class="text-center")
        v-col(cols="12" class="pt-0")
          span(class="info-hint__bulb") 💡
          span() Для оплати клієнту потрібно відсканувати даний QR код або скористатися QR кодом наданим Monobank.
        v-col(cols="12")
          p(class="mb-2") Очікується оплата...
          e-progress-linear(size="md")
        v-col(cols="auto")
          v-btn(color="primary" :loading="resetQrLoading" @click="resetQr"  class="main-button" depressed) Скасувати замовлення

      v-row(class="mt-2" justify="center" v-else-if="paymentError")
        v-col(cols="auto")
          e-svg-icon(name="warning-2" size="xbg")
        v-col(cols="12")
          p(class="text-center") Помилка при оплаті! {{errorText}}
        v-col(cols="auto")
          v-btn(color="primary" @click="close" class="main-button" depressed) Закрити

      v-row(class="mt-2" justify="center" v-else-if="paymentSuccess")
        v-col(cols="auto")
          e-svg-icon(name="money" size="xbg")
        v-col(cols="12")
          p(class="text-center") Оплата успішна

      v-row(class="mt-2" justify="center" v-else-if="receiptPending")
        v-col(cols="auto")
          e-svg-icon(name="receipt" size="xbg")
        v-col(cols="12")
          p(class="text-center") Видача фіскального чеку
          e-progress-linear(size="md")
      v-row(class="mt-2" justify="center" v-else-if="receiptError")
        v-col(cols="auto")
          e-svg-icon(name="warning-2" size="xbg")
        v-col(cols="12")
          p(class="text-center") Помилка при видачі чеку! {{errorText}}
        v-col(cols="auto")
          v-btn(color="primary"  @click="close"  class="main-button" depressed) Закрити

      v-row(class="mt-2" justify="center" v-else-if="receiptSuccess")
        v-col(cols="auto")
          e-svg-icon(name="done-all" size="xbg")
        v-col(cols="12")
          p(class="text-center") Фіскальний чек успішно створений

</template>

<script>
import QrcodeVue from 'qrcode.vue'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import ETextWithIcon from '~/components/elements/text/e-text-with-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import AcquiringInvoices from '~/modules/acquiring/models/AcquiringInvoices'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import Receipts from '~/modules/receipt/models/Receipts'
import authData from '~/modules/receipt/mixins/getters/authData'
import converters from '~/mixins/methods/converters'
import receiptDialogs from '~/modules/receipt/mixins/receiptDialogs'
import wait from '~/mixins/methods/wait'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

const workflow = {
  paymentPending: 'paymentPending',
  paymentError: 'paymentError',
  paymentSuccess: 'paymentSuccess',
  receiptPending: 'receiptPending',
  receiptError: 'receiptError',
  receiptSuccess: 'receiptSuccess'
}

export default {
  components: { ESvgIcon, ETextWithIcon, EProgressLinear, QrcodeVue },
  mixins: [authData, converters, receiptDialogs, wait, processingApiRequest],
  props: {
    orderId: {
      type: String,
      required: true
    },
    receiptId: {
      type: String,
      required: true
    },
    qrLink: {
      type: String,
      required: true
    }
  },
  data: () => ({
    state: workflow.paymentPending,
    errorText: null,
    done: false,
    resetQrLoading: false,
    checkOrderRequest: null
  }),
  computed: {
    model () {
      return AcquiringInvoices
    },
    paymentPending () {
      return this.state === workflow.paymentPending
    },
    paymentError () {
      return this.state === workflow.paymentError
    },
    paymentSuccess () {
      return this.state === workflow.paymentSuccess
    },
    receiptPending () {
      return this.state === workflow.receiptPending
    },
    receiptError () {
      return this.state === workflow.receiptError
    },
    receiptSuccess () {
      return this.state === workflow.receiptSuccess
    }
  },
  created () {
    this.process()
  },
  methods: {
    async process () {
      this.state = workflow.paymentPending
      const orderStatus = await this.checkOrder()
      if (!orderStatus) {
        return
      }
      if (orderStatus.status === this.model.statuses.paymentError.value) {
        this.errorText = orderStatus.error
        this.state = workflow.paymentError
      } else if (orderStatus.status === this.model.statuses.receiptError.value) {
        this.errorText = orderStatus.error
        this.state = workflow.receiptError
      } else if (orderStatus.status === this.model.statuses.done.value) {
        this.state = workflow.paymentSuccess
        await this.wait(2000)
        this.state = workflow.receiptPending
        const receipt = await this.checkReceipt()
        if (receipt.status === Receipts.processingStatuses.DONE) {
          const receiptHtml = this._.get(await Receipts.api().processingReadHtml(receipt.id), 'response.data')
          this.state = workflow.receiptSuccess
          await this.wait(3000)
          await this.openReceiptDialog({
            view: receiptHtml,
            item: receipt
          })
        } else {
          this.state = workflow.receiptError
        }
      }
    },
    async checkOrder () {
      const intervalRequest = new IntervalRequest(() => this.model.api().checkStatus(this.orderId), { exponential: 1.1, interval: 1500, maxDelay: 1e5 })
      const resolveCondition = response => [this.model.statuses.done.value, this.model.statuses.paymentError.value, this.model.statuses.receiptError.value].includes(response.response.data.status)
      this.checkOrderRequest = intervalRequest
      return this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
    },
    async checkReceipt () {
      const intervalRequest = new IntervalRequest(async () => {
        return await this.processingApiRequest({
          request: token => Receipts.api().processingRead(this.receiptId, token || this.token)
        })
      })
      const resolveCondition = response => response.response.data.status === Receipts.processingStatuses.DONE || response.response.data.status === Receipts.processingStatuses.ERROR
      return this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
    },

    async resetQr () {
      this.resetQrLoading = true
      try {
        await this.model.api().resetQr(this.orderId)
        this.checkOrderRequest.stopExponential()
        this.$notification.success('Замовлення скасовано')
        this.close()
      } catch (e) {
        this.resetQrLoading = false
        this.$handlers.error(e, this)
      } finally {
        this.resetQrLoading = false
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
