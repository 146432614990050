import { render, staticRenderFns } from "./m-block-payment-receipt-preview.vue?vue&type=template&id=71e0d7a2&scoped=true&lang=pug&"
import script from "./m-block-payment-receipt-preview.vue?vue&type=script&lang=js&"
export * from "./m-block-payment-receipt-preview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e0d7a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VCardText,VCol,VRow,VSelect})
