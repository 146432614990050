<template lang="pug">
  div
    p(class="mb-3") Це означає, що ви підписуєте заяву у ДПС не головним ключем директора.
    p(class="mb-1") Щоб уникнути цієї помилки, є два варіанти:
    ol(class="list mb-3")
      li Оновити сторінку браузера, після чого заново підписати заяву чинним ключем директора, якщо він є в наявності. Це може бути ваш попередній, старіший ключ, який ви використовували для подачі звітності у ДПС.
      li Якщо старого ключа немає в наявності, потрібно відкликати його, звернувшись до АЦСК в якому ви отримували ключ та зачекати від 4 до 24 годин (в більшості випадків синхронізація відбувається після 00:00) і спробувати подати заяву повторно, вказавши новий ключ.

    p(class="mb-1")
      span Для того щоб відкликати сертифікати потрібно скористатись{{ showInstructions ? ' ' : '...' }}
      e-link(
        href="https://wiki.checkbox.ua/uk/instructions/error/privat-bank-keys"
      ) інструкцією
      e-link(
        v-if="!showInstructions"
        class="ml-1"
        @click="showInstructions = true"
      ) Читати далі
    div(v-if="showInstructions")
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockPrivatBankFirstDocumentError',
  components: {
    ELink
  },
  data: () => ({
    showInstructions: true
  })
}
</script>

<style scoped lang="scss">
.list {
  li:not(:last-child) {
    margin-bottom: 7px;
  }
}
</style>
