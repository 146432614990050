<template lang="pug">
  div(class="tap-to-phone-form")
    div(class="dialog__content-flex")
      div(class="dialog__left")
        span(v-if="showStepper" class="tap-to-phone-form__stepper") {{ $t('Step') }} {{ currentStep }} / 3
        h3(class="tap-to-phone-form__title")
          v-btn(
            v-if="showBackBtn && !isEditing"
            class="back-arrow"
            @click="handleStepBack"
            icon
            small
          )
            e-svg-icon(size="sm") arrow-next
          span {{ $t(title) }}
        div(
          v-if="!hideHintMobile  && xsDevice"
          class="mb-6"
        )
          block-acquiring-form-hint(
            mobile
            :currentStep="step"
            :steps="steps"
            acquiringType="tap2phone"
            :isEditing="isEditing"
          )
        block-tap-to-phone-connecting-options(
          v-if="step === steps.options"
          @nextStep="handleStep"
        )
        m-block-acquiring-bank-selection(
          v-else-if="step === steps.bankSelection"
          :banks="banks"
          @changeSelectedBank="onChangeSelectedBank"
        )
        block-tap-to-phone-settings-form(
          v-else-if="step === steps.settingsForm"
          :close-modal="closeModal"
          :is-editing="isEditing"
          :is-updating="isUpdating"
          :item="item"
          :selected-bank="selectedBank"
          :monobank-data="monobankQrData"
          @changeMonoAccount="handleStep({ prevStep: steps.bankSelection, nextStep: steps.monoQr })"
        )
        block-privatbank-settings-form(
          v-else-if="step === steps.privatSettingsForm"
          :close-modal="closeModal"
          :table-request="tableRequest"
          :item="item"
          :is-editing="isEditing"
        )
        block-oschadbank-preview-info(
          v-else-if="step === steps.oschadPreview"
          :close-modal="closeModal"
          @nextStep="handleStep"
        )
        block-tap-to-phone-upload-documents(
          v-else-if="step === steps.uploadDocuments"
          @documents="handleDocuments"
          @nextStep="handleStep"
        )
        block-tap-to-phone-contact-form(
          v-else-if="step === steps.contactForm"
          :documents="documents"
          :tableRequest="tableRequest"
          :close-modal="closeModal"
          @nextStep="handleStep"
        )
        block-oschadbank-acquiring-order-info(
          v-else-if="step === steps.oschadOrderInfo"
          :close-modal="closeModal"
        )
        block-monobank-qr(
          v-else-if="step === steps.monoQr"
          :close-modal="closeModal"
          @getMonobankQrData="monobankQrData = $event"
          @showNextStep="handleStep({ prevStep: 'monoQr', nextStep: 'settingsForm' })"
        )
      div(v-if="step !== steps.bankSelection && !xsDevice" class="dialog__right")
        block-acquiring-form-hint(
          :currentStep="step"
          :steps="steps"
          acquiringType="tap2phone"
          :isEditing="isEditing"
        )
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import BlockMonobankQr from '~/modules/acquiring/views/monobank/block-monobank-qr'
import BlockOschadbankPreviewInfo from '~/modules/acquiring/views/oschadbank/block-oschadbank-preview-info'
import BlockTapToPhoneConnectingOptions from '~/modules/acquiring/views/block-tap-to-phone-connecting-options'
import BlockTapToPhoneSettingsForm from '~/modules/acquiring/views/block-tap-to-phone-settings-form'
import BlockTapToPhoneUploadDocuments from '~/modules/acquiring/views/block-tap-to-phone-upload-documents'
import BlockTapToPhoneContactForm from '~/modules/acquiring/views/block-tap-to-phone-contact-form'
import BlockOschadbankAcquiringOrderInfo from '~/modules/acquiring/views/oschadbank/block-oschadbank-acquiring-order-info'
import MBlockAcquiringBankSelection from '~/modules/acquiring/views/m-block-acquiring-bank-selection'
import BlockAcquiringFormHint from '~/modules/acquiring/views/block-acquiring-form-hint'
import responsive from '~/mixins/pages/responsive'
import BlockPrivatbankSettingsForm from '~/modules/acquiring/views/privatbank/block-privatbank-settings-form'

export default {
  name: 'BlockTapToPhoneForm',
  components: {
    ESvgIcon,
    BlockMonobankQr,
    BlockOschadbankPreviewInfo,
    BlockOschadbankAcquiringOrderInfo,
    BlockTapToPhoneContactForm,
    BlockTapToPhoneUploadDocuments,
    MBlockAcquiringBankSelection,
    BlockTapToPhoneSettingsForm,
    BlockTapToPhoneConnectingOptions,
    BlockAcquiringFormHint,
    BlockPrivatbankSettingsForm
  },
  mixins: [responsive],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    isFromBanner: {
      type: Boolean,
      default: false
    },
    isPrivatBank: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    documents: [],
    selectedBank: null,
    isNewTapToPhoneOption: false,
    monobankQrData: null,
    step: 'options',
    prevStep: null
  }),
  computed: {
    title () {
      if (this.isEditing) {
        return 'Tap to phone editing'
      } else if (this.isUpdating) {
        return 'Tap to phone settings'
      }
      return this.titles[this.step]
    },
    banks () {
      return [
        {
          key: 'mono',
          visible: () => !this.isNewTapToPhoneOption
        },
        {
          key: 'oschad',
          visible: () => true
        },
        {
          key: 'privat',
          visible: () => !this.isNewTapToPhoneOption
        }
      ]
    },
    steps () {
      return {
        options: 'options',
        bankSelection: 'bankSelection',
        settingsForm: 'settingsForm',
        monoQr: 'monoQr',
        oschadPreview: 'oschadPreview',
        uploadDocuments: 'uploadDocuments',
        contactForm: 'contactForm',
        oschadOrderInfo: 'oschadOrderInfo',
        privatSettingsForm: 'privatSettingsForm'
      }
    },
    titles () {
      return {
        options: 'Add Tap to Phone',
        bankSelection: 'Add Tap to Phone',
        settingsForm: 'Add Tap to Phone',
        monoQr: 'Add Tap to Phone',
        oschadPreview: 'Terminal from Oschadbank in your smartphone',
        uploadDocuments: 'Upload all needed documents',
        contactForm: 'Contacts data',
        oschadOrderInfo: 'Contacts data',
        privatSettingsForm: 'Add Tap to Phone'
      }
    },
    showStepper () {
      return [this.steps.uploadDocuments, this.steps.contactForm, this.steps.oschadOrderInfo].includes(this.step)
    },
    currentStep () {
      if (this.steps.uploadDocuments) {
        return 1
      } else if (this.steps.contactForm) {
        return 2
      } else {
        return 3
      }
    },
    showBackBtn () {
      return [this.steps.bankSelection, this.steps.settingsForm, this.steps.monoQr, this.steps.oschadPreview, this.steps.uploadDocuments, this.steps.contactForm, this.steps.privatSettingsForm].includes(this.step)
    },
    prevSteps () {
      return {
        bankSelection: this.steps.options,
        settingsForm: this.steps.bankSelection,
        monoQr: this.steps.bankSelection,
        oschadPreview: this.steps.bankSelection,
        uploadDocuments: this.steps.oschadPreview,
        contactForm: this.steps.uploadDocuments,
        privatSettingsForm: this.steps.bankSelection
      }
    },
    hideHintMobile () {
      return [this.steps.bankSelection, this.steps.oschadPreview, this.steps.oschadOrderInfo].includes(this.step)
    }
  },
  watch: {
    selectedBank (val) {
      // decide acquiring flow depending on selected bank
      if (val) {
        switch (val) {
          case 'mono':
            this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.monoQr })
            break
          case 'oschad':
            if (this.isNewTapToPhoneOption) {
              this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.oschadPreview })
            } else {
              this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.settingsForm })
            }
            break
          case 'privat':
            this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.privatSettingsForm })
            break
        }
      }
    }
  },
  created () {
    this.$gtm.push({
      event: 'open_add_tap_to_phone_dialog',
      organization: this._.get(this.$Organization, 'owner.email')
    })
    if (this.isFromBanner) {
      this.isNewTapToPhoneOption = true
      this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.oschadPreview })
    }
    if (this.isEditing || this.isUpdating) {
      if (this.isPrivatBank) {
        this.handleStep({ prevStep: null, nextStep: this.steps.privatSettingsForm })
      } else {
        this.handleStep({ prevStep: null, nextStep: this.steps.settingsForm })
      }
    }
  },
  methods: {
    handleStep ({ prevStep, nextStep, isNewOption }) {
      this.step = nextStep
      this.prevStep = prevStep
      if (isNewOption !== undefined) {
        this.isNewTapToPhoneOption = isNewOption
      }
    },
    handleStepBack () {
      this.selectedBank = null
      this.handleStep({ prevStep: this.prevSteps[this.prevStep] || null, nextStep: this.prevStep })
    },
    onChangeSelectedBank (val) {
      this.selectedBank = val
    },
    handleDocuments (documents) {
      this.documents = [...documents]
    }
  }
}
</script>

<style scoped lang="scss">
.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}

.tap-to-phone-form {
  &__stepper {
    display: inline-block;
    padding: 1px 6px;
    border-radius: 6px;
    background: #F0F1F2;
    align-self: flex-start;

    color: #161B25;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.11px;
    margin-bottom: 8px;
  }

  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }
}
</style>
