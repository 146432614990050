<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    v-on="listeners"
    :max-width="cardWidth"
    content-class="employee-form-dialog"
    content-without-padding
  )
    template(#content)
      div(class="dialog__content-flex")
        div(class="dialog__left")
          div(
            v-if="currentStep !== steps.options"
            class="employee-form-dialog__stepper"
          ) {{ $t('Step') }} {{ currentStepNumber }} / {{ totalStepsNumber }}
          h3(
            v-if="currentStep !== steps.sign"
            class="employee-form-dialog__title"
          )
            v-btn(
              v-if="showBackBtn"
              class="back-arrow"
              @click="handleStepBack"
              icon
              small
            )
              e-svg-icon(size="sm") arrow-next
            span {{ $t(title) }}
          div(
            v-if="showHintBlock && xsDevice && currentStep !== steps.sign"
            class="mb-6"
          )
            block-employee-form-hint(
              :currentStep="currentStep"
              mobile
            )
          block-employee-creating-options(
            v-if="currentStep === steps.options"
            @select="handleOptionSelect"
          )
          form-employee-upload-eds-key(
            v-else-if="showEdsKeyUploadForm"
            @select="handleEdsKeySelect"
            @confirm="handleEdsKeyConfirm"
            button-text="Read key"
            :show-key-info="showKeyInfo"
            :key-data="keyData"
            :key-form="keyForm"
            :key-file-name="keyFileName"
          )
          form-employee(
            v-else-if="showEmployeeForm"
            :key-file="keyFile"
            :item="item"
            :choice="initialChoice"
            @signing="handleSigning"
          )
          eds-iit-checkbox(
            v-else-if="currentStep === steps.sign"
            @stepChange="handleSignPluginStepChange"
            :on-confirm="handleSignKeyConfirmed"
            :on-interface-action-error="handleSignActionError"
            :key="edsIitCheckboxKey"
            show-cached-data-message
            keep-back-btn-visible
            read-diia-key
          )
            template(v-slot:subtitle)
              div(
                v-if="xsDevice && currentSignStep !== 'keyInfo'"
                class="mb-4"
              )
                block-eds-iit-checkbox-hint(
                  :current-step="currentSignStep"
                  mobile
                )
        div(
          v-if="showHintBlock && !xsDevice && currentSignStep !== 'keyInfo'"
          class="dialog__right"
        )
          block-eds-iit-checkbox-hint(
            v-if="currentStep === steps.sign"
            :current-step="currentSignStep"
          )
          block-employee-form-hint(
            :currentStep="currentStep"
            v-else
          )
</template>

<script>
import Base64js from 'base64-js'
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import FormEmployee from '~/modules/employees/views/components/form-employee'
import apm from '~/mixins/apm/apm'
import contentDialog from '~/mixins/dialogs/contentDialog'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'
import BlockEmployeeCreatingOptions from '~/modules/employees/views/components/block-employee-creating-options'
import FormEmployeeUploadEdsKey from '~/modules/employees/views/components/form-employee-upload-eds-key'
import BlockEmployeeFormHint from '~/modules/employees/views/components/block-employee-form-hint'
import responsive from '~/mixins/pages/responsive'
import employeeCreating from '~/modules/employees/mixins/employeeCreating'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/views/eds-iit-checkbox'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import edsIitCheckboxHelper from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxHelper'
import BlockEdsIitCheckboxHint from '~/modules/eds-iit-checkbox/views/block-eds-iit-checkbox-hint'

export default {
  name: 'EmployeeFormDialog',
  components: {
    BlockEmployeeFormHint,
    FormEmployee,
    ESvgIcon,
    ELink,
    TDialog,
    BlockEmployeeCreatingOptions,
    FormEmployeeUploadEdsKey,
    EdsIitCheckbox,
    BlockEdsIitCheckboxHint
  },
  mixins: [fill, apm, contentDialog, responsive, employeeCreating, filterOrmObject, edsIitCheckboxHelper],
  data: () => ({
    item: null,
    depositSignRequest: null,
    keyFile: null,
    currentStep: 'options',
    currentSignStep: 'options',
    currentStepNumber: 1,
    totalStepsNumber: 2,
    showKeyInfo: false,
    keyData: null,
    keyForm: null,
    keyFileName: null,
    initialChoice: null,
    edsIitCheckboxKey: 0
  }),
  computed: {
    model () {
      return EmployeeOrder
    },
    cardWidth () {
      return [900, 'px'].join('')
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.handleDataClear()
            vm.$emit('input', event)
          }
        }
      )
    },
    showEdsKeyUploadForm () {
      return this.currentStep === this.steps.selectEdsKey || this.currentStep === this.steps.edsKeyInfo
    },
    showEmployeeForm () {
      return this.currentStep === this.steps.employeeFormEdsKey ||
        this.currentStep === this.steps.creatingDsKey ||
        this.currentStep === this.steps.existingDsKey ||
        this.currentStep === this.steps.manualEmployeeForm
    },
    title () {
      switch (this.currentStep) {
        case this.steps.selectEdsKey:
          return 'Read data from eds key'
        case this.steps.edsKeyInfo:
          return 'Check the read key information'
        default:
          return this._.capitalize(this.$t('Employee data'))
      }
    },
    showHintBlock () {
      return this.currentStep !== this.steps.edsKeyInfo
    },
    showBackBtn () {
      return this.currentStep !== this.steps.options
    }
  },
  methods: {
    closeDialog (success = true) {
      const title = this.initialChoice === this.steps.creatingDsKey ? 'Request sent successfully' : 'The request to DPS was successfully sent'
      this.handleDataClear()
      this.$emit('input', false)
      if (success) {
        this.contentDialog.open({
          component: 'block-employee-information',
          width: '560px',
          contentNoGutters: true,
          componentProps: {
            title,
            text: 'Wait for a response about the change in the registration status of the cashier key'
          }
        })
      }
    },
    handleStepBack () {
      switch (this.currentStep) {
        case this.steps.selectEdsKey:
        case this.steps.creatingDsKey:
        case this.steps.existingDsKey:
        case this.steps.manualEmployeeForm:
          this.handleDataClear()
          break
        case this.steps.edsKeyInfo:
          this.currentStep = this.steps.selectEdsKey
          this.showKeyInfo = false
          break
        case this.steps.sign:
          if (this.initialChoice === this.steps.selectEdsKey) {
            this.currentStep = this.steps.employeeFormEdsKey
            this.currentStepNumber = 2
          } else {
            this.currentStep = this.initialChoice
            this.currentStepNumber = 1
          }
          break
        case this.steps.employeeFormEdsKey:
          if (this.initialChoice === this.steps.selectEdsKey) {
            this.currentStep = this.steps.edsKeyInfo
          } else {
            this.currentStep = this.steps.options
          }
          this.currentStepNumber = 1
          break
        default:
          break
      }
    },
    handleDataClear () {
      this.currentStep = this.steps.options
      this.currentSignStep = this.steps.options
      this.keyFile = null
      this.item = null
      this.depositSignRequest = null
      this.keyData = null
      this.keyForm = null
      this.initialChoice = null
      this.currentStepNumber = 1
      this.totalStepsNumber = 2
      this.showKeyInfo = false
    },
    handleOptionSelect (key) {
      this.totalStepsNumber = key === this.steps.selectEdsKey ? 3 : 2
      this.currentStep = key
      this.initialChoice = key
    },
    handleSigning ({ payload, depositSignRequest } = {}) {
      this.item = payload
      this.depositSignRequest = depositSignRequest
      this.currentStep = this.steps.sign
      this.currentStepNumber += 1
    },
    handleEdsKeySelect () {
      this.currentStep = this.steps.edsKeyInfo
      this.showKeyInfo = true
    },
    handleSignPluginStepChange (step) {
      if (step === 'options' && this.currentSignStep === 'options') {
        this.handleStepBack()
      }
      this.currentSignStep = step
    },
    handleSignActionError () {
      this.currentStep = this.steps.sign
      this.edsIitCheckboxKey += 1
    },
    async handleSignKeyConfirmed (signInterface) {
      try {
        const { error, noReturn, edsKeyData, reportService } = await this.generateSignInterfacePayloadAndErrorsCheck({
          signInterface,
          checkAuthService: true,
          createReportService: true,
          fallbackMethod: this.handleSignActionError
        })
        if (error) {
          return !noReturn ? this.handleSignActionError() : null
        }

        if (!edsKeyData.subjFullName) {
          this.$handlers.error('EDS key has not full name.  Please, choose other EDS key', this)
          return
        }

        const payload = this.filterOrmObject(this.item)

        if (!payload.T1RXXXXG2S) {
          payload.T1RXXXXG2S = this._.get(this.$Organization, 'edrpou', null) || this._.get(this.$Organization, 'taxNumber', null)
        }

        payload.HPOST = 'Директор'
        payload.HKBOS = reportService.getHKBOSField({
          sentry: this.$sentry,
          organization: this.$Organization,
          entity: this._.get(this.model, 'entity'),
          type: 'creation'
        })

        const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

        if (!re.test(payload.HKBOS)) {
          this.$handlers.error('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents', this)
          return
        }

        if (!payload.HKBOS) {
          this.$handlers.error('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key', this)
          return
        }

        if (this.initialChoice === this.steps.creatingDsKey) {
          payload.depositSignRequest = {
            phoneNumber: this._.get(this.depositSignRequest, 'phoneNumber'),
            certificateEncoded: Base64js.fromByteArray(new Uint8Array(Object.values(this._.get(edsKeyData.keyData, '[0].data', {}))))
          }
          payload.signatureType = 'DEPOSITSIGN'
        }

        const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)

        if (this.initialChoice !== this.steps.creatingDsKey) {
          const xmlResponse = await this.model.api().xmlDoc(createdEntity)
          const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
          const fname = this._.get(xmlResponse, 'response.data.fname', null)
          const signData = await reportService.getEnvelopedData(xml)

          if (!signData) {
            this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
            return
          }

          const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
          const encodedTaxId = new TextEncoder().encode(String(taxId))
          const signedTaxId = await reportService.getEnvelopedData(encodedTaxId, false)

          if (!signedTaxId) {
            this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
            return
          }

          await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })
        }

        this.closeDialog()
      } catch (e) {
        this.$handlers.error(e, this)
        this.closeDialog(false)
      }
    },
    handleEdsKeyConfirm (keyData) {
      const subjFullName = this._.get(keyData, 'keyData.certificate_info.certificate.pszSubjFullName', null)
      const subjDRFOCode = this._.get(keyData, 'keyData.certificate_info.certificate.pszSubjDRFOCode', null)
      const publicKeyID = this._.get(keyData, 'keyData.key_id', '')
      const issuerCN = this._.get(keyData, 'keyData.certificate_info.certificate.pszIssuerCN', null)
      const privKeyBeginTime = this._.get(keyData, 'keyData.certificate_start', null)
      const privKeyEndTime = this._.get(keyData, 'keyData.certificate_end', null)
      const certificate = this._.get(keyData, 'keyData.certificate_info.certificate', null)
      const keyFile = keyData.form

      keyFile.ca = this._.get(keyData, 'keyData.ca', null)

      this.item = {
        ...(this.item || {}),
        T1RXXXXG1S: subjFullName,
        T1RXXXXG2S: subjDRFOCode,
        T1RXXXXG3S: publicKeyID.replace(/ /g, '').toLowerCase(),
        ca: issuerCN,
        certificateStart: privKeyBeginTime,
        certificateEnd: privKeyEndTime,
        certificateInfo: certificate
      }
      this.keyFile = keyFile
      this.keyData = this._.get(keyData, 'keyData')
      this.keyForm = this._.get(keyData, 'form')
      this.keyFileName = this._.get(keyData, 'keyFileName')
      this.currentStep = this.steps.employeeFormEdsKey
      this.currentStepNumber = 2
    }
  }
}
</script>

<style lang="scss" scoped>
.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}

.employee-form-dialog {
  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }

  &__stepper {
    border-radius: 6px;
    background: #F0F1F2;
    padding: 2px 6px;
    color: $btn-secondary-color;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 8px;
    line-height: 16px;
  }
}
</style>
