<template lang="pug">
  div
    template(v-if="endSending")
      v-data-table(
        v-bind="tableConfig"
        :headers="headers"
        :locale="$config.locale.iso"
        :item-key="model.entity"
        :items="reportItems"
      )
        template(v-slot:item.saved="{ item }")
          e-svg-icon {{ item.saved ? 'done' : 'cross' }}
        template(v-slot:item.sent="{ item }")
          e-svg-icon {{ item.sent ? 'done' : 'cross' }}
    template(v-else)
      v-progress-linear(
        height="20"
        v-model="sendBillsCount"
      )
        span {{ sendBills }}/{{ totalItems }}
      v-btn(
        @click="startSending"
        class="main-button mx-auto d-block mt-7 mb-3"
        :loading="itemLoading"
      ) {{ $t('Send ') }}
</template>

<script>
import Base64js from 'base64-js'
import TaxBills from '~/models/billing/TaxBills'
import routes from '~/const/routes'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

export default {
  components: {
    ESvgIcon
  },
  mixins: [filterOrmObject, edsIitCheckboxDialog],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    itemLoading: false,
    sendBills: 0,
    report: {},
    endSending: false
  }),
  computed: {
    model () {
      return TaxBills
    },
    sendBillsCount () {
      return this.sendBills * 100 / this.totalItems
    },
    totalItems () {
      return (this.items && this.items.length) || 0
    },
    tableConfig () {
      return Object.assign(this.$config.table, this.model.getTableConfig())
    },
    headers () {
      return [
        { text: this._.upperFirst(this.$t('Saved')), value: 'saved' },
        { text: this._.upperFirst(this.$t('Sent')), value: 'sent' },
        { text: this._.upperFirst(this.$t('Number')), value: 'bill.number' },
        { text: this._.upperFirst(this.$t('organization')), value: 'bill.organization.name' }
      ]
    },
    reportItems () {
      return Object.values(this.report)
    }
  },
  created () {
    this._.forEach(this.items, (item) => {
      this.report[this._.get(item, 'id')] = {
        saved: false,
        sent: false,
        bill: item
      }
    })
  },
  methods: {
    async startSending () {
      try {
        this.itemLoading = true
        for (const item of this.items) {
          const bill = item
          if (bill) {
            const payload = {
              date: bill.date,
              number: bill.number,
              receiverEdrpou: bill.receiverEdrpou,
              receiverHtypr: bill.receiverHtypr,
              receiverName: bill.receiverName,
              receiverOrig: bill.receiverOrig,
              receiverTaxNumber: bill.receiverTaxNumber,
              sum: bill.sum,
              sumDetails: bill.sumDetails
            }
            const editedTaxBill = this._.get(await this.saveWithoutSending(bill, payload), 'entities.taxbills[0]', null)
            await this.sendToTax(bill, editedTaxBill)
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.endSending = true
        this.itemLoading = false
      }
    },
    async saveWithoutSending (bill, payload) {
      try {
        const res = await this.model.api().update(bill, payload)
        this.report[this._.get(bill, 'id', null)].saved = true
        return res
      } catch (e) {
        this.report[this._.get(bill, 'id', null)].saved = false
        this.$handlers.error(e, this)
      }
    },
    async sendToTax (bill, editedTaxBill) {
      if (editedTaxBill) {
        await this.useEdsIitCheckboxDialog({
          type: 'edsIitCheckboxCommon',
          keyCheck: false,
          createReportService: true,
          onConfirm: async ({ reportService }) => {
            try {
              if (!reportService) {
                return null
              }
              const { fname, xml } = this._.get(await this.model.api().put(routes[this.model.entity].xmlDoc(editedTaxBill.id), {}, { save: false }), 'response.data', {})
              const signData = await reportService.getEnvelopedData(Base64js.toByteArray(xml), true)
              if (!signData) {
                return
              }
              await this.model.api().put(routes[this.model.entity].taxRegister(editedTaxBill.id), { fname, signData })
              this.sendBills += 1
              this.report[this._.get(bill, 'id', null)].sent = true
            } catch (e) {
              this.report[this._.get(bill, 'id', null)].sent = false
              this.$handlers.error(e, this)
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
