import Download from 'js-file-download'
import { Model } from '@vuex-orm/core'
import PromoCodeOrganizations from '../billing/PromoCodeOrganizations'
import Restorations from './Restorations'
import AdminAuthLog from './AdminAuthLog'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import User from '~/models/administration/User'
import Stepper from '~/view_configs/modules/stepper/Stepper'
import MediaObject from '~/models/system/MediaObject'
import Billings from '~/models/billing/Billings'
import Invoices from '~/models/billing/Invoices'
import CashRegisters from '~/models/directories/CashRegisters'
import Shifts from '~/models/directories/Shifts'
import Dialog from '~/models/system/Dialog'
import PromoCodes from '~/models/billing/PromoCodes'
import { formats } from '~/const/global'
import Tags from '~/models/directories/Tags'
import authUser from '~/modules/auth/authUser'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import LabelsSearch from '~/modules/goods/models/LabelsSearch'
import Labels from '~/models/directories/Labels'
import AppNotifications from '~/services/Notifications/AppNotifications'
import PromoCodeRroShare from '@/models/billing/PromoCodeRroShare'
import CashRegistersSearch from '@/models/directories/search/CashRegistersSearch'

export default class Organization extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'organization'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'name'
  static defaultSortOrder = false
  static persistOptions = {
    insertOrUpdate: ['users']
  }

  static personTypes = [
    {
      text: 'Фізична особа підприємець',
      value: 'individual'
    }, {
      text: 'Юридична особа',
      value: 'juridical'
    }
  ]

  static documentFlowServices = {
    email: {
      value: 'email',
      text: 'На Email'
    },
    vchasno: {
      value: 'vchasno',
      text: 'В сервіс Вчасно'
    },
    dubidoc: {
      value: 'dubidoc',
      text: 'В сервіс Dubidoc'
    }
  }

  static billingStatuses = {
    created: {
      text: 'Рахунок створено',
      value: 'created'
    },
    trial: {
      text: 'Тріальний період',
      value: 'trial'
    },
    invoiced: {
      text: 'Виставлений рахунок',
      value: 'invoiced'
    },
    in_debt: {
      text: 'Борг',
      value: 'in_debt'
    },
    disabled: {
      text: 'Відключено',
      value: 'disabled'
    },
    cc_active: {
      text: 'Продовжено колл центром',
      value: 'cc_active'
    },
    payed: {
      text: 'Сплачено',
      value: 'payed'
    },
    frozen: {
      text: 'Заморожено',
      value: 'frozen'
    },
    start: {
      text: 'Немає кас',
      value: 'start'
    }
  }

  static fields () {
    return super.fields({
      owner_id: this.attr(null),
      logo_id: this.attr(null),

      ownerId: this.attr(null),
      // billing_id: this.attr(null, val => val),

      id: this.attr(null),
      name: this.attr(null),
      shortName: this.attr(null),
      edrpou: this.attr(null),
      logo: this.belongsTo(MediaObject, 'logo_id'),
      taxNumber: this.attr(null),
      owner: this.belongsTo(User, 'owner_id'),
      users: this.hasMany(User, 'organization', 'id'),
      synchronized: this.attr(null),
      personType: this.attr(null),
      region: this.attr(null),
      district: this.attr(null),
      isSingleTax: this.attr(null),
      isDraft: this.attr(null),
      isVAT: this.attr(null),
      receiptFormationType: this.attr(null),
      receiptSetting: this.attr(null),
      billEmail: this.attr(null),
      emergencyPhone: this.attr(null),
      shiftCloseTime: this.attr(null),
      allowEmployeeLogin: this.attr(null),
      tags: this.attr(null, value => this._.isArray(value)
        ? value.map(item => new Tags(item))
        : []),
      // billing: this.belongsTo(Billings, 'billing_id'),
      billing: this.attr(null),
      promoCodeOrganizations: this.attr(null),
      documentFlowServiceName: this.attr(null),
      documentFlowServiceUser: this.attr(null),
      autoCollectionOnClose: this.attr(null),
      referralCode: this.attr(null),
      actSignatoryPosition: this.attr(null),
      actSignatoryFullName: this.attr(null),
      goodsAccountingEnabled: this.attr(null),
      remindShiftCloseTime: this.attr(null),
      enableOrderDelivery: this.attr(null),
      enableSmsModule: this.attr(null),
      lowBalanceSmsCount: this.attr(null),
      hideTestData: this.attr(null),
      isPawnshop: this.attr(null),
      concordLogin: this.attr(null),
      concordTerminalId: this.attr(null),
      chiefName: this.attr(null),
      vip: this.attr(null),
      labels: this.attr([]),
      shares: this.attr([]),
      hasRroShare: this.attr(null),
      monoTips: this.attr(null)
    })
  }

  static ormRelationMap = [
    'owner',
    'logo',
    'tags'
  ]

  static ormFieldsMap = {}

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    },
    {
      model: 'tags',
      component: 'e-search-select',
      provider: {
        name: 'Tags',
        vid: 'tags',
        rules: ''
      },
      attrs: {
        label: 'Tags',
        outlined: true,
        clearable: true,
        'return-object': true,
        'item-text': 'name'
      },
      async request () {
        Tags.deleteAll()
        await Tags.api().all()
      },
      items: () => Tags.all()
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'tags'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormTrans = {
    single: 'Organization',
    multy: 'Organizations',
    dialog: 'organization'
  }

  get labelsString () {
    let labels = ''
    this.labels.forEach((item) => {
      labels += item.name + ', '
    })
    if (labels.endsWith(', ')) {
      labels = labels.slice(0, -2)
    }
    return labels
  }

  get sharesString () {
    let shares = ''
    this.shares.forEach((item) => {
      shares += item.name + ', '
    })
    if (shares.endsWith(', ')) {
      shares = shares.slice(0, -2)
    }
    return shares
  }

  get verifCode () {
    const verifCode = this._.get(this.billing, 'verifCode', '')
    return verifCode ? '#' + verifCode : ''
  }

  get handleUnits () {
    const units = this._.get(this.billing, 'units', 0)
    return units ? (units / 100).toFixed(2) : '0.00'
  }

  get billingStatus () {
    const status = this._.get(this.billing, 'status', '')
    return this._.get(Organization, `billingStatuses[${status}].text`, '—')
  }

  get tagsString () {
    let tags = ''
    this._.each(this.tags, (tag, index) => {
      tags += index === 0 ? tag.name : `, ${tag.name}`
    })
    return tags || '-'
  }

  get ownerFullName () {
    return `${this._.get(this.owner, 'profile.firstName', '')} ${this._.get(this.owner, 'profile.lastName', '')}`
  }

  get phone () {
    return this._.get(this.owner, 'phone', '-')
  }

  get email () {
    return this._.get(this.owner, 'email', '-')
  }

  get vipStr () {
    return this.vip ? 'Так' : 'Ні'
  }

  static ormHeaders = [
    { text: 'Name', value: 'name', sortable: true, filterable: true },
    { text: 'Owner', value: 'owner', sortable: false, filterable: false },
    { text: 'Phone', value: 'phone', sortable: false, filterable: false },
    { text: 'VIP', value: 'vipStr', sortable: false, filterable: false },
    { text: 'Email', value: 'email', sortable: false, filterable: false },
    { text: 'Edrpou', value: 'edrpou', sortable: true, filterable: true },
    { text: 'Billing status', value: 'billingStatus', sortable: true, filterable: true },
    { text: 'Payment code', value: 'verifCode', sortable: true, filterable: true },
    { text: 'The amount on the account', value: 'handleUnits', sortable: true, filterable: true },
    { text: 'Tags', value: 'tagsString', sortable: true, filterable: true },
    { text: 'Labels', value: 'labelsString', sortable: false, filterable: false },
    { text: 'Shares', value: 'sharesString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['active'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'login'
    },
    {
      name: 'read',
      text: 'View',
      icon: {
        text: 'info',
        type: 'e-svg-icon'
      }
    },
    {
      name: 'edit'
    },
    {
      name: 'activateBilling',
      visible: item => this._.get(item, 'billing.status', '') === Organization.billingStatuses.disabled.value
    },
    {
      name: 'defrost',
      visible: item => this._.get(item, 'billing.status', '') === Organization.billingStatuses.frozen.value
    },
    {
      name: 'adminAuthLogs',
      text: 'Logs',
      icon: {
        text: 'key-sign',
        type: 'e-svg-icon'
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          title: 'Admin auth logs',
          width: 'lg',
          component: 'm-orm-table',
          componentProps: {
            model: AdminAuthLog,
            customRequest: ({ model, requestParams }) => {
              return model.api().filter({ destinationUser: item.owner.id }).all(requestParams, {
                route: Organization.$routes[Organization.entity].adminAuthLogs()
              })
            }
          }
        })
      }
    },
    {
      name: 'restorations',
      text: 'Restorations organization',
      icon: {
        text: 'list-settings',
        type: 'e-svg-icon'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Restorations',
          component: 'm-orm-table',
          width: 'lg',
          componentProps: {
            model: Restorations,
            customRequest: ({ model, requestParams }) => {
              return model.api().filter({ organization: item.id }).all(requestParams, { route: Organization.$routes[Organization.entity].activationLogsOrganization() })
            }
          }
        })
      }
    },
    {
      name: 'addPromocode',
      text: 'Add promo code',
      icon: {
        text: 'voucher',
        type: 'e-svg-icon'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Add promo code',
          component: 'm-form-block',
          width: '500px',
          componentProps: {
            buttonText: 'Add',
            fields: [
              {
                model: 'type',
                component: 'v-text-field',
                provider: {
                  vid: 'type',
                  name: 'Type',
                  rules: 'required'
                },
                attrs: {
                  visible: false
                },
                default: 200
              },
              {
                model: 'value',
                component: 'v-text-field',
                provider: {
                  vid: 'value',
                  name: 'Cash registers amount',
                  rules: 'required'
                },
                attrs: {
                  label: 'Cash registers amount',
                  type: 'number',
                  outlined: true
                },
                asyncDefault: (items, amount) => {
                  return amount
                },
                request: async () => {
                  const cashRegisters = await Organization.api().get(Organization.$routes[Organization.entity].cashRegisters(item.id), {
                    save: false,
                    params: {
                      limit: 2,
                      offset: 1,
                      mode: CashRegisters.itemModes.checkbox.mode
                    }
                  })
                  return this._.get(cashRegisters, 'response.data.meta.totalItems', 0)
                }
              },
              {
                model: 'months',
                component: 'v-text-field',
                provider: {
                  vid: 'months',
                  name: 'Months',
                  rules: 'required'
                },
                attrs: {
                  label: 'Months',
                  type: 'number',
                  outlined: true
                },
                default: 1
              },
              {
                model: 'startDate',
                component: 'e-input-datetime',
                provider: {
                  vid: 'startDate',
                  name: 'Select start date',
                  rules: 'required'
                },
                attrs: {
                  outlined: true,
                  label: 'Select start date',
                  visible: false
                },
                default: () => {
                  const date = new Date()
                  return this.$moment(date).format(formats.dateISO8601)
                }
              },
              {
                model: 'endDate',
                component: 'e-input-datetime',
                provider: {
                  vid: 'endDate',
                  name: 'Select end date',
                  rules: 'required'
                },
                attrs: {
                  outlined: true,
                  label: 'Select end date',
                  visible: false
                },
                default: () => {
                  const date = new Date()
                  date.setDate(date.getDate() + 2)
                  return this.$moment(date).format(formats.dateISO8601)
                }
              },
              {
                model: 'promoCodePurpose',
                component: 'v-text-field',
                provider: {
                  vid: 'promoCodePurpose',
                  name: 'Promo code purpose',
                  rules: 'required'
                },
                attrs: {
                  label: 'Promo code purpose',
                  outlined: true
                }
              }
            ],
            successMessage: 'Promo code successfully applied',
            onSubmit: async (items) => {
              if (items) {
                items.value = parseInt(items.value)
                items.months = parseInt(items.months)
                const promocode = this._.get(await PromoCodes.api().create(items), 'response.data.code', null)
                await Organization.api().put(Organization.$routes[Organization.entity].applyPromo(item.id), [promocode])
                await dialog.close()
              }
            }
          }
        })
      }
    },
    {
      name: 'promoCodes'
    },
    {
      name: 'addPromoCodeRroShare',
      text: 'Add promo code rro share',
      icon: {
        text: 'voucher',
        type: 'e-svg-icon'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Add promo code rro share',
          component: 'm-form-block',
          width: '500px',
          componentProps: {
            buttonText: 'Add',
            fields: [
              new AutocompleteBuilder({
                model: 'cashRegister',
                label: 'Cash register',
                autoWidth: true,
                rules: 'required',
                customQuery: () => true,
                query: (model, ctx, config, user) => {
                  model.deleteAll()
                  const filter = { 'mode[in]': `${CashRegisters.itemModes.checkbox.mode}` }
                  const route = Organization.$routes[Organization.entity].cashRegisters(item.id)
                  return model.api().filter(filter).all(config?.params, { route })
                }
              }, CashRegistersSearch).build(),
              {
                model: 'monthsCount',
                component: 'v-text-field',
                provider: {
                  vid: 'monthsCount',
                  name: 'Months count',
                  rules: 'required'
                },
                attrs: {
                  label: 'Months',
                  type: 'number',
                  outlined: true
                },
                default: 1
              },
              {
                model: 'description',
                component: 'v-text-field',
                provider: {
                  vid: 'description',
                  name: 'Promo code purpose',
                  rules: 'max:255'
                },
                attrs: {
                  label: 'Promo code purpose',
                  outlined: true
                }
              }
            ],
            successMessage: 'Promo code successfully applied',
            onSubmit: async (items) => {
              if (items) {
                items.monthsCount = parseInt(items.monthsCount)
                items.cashRegister = '/cash-registers/' + items.cashRegister.id
                await PromoCodeRroShare.api().create(items)
                await dialog.close()
              }
            }
          }
        })
      }
    },
    {
      name: 'invoices'
    },
    {
      name: 'cashRegisters'
    },
    {
      name: 'shifts'
    },
    {
      name: 'reconciliationAct',
      text: 'Reconciliation act',
      icon: {
        text: 'download-black',
        type: 'e-svg-icon'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Reconciliation act',
          component: 'm-form-block',
          width: '500px',
          componentProps: {
            buttonText: 'Download',
            fields: [
              {
                model: 'dateRange',
                component: 'e-input-datetime-range',
                name: 'dateRange',
                provider: {
                  vid: 'dateRange',
                  name: 'Date range',
                  rules: 'required'
                },
                attrs: {
                  range: true,
                  outlined: true,
                  placeholder: 'Date range',
                  max: () => new Date().toISOString()
                }
              }
            ],
            onSubmit: async (data) => {
              const fromDate = this._.get(data, 'dateRange[0]', '')
              const toDate = this._.get(data, 'dateRange[1]', '') || this._.get(data, 'dateRange[0]', '')
              const res = await Billings.api().getReconciliationAct(this._.get(item, 'billing.id', ''), {
                from_date: fromDate,
                to_date: toDate
              })
              Download(this._.get(res, 'response.data'), `reconciliation-act_${this._.get(item, 'name', '')}_${fromDate}_${toDate}.xlsx`)
              await dialog.close()
            }
          }
        })
      }
    },
    {
      name: 'generateTestData',
      text: 'Generate test data',
      icon: {
        text: 'add',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return user?.isSuperAdmin
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          width: '500px',
          title: 'Generate test data',
          component: 'm-form-block',
          componentProps: {
            successMessage: 'Test data successfully generated',
            buttonText: 'Generate',
            fields: [
              {
                name: 'count',
                model: 'count',
                default: '1',
                component: 'v-text-field',
                provider: {
                  vid: 'count',
                  name: 'Count',
                  rules: 'required|numeric|min_value:1|max_value:100'
                },
                attrs: {
                  min: 1,
                  max: 100,
                  type: 'number',
                  outlined: true,
                  placeholder: 'Count of test users'
                }
              }
            ],
            onSubmit: async (data) => {
              await this.api().post(this.$routes.organization.generateTestData(item.id), data)
              await dialog.close()
            }
          }
        })
      }
    },
    {
      name: 'setReferralCode',
      text: 'Set referral code',
      icon: {
        text: 'referral-code',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return user?.isPartnersManager
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Set referral code',
          component: 'm-form-block',
          width: '500px',
          componentProps: {
            buttonText: 'Set',
            fields: [
              {
                model: 'partnerCode',
                component: 'v-text-field',
                provider: {
                  vid: 'partnerCode',
                  name: 'Referral code',
                  rules: 'max:10'
                },
                attrs: {
                  label: 'Referral code',
                  outlined: true
                }
              }
            ],
            successMessage: 'Referral code successfully set',
            onSubmit: async (data) => {
              if (data) {
                await User.api().put(User.$routes[User.entity].updatePartnerCode(item.owner_id), {
                  partnerCode: data.partnerCode
                })
                await dialog.close()
              }
            }
          }
        })
      }
    },
    {
      name: 'setVip',
      text: 'Set vip state',
      icon: {
        text: 'add',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return user?.isSuperAdmin && !item.vip
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await dialog.open({
          width: '500px',
          title: 'Are you sure to set vip state?'
        })
        if (confirmed) {
          await Organization.api().setVip(item.id)
        }
      }
    },
    {
      name: 'unsetVip',
      text: 'Unset vip state',
      icon: {
        text: 'add',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return user?.isSuperAdmin && item.vip
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await dialog.open({
          width: '500px',
          title: 'Are you sure to unset vip state?'
        })
        if (confirmed) {
          await Organization.api().unsetVip(item.id)
        }
      }
    },
    {
      name: 'setLabels',
      text: 'Set labels',
      icon: {
        text: 'voucher',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return user?.isSuperAdmin
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Set label for organization',
          component: 'm-form-block',
          width: '500px',
          componentProps: {
            buttonText: 'Set',
            fields: [
              new AutocompleteBuilder({
                model: 'labels',
                label: 'Labels',
                multiple: true,
                chipText: item => item.name,
                query: model => model.api(),
                addEntityBtn: {
                  text: '+ Додати мітку',
                  call: async () => {
                    const dialog = Dialog.query().where('type', 'content_top').first()
                    return await dialog.open({
                      title: 'New label',
                      width: '400px',
                      component: 'm-form-block',
                      componentProps: {
                        fields: Labels.ormFields,
                        buttonText: 'Add label',
                        onSubmit: async (data) => {
                          const labelsRequest = await Labels.api().create(data)
                          const label = this._.get(labelsRequest, 'entities.suppliers[0]', null)
                          AppNotifications.success('Мітка успішно створена')
                          await dialog.close(label)
                        }
                      }
                    })
                  }
                },
                deleteEntityBtn: false
              }, LabelsSearch).build()
            ],
            successMessage: 'Labels successfully set for organization',
            onSubmit: async (data) => {
              const labels = data.labels
              const labelsPayload = []
              labels.forEach((item) => {
                if (item) {
                  labelsPayload.push('labels/' + item.id)
                }
              })
              await Organization.api().update(item, { labels: labelsPayload })
              await dialog.close()
            }
          }
        })
      }
    }
  ]

  static ormFields = [
    {
      model: 'tags',
      component: 'e-search-select',
      provider: {
        name: 'Tags',
        vid: 'tags'
      },
      attrs: {
        label: 'Tags',
        outlined: true,
        clearable: true,
        'return-object': true,
        'item-text': 'name'
      },
      async request () {
        Tags.deleteAll()
        await Tags.api().all()
      },
      items: () => Tags.all()
    }
  ]

  static ormColsComponents = {
    owner: {
      component: 'e-organization-owner'
    },
    name: {
      component: 'e-organization-name-with-icon'
    }
  }

  static ormDialogs = {
    default: 'm-orm-forms-stepper-dialog',
    edit: 'm-orm-crud-dialog',
    read: 'm-orm-view-dialog',
    invoices: 'm-orm-table-dialog',
    cashRegisters: 'm-orm-table-dialog',
    shifts: 'm-orm-table-dialog',
    activateBilling: 'm-orm-activate-dialog',
    defrost: 'm-orm-activate-dialog',
    deactivate: 'm-orm-deactivate-dialog',
    promoCodes: 'm-orm-table-dialog'
  }

  static ormDialogsConfig = {
    default: {
      stepperFields: [['name', 'shortName', 'edrpou', 'taxNumber', 'personType', 'logo'], []],
      stepperConfig: new Stepper(
        [null, {
          component: 't-orm-fields-content-wrapper',
          attrs: {
            model: User,
            property: 'owner'
          }
        }],
        1,
        ['Create organization', 'Create owner'])
    },
    edit: {
      config: {
        context: 'update',
        filterPayload: (payload) => {
          if (payload?.tags) {
            payload.tags = ['/tags/' + payload?.tags?.id]
          } else {
            payload.tags = []
          }
          return payload
        }
      }
    },
    read: {
      title: item => ({ name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'name',
            label: 'Name'
          },
          {
            model: 'ownerFullName',
            label: 'Owner'
          },
          {
            model: 'edrpou',
            label: 'Edrpou'
          },
          {
            model: 'billingStatus',
            label: 'Billing status'
          },
          {
            model: 'verifCode',
            label: 'Payment code'
          },
          {
            model: 'oldVerifCode',
            label: 'Old payment code'
          },
          {
            model: 'handleUnits',
            label: 'The amount on the account'
          },
          {
            model: 'billingDay',
            label: 'Billing day',
            request: item => Organization.api().get(Organization.$routes[Organization.entity].invoices(item.id), { save: false }),
            value: (val, res) => {
              const endDate = this._.get(res, 'response.data.data[0].period.endDate', '')
              if (endDate) {
                const billingDay = new Date(endDate).getDate()
                return billingDay < 10 ? '0' + billingDay : billingDay
              }
              return '—'
            }
          },
          {
            model: 'cashRegistersAmount',
            label: 'Cash registers amount',
            request: item => Organization.api().get(Organization.$routes[Organization.entity].cashRegisters(item.id), {
              save: false,
              params: {
                limit: 2,
                offset: 1,
                mode: CashRegisters.itemModes.checkbox.mode
              }
            }),
            value: (val, res) => this._.get(res, 'response.data.meta.totalItems', 0)
          }
        ]
      }
    },
    invoices: {
      config: {
        model: Invoices
      },
      size: 'lg',
      requestRoute: item => Organization.$routes[Organization.entity].invoices(item.id)
    },
    promoCodes: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      config: {
        model: PromoCodeOrganizations
      },
      size: 'lg',
      requestRoute: item => Organization.$routes[Organization.entity].promoCodeOrganizations(item.id)
    },
    cashRegisters: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      config: {
        model: CashRegisters
      },
      requestRoute: item => Organization.$routes[Organization.entity].cashRegisters(item.id)
    },
    shifts: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      config: {
        model: Shifts
      },
      requestRoute: item => Organization.$routes[Organization.entity].shifts(item.id)
    },
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: this.ormTrans.single, name: item.name })
    },
    activateBilling: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: this.ormTrans.single, name: item.name }),
      confirmCallback: async item => await Billings.api().ccActive(this._.get(item, 'billing.id', ''))
    },
    defrost: {
      title: item => ({ name: item.name }),
      notification: item => ({ type: this.ormTrans.single, name: item.name }),
      confirmCallback: async item => await Billings.api().generateInvoice(this._.get(item, 'billing.id', ''))
    },
    deactivate: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: this.ormTrans.single, name: item.name })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.putDraftOrganization = function (id, payload, token) {
        const data = Object.assign({}, payload, { xRegistrationToken: token })
        return this.put(Model.$routes.organization.draft(id), data, { save: false })
      }
      configActions.multiAccount = function (payload, token) {
        const data = Object.assign({}, payload, { xRegistrationToken: token })
        return this.post(Model.$routes.organization.multiAccount(), data, { save: false })
      }
      configActions.sendTaxAuthData = function (payload) {
        return this.post(Model.$routes.organization.sendTaxAuthData(), payload, { save: false })
      }
      configActions.deleteManagedOrganization = function (id, orgId) {
        return this.delete(Model.$routes.users.deleteManagedOrganization(id, orgId), { delete: orgId })
      }
      configActions.deleteTestData = function () {
        const organization = authUser.get() ? Organization.store().$db().model('currentorganization').query().first() : null
        return this.put(Model.$routes.organization.concrete(organization.id), { hideTestData: true }, { save: false })
      }
      configActions.generateTelegramBotLink = function (id) {
        return this.get(Model.$routes.organization.generateTelegramBotLink(id), { save: false })
      }
      configActions.setVip = function (id) {
        return this.put(Model.$routes.organization.vip(id), { vip: true })
      }
      configActions.unsetVip = function (id) {
        return this.put(Model.$routes.organization.vip(id), { vip: false })
      }

      configActions.multiAccountArchivedOrganizations = function () {
        return this.get(Model.$routes.organization.multiAccountArchivedOrganizations(), { save: false })
      }
      configActions.multiAccountDelete = function (id) {
        return this.delete(Model.$routes.organization.multiAccountDelete(id))
      }

      configActions.getRro = function () {
        return this.get(Model.$routes.organization.rro())
      }
      configActions.getClientRroPromotionDetails = function () {
        return this.get(Model.$routes.organization.getClientRroPromotionDetails())
      }
      configActions.rroPromotionApply = function () {
        return this.post(Model.$routes.organization.rroPromotionApply())
      }

      return configActions
    }
  }
}
